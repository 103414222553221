import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
const Email = ({ getMessage }) => {
  getMessage('Email section');
  const { toggler } = useOutletContext();

  const [receiver, setReceiver] = useState(''); // this should be converted to array before sending to backend
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [template, setTemplate] = useState('');
  const [emailFilter, setFilter] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loadedUsers, setLoadedUsers] = useState(10);

  useEffect(() => {
    axios
      .get('/email/getAll', {
        headers: {
          Authorization: localStorage.getItem('accessToken')
        }
      })
      .then((res) => {
        setAllUsers(res.data.users);
        setFilteredUsers(res.data.users);
      });
  }, []);

  function handleReceiver(e) {
    e.preventDefault();
    setReceiver(e.target.value);
  }

  function handleSubject(e) {
    e.preventDefault();
    setSubject(e.target.value);
  }

  function handleMessage(e) {
    e.preventDefault();
    setMessage(e.target.value);
  }

  function handleTemplate(e) {
    e.preventDefault();
    setTemplate(e.target.value);
  }

  function handleFilter(e) {
    e.preventDefault();
    let t = allUsers.filter((user) => {
      if (e.target.value == 'both') {
        if (user.receiveEmail && user.verified) return true;
      } else {
        return user[e.target.value];
      }
    });
    setFilteredUsers([...t]);
    setFilter(e.target.value);
  }

  function handleLoadedUsers(e) {
    e.preventDefault();
    setLoadedUsers(loadedUsers + 10);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const to = receiver.split(', ');
    const result = await axios
      .post(
        '/email/specific',
        { to, subject, body: message },
        {
          headers: {
            Authorization: localStorage.getItem('accessToken')
          }
        }
      )
      .catch((error) => {
        if (error.response.data.msg == 'Invalid token') {
          axios
            .get('/refresh', {
              headers: {
                Refresh: localStorage.getItem('refreshToken')
              }
            })
            .then((res) => {
              localStorage.setItem('accessToken', res.data.accessToken);
              axios.post(
                '/email/specific',
                { to, subject, body: message },
                {
                  headers: {
                    Authorization: localStorage.getItem('accessToken')
                  }
                }
              );
            })
            .catch((err) => (window.location.href = '/'));
        }
      });
    console.log(result.data);
  }

  return (
    <>
      <section
        id="email-and-promotion"
        class={toggler ? 'left-margin-container' : 'left-margin-container margin-remove'}
      >
        <div class="container email-and-promotion-container">
          <h1>Email</h1>
          <form onSubmit={handleSubmit} class="email-and-promotion-form">
            <div class="form-group">
              <label for="send_to">Send to</label>
              <input
                value={receiver}
                onChange={handleReceiver}
                type="text"
                name="send_to"
                id="send_to"
                placeholder="receiver's email"
              />
            </div>
            <div class="form-group">
              <label for="subject">Subject</label>
              <input
                value={subject}
                onChange={handleSubject}
                type="text"
                name="email_subject"
                id="subject"
                placeholder="subject"
              />
            </div>
            <div class="form-group">
              <label for="text_message">Message</label>
              <textarea
                value={message}
                onChange={handleMessage}
                name="text_message"
                id="text_message"
                placeholder="message goes here..."
              ></textarea>
            </div>
            <div class="form-group">
              <label for="template_choice">Pick a template</label>
              <select value={template} onChange={handleTemplate} name="template_choice" id="template_choice">
                <option value="default">Default</option>
                <option value="new_year">New year</option>
              </select>
            </div>
            <button type="submit" class="save-button" name="save">
              <span class="lnr lnr-checkmark-circle"></span> Send
            </button>
          </form>
          {/* <!-- div: brand listings --> */}
          <div class="listing-table-wrapper">
            <select value={emailFilter} onChange={handleFilter} name="filter_by" id="filter_by" class="email-filter">
              <option value="receiveEmail">Subscribed emails</option>
              <option value="verified">Verified emails</option>
              <option value="both">Subscribed and Verified emails</option>
            </select>
            <table class="listings-table">
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>First name</th>
                  <th>Last name</th>
                  <th>Email</th>
                  <th>Phone number</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers &&
                  filteredUsers.map((user, index) => {
                    return index < loadedUsers ? (
                      <tr>
                        <td>{index + 1}.</td>
                        <td>{user.firstName}</td>
                        <td>{user.lastName}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                      </tr>
                    ) : (
                      ''
                    );
                  })}
              </tbody>
            </table>
          </div>
          <a href="#" onClick={handleLoadedUsers} class="underline load-more">
            Load more
          </a>
        </div>
      </section>
    </>
  );
};

export default Email;
