import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';

const Category = ({ getMessage }) => {
  getMessage('Categorization section');
  const { toggler } = useOutletContext();

  const [categorization, setCategorization] = useState('');
  const [allCategories, setAllCategories] = useState([]);
  const [edit, setEditing] = useState(-1);

  useEffect(() => {
    axios.get('/filter/categories').then((res) => setAllCategories(res.data.result));
  }, []);

  function handleEdit(index) {
    console.log(index);
    setEditing(index);
    setCategorization(allCategories[index]);
  }

  async function handleDelete(index) {
    const temp = allCategories;
    temp.splice(index, 1);
    setAllCategories([...temp]);
    await axios
      .put(
        '/filter/deleteFilter',
        { filter: temp, key: 'categories' },
        {
          headers: {
            Authorization: localStorage.getItem('accessToken')
          }
        }
      )
      .catch((error) => {
        if (error.response.data.msg == 'Invalid token') {
          axios
            .get('/refresh', {
              headers: {
                Refresh: localStorage.getItem('refreshToken')
              }
            })
            .then((res) => {
              localStorage.setItem('accessToken', res.data.accessToken);
              axios.put(
                '/filter/deleteFilter',
                { filter: temp, key: 'categories' },
                {
                  headers: {
                    Authorization: localStorage.getItem('accessToken')
                  }
                }
              );
            })
            .catch((err) => (window.location.href = '/'));
        }
      });
  }

  async function addCategories(e) {
    e.preventDefault();
    let a;
    let temp = allCategories;
    if (edit !== -1) {
      a = await axios
        .put(
          '/filter/updateFilter',
          {
            filter: { key: 'categories', index: edit, value: categorization }
          },
          {
            headers: {
              Authorization: localStorage.getItem('accessToken')
            }
          }
        )
        .catch((error) => {
          if (error.response.data.msg == 'Invalid token') {
            axios
              .get('/refresh', {
                headers: {
                  Refresh: localStorage.getItem('refreshToken')
                }
              })
              .then((res) => {
                localStorage.setItem('accessToken', res.data.accessToken);
                axios.put(
                  '/filter/updateFilter',
                  {
                    filter: { key: 'categories', index: edit, value: categorization }
                  },
                  {
                    headers: {
                      Authorization: localStorage.getItem('accessToken')
                    }
                  }
                );
              })
              .catch((err) => (window.location.href = '/'));
          }
        });
      temp[edit] = categorization;
      setCategorization('');
      setEditing(-1);
    } else {
      a = await axios
        .post(
          '/filter/addFilter',
          {
            filter: { key: 'categories', value: categorization }
          },
          {
            headers: {
              Authorization: localStorage.getItem('accessToken')
            }
          }
        )
        .catch((error) => {
          if (error.response.data.msg == 'Invalid token') {
            axios
              .get('/refresh', {
                headers: {
                  Refresh: localStorage.getItem('refreshToken')
                }
              })
              .then((res) => {
                localStorage.setItem('accessToken', res.data.accessToken);
                axios.post(
                  '/filter/addFilter',
                  {
                    filter: { key: 'categories', value: categorization }
                  },
                  {
                    headers: {
                      Authorization: localStorage.getItem('accessToken')
                    }
                  }
                );
              })
              .catch((err) => (window.location.href = '/'));
          }
        });
      temp.push(categorization);
    }
    setAllCategories([...temp]);
    console.log(a);
  }

  function handleCategorization(e) {
    e.preventDefault();
    setCategorization(e.target.value);
  }

  return (
    <>
      <section id="categorization" class={toggler ? 'left-margin-container' : 'left-margin-container margin-remove'}>
        <div class="container add-categorization-container">
          <h1>Add Categorization</h1>
          <form onSubmit={addCategories} class="add-categorization-form">
            <div class="form-group">
              <label for="categorization_add">Categorization</label>
              <input
                value={categorization}
                onChange={handleCategorization}
                type="text"
                name="cat_for"
                id="categorization_add"
                placeholder="Categorization"
              />
              <span class="categorization-tags">eg: T-Shirt,Bag,Shoe</span>
            </div>
            <button type="submit" class="save-button" name="save">
              <span class="lnr lnr-checkmark-circle"></span> {edit !== -1 ? 'Edit' : 'Add'}
            </button>
          </form>
          <div class="listing-table-wrapper">
            <table class="listings-table">
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Categorization names</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {allCategories.map((style, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{style}</td>
                      <td class="options">
                        <a href="#" onClick={() => handleEdit(index)} class="edit">
                          <div class="lnr lnr-pencil"></div>
                        </a>
                        <a href="#" onClick={() => handleDelete(index)} class="delete">
                          <div class="lnr lnr-trash"></div>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default Category;
