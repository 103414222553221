import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import { imgDelete, imgUpload } from '../../utils/img';
import { useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditProduct = ({ getMessage }) => {
  getMessage('Product Section');
  const { toggler } = useOutletContext();
  const { id } = useParams();

  const [productContainer, setProductContainer] = useState('product-container-content hidden');
  const [afterMarketContainer, setAMContainer] = useState('after-market-container hidden');
  const [whereToBuyContainer, setWTBContainer] = useState('where-to-buy-container hidden');

  // input fields
  const [productName, setProductName] = useState('');
  const [author, setAuthor] = useState('');
  const [mainImage, setMainImage] = useState();
  const [description, setDesc] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [color, setColor] = useState('');
  const [price, setPrice] = useState(0);
  const [releaseDate, setReleaseDate] = useState('');
  const [styleCode, setStyleCode] = useState('');
  const [topAfterMarket, setTopAM] = useState({
    img: '',
    logo: '',
    affiliateLink: '',
    name: ''
  });
  const [afterMarkets, setAfterMs] = useState([]);
  const [whereToBuy, setWhereToBuy] = useState([{ name: '', url: '', img: '', date: '' }]);
  const [tags, setTags] = useState(['']);
  const [style, setStyle] = useState('');
  const [size, setSize] = useState('');
  const [category, setCategory] = useState('');
  const [brand, setBrand] = useState('');
  const [otherImages, setOtherImages] = useState(['']);
  const [inStock, setInStock] = useState(false);
  const [productFor, setProductFor] = useState('');
  const [topic, setTopic] = useState('');
  const [allTopics, setAllTopics] = useState([]);
  const [allBrand, setAllBrand] = useState([]);
  const [allStyle, setAllStyle] = useState([]);
  const [allProductFor, setAllProductFor] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [allMarketplace, setAllMarketplace] = useState([]);
  const [mainImgChange, setmainImgChange] = useState('');
  const [wtbChange, setWTBChange] = useState([{ name: '', url: '', img: '', date: '' }]);
  const [topAMChange, setTopAmChange] = useState({
    img: '',
    logo: '',
    affiliateLink: '',
    name: ''
  });
  const [otherImgChange, setOtherImgChange] = useState([]);

  useEffect(() => {
    axios.get(`/products/getProducts/${id}`).then((res) => {
      const temp = res.data.product;
      setProductName(temp.productName);
      setAuthor(temp.author);
      setMainImage(temp.mainImage);
      setDesc(temp.description);
      setShortDesc(temp.shortDesc);
      setColor(temp.color);
      setPrice(temp.price);
      let tempDate = temp.releaseDate.split('T')[0];
      setReleaseDate(tempDate);
      setStyleCode(temp.styleCode);
      setTopAmChange({
        img: '',
        logo: '',
        affiliateLink: temp.topAfterMarket.affiliateLink,
        name: temp.topAfterMarket.name
      });
      setTopAM(temp.topAfterMarket);
      let tempAms = temp.afterMarkets.map((item) => {
        const t = { ...item };
        var tempDate = t.marketDate.split('T')[0];
        t.marketDate = tempDate;
        return t;
      });
      setAfterMs(tempAms);
      const wtbTemp = temp.whereToBuy.map((item) => {
        return {
          name: item.name,
          url: item.url,
          img: '',
          date: item.date ? item.date.split('T')[0] : ''
        };
      });
      setWTBChange(wtbTemp);
      setWhereToBuy(temp.whereToBuy);
      let t = '';
      temp.tags.forEach((tag, index) => {
        if (index < temp.tags.length - 1) {
          t = t.concat(tag, ',');
        } else {
          t = t.concat(tag);
        }
      });
      t.slice(t.length - 2, 1);
      setTags(t);
      setStyle(temp.style);
      setStyleCode(temp.styleCode);
      setSize(temp.size);
      setCategory(temp.category);
      setBrand(temp.brand);
      setOtherImages(temp.otherImages);
      let tp = temp.inStock ? 'yes' : 'no';
      setInStock(tp);
      setProductFor(temp.productFor);
      setTopic(temp.topic);
    });
    axios.get('/filter/all').then((res) => {
      const result = { ...res.data.filters };
      setAllBrand([...result.brand]);
      setAllCategory(result.categories);
      setAllTopics(result.productTopics);
      setAllMarketplace(result.marketplace);
      setAllStyle(result.styles);
      setAllProductFor(result.productFor);
    });
  }, []);

  function handleProductName(e) {
    e.preventDefault();
    setProductName(e.target.value);
  }

  function handleAuthor(e) {
    e.preventDefault();
    setAuthor(e.target.value);
  }

  function handleDescription(e) {
    e.preventDefault();
    setDesc(e.target.value);
  }

  function handleShortDesc(e) {
    e.preventDefault();
    setShortDesc(e.target.value);
  }

  function handleColor(e) {
    e.preventDefault();
    setColor(e.target.value);
  }

  function handlePrice(e) {
    e.preventDefault();
    setPrice(e.target.value);
  }

  function handleReleaseDate(e) {
    e.preventDefault();
    setReleaseDate(e.target.value);
  }

  function handleStyleCode(e) {
    e.preventDefault();
    setStyleCode(e.target.value);
  }

  function handleTags(e) {
    e.preventDefault();
    setTags(e.target.value);
  }

  function handleStyle(e) {
    e.preventDefault();
    setStyle(e.target.value);
  }

  function handleSize(e) {
    e.preventDefault();
    setSize(e.target.value);
  }

  function handleCategory(e) {
    e.preventDefault();
    setCategory(e.target.value);
  }

  function handleBrand(e) {
    e.preventDefault();
    setBrand(e.target.value);
  }

  function handleOtherImages(e) {
    e.preventDefault();
    const a = [];
    for (let i = 0; i < e.target.files.length; i++) {
      let reader = new FileReader();
      reader.onload = () => {
        a.push(reader.result);
        setOtherImgChange(a);
      };
      reader.readAsDataURL(e.target.files[i]);
    }
  }

  function handleInStock(e) {
    e.preventDefault();
    if (e.target.value === 'yes') setInStock(true);
    else setInStock(false);
  }

  function handleProductFor(e) {
    e.preventDefault();
    setProductFor(e.target.value);
  }

  function handleTopic(e) {
    e.preventDefault();
    setTopic(e.target.value);
  }

  function handleMainImage(e) {
    e.preventDefault();
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setmainImgChange(reader.result);
    };
  }

  function addWTB(e) {
    e.preventDefault();
    const t = { img: '', name: '', url: '', date: '' };
    if (wtbChange.length < 5) {
      setWTBChange([...wtbChange, t]);
    }
  }

  function addAMs(e) {
    e.preventDefault();
    if (allMarketplace.length > afterMarkets.length + 1) {
      const t = {
        destination: '',
        marketDate: '',
        affiliateLink: '',
        name: ''
      };
      setAfterMs([...afterMarkets, t]);
    }
  }

  function handleProductC(e) {
    e.preventDefault();
    if (productContainer.includes('hidden')) setProductContainer('product-container-content');
    else setProductContainer('product-container-content hidden');
  }

  function handleAMContainer(e) {
    e.preventDefault();
    if (afterMarketContainer.includes('hidden')) setAMContainer('after-market-container');
    else setAMContainer('after-market-container hidden');
  }

  function handleWTBContainer(e) {
    e.preventDefault();
    if (whereToBuyContainer.includes('hidden')) setWTBContainer('where-to-buy-container');
    else setWTBContainer('where-to-buy-container hidden');
  }

  const WhereToBuyForm = ({ index, item, setWTBChange }) => {
    const [name, setName] = useState(item.name);
    const [url, setUrl] = useState(item.url);
    const [wtbDate, setWtbDate] = useState(item.date);

    function handleImg(e) {
      e.preventDefault();
      let a = wtbChange;
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        a[index].img = reader.result;
        setWTBChange(a);
      };
    }

    function handleName(e) {
      e.preventDefault();
      let a = wtbChange;
      a[index].name = e.target.value;
      setWTBChange(a);
      setName(e.target.value);
    }

    function handleUrl(e) {
      e.preventDefault();
      let a = wtbChange;
      a[index].url = e.target.value;
      setWTBChange(a);
      setUrl(e.target.value);
    }

    function handleWtbDate(e) {
      e.preventDefault();
      let a = wtbChange;
      a[index].date = e.target.value;
      setWhereToBuy(a);
      setWtbDate(e.target.value);
    }

    return (
      <>
        <div class="form-group">
          <label for="where_to_buy_image">Where to buy image</label>
          <input
            onChange={handleImg}
            type="file"
            name="where_to_buy_image"
            class="where_to_buy_img"
            id="where_to_buy_image"
          />
        </div>
        <div class="form-group">
          <label for="title_text">Title text</label>
          <input
            value={name}
            onChange={handleName}
            type="text"
            name="title_text"
            id="title_text"
            class="title_text"
            placeholder="where to buy title"
          />
        </div>
        <div class="form-group">
          <label for="where_to_buy_link">Link</label>
          <input
            value={url}
            onChange={handleUrl}
            type="text"
            name="where_to_buy_link"
            class="where_to_buy_link"
            id="where_to_buy_link"
            placeholder="where to buy link"
          />
        </div>
        <div class="form-group">
          <label for="release_date">WTB date</label>
          <input value={wtbDate} onChange={handleWtbDate} type="date" name="release_date" id="release_date" />
        </div>
      </>
    );
  };

  const AfterMarketForm = ({ index, item, setAfterMs }) => {
    const [name, setName] = useState(item.name);
    const [affiliateLink, setAffiliateLink] = useState(item.affiliateLink);
    const [destination, setDestination] = useState(item.destination);
    const [marketDate, setMarketDate] = useState(item.marketDate);

    function handleDestination(e) {
      e.preventDefault();
      let a = afterMarkets;
      a[index].destination = e.target.value;
      setAfterMs(a);
      setDestination(e.target.value);
    }

    function handleName(e) {
      e.preventDefault();
      let a = afterMarkets;
      a[index].name = e.target.value;
      setAfterMs(a);
      setName(e.target.value);
    }

    function handleUrl(e) {
      e.preventDefault();
      let a = afterMarkets;
      a[index].affiliateLink = e.target.value;
      setAfterMs(a);
      setAffiliateLink(e.target.value);
    }

    function handleMarketDate(e) {
      e.preventDefault();
      let a = afterMarkets;
      a[index].marketDate = e.target.value;
      setAfterMs(a);
      setMarketDate(e.target.value);
    }

    return (
      <>
        <hr />
        <div class={afterMarketContainer}>
          <div class="form-group">
            <label for="destination">Destination</label>
            <input
              value={destination}
              onChange={handleDestination}
              placeholder="Marketplace Destination"
              type="text"
              name="destination"
              class="where_to_buy_img"
              id="where_to_buy_image"
            />
          </div>
          <div class="form-group">
            <label for="marketplace">Marketplace</label>
            <select value={name} onChange={handleName} name="marketplace" id="marketplace" class="pointer">
              {allMarketplace.map((item) => {
                return <option value={item}>{item}</option>;
              })}
            </select>
          </div>
          <div class="form-group">
            <label for="marketplace_link">Affiliate Link</label>
            <input
              value={affiliateLink}
              onChange={handleUrl}
              type="text"
              name="marketplace_link"
              class="affiliate_link"
              id="affiliate_link"
              placeholder="affiliate link"
            />
          </div>
          <div class="form-group">
            <label for="market_date">Market date</label>
            <input value={marketDate} onChange={handleMarketDate} type="date" name="market_date" id="release_date" />
          </div>
        </div>
      </>
    );
  };

  async function editProduct(e) {
    e.preventDefault();
    let mImg;
    if (mainImgChange !== '') {
      mImg = await imgUpload(mainImgChange);
      await imgDelete(mainImage, 'single');
    } else {
      mImg = mainImage;
    }
    const wtb = wtbChange.map(async (item, index) => {
      let wtbT = item;
      if (wtbT.date !== '') {
        let p = wtbT.date.split('-');
        wtbT.date = new Date(p[0], p[1] - 1, p[2]);
      }
      if (item.img !== '') {
        wtbT.img = await imgUpload(item.img);
        await imgDelete(whereToBuy[index].img, 'single');
      } else {
        wtbT.img = whereToBuy[index].img;
      }
      return wtbT;
    });
    let topAM = { ...topAMChange };
    if (topAM.img !== '') {
      topAM.img = await imgUpload(topAMChange.img);
      await imgDelete(topAfterMarket.img, 'single');
    } else {
      topAM.img = topAfterMarket.img;
    }
    if (topAM.logo !== '') {
      topAM.logo = await imgUpload(topAMChange.logo);
      await imgDelete(topAfterMarket.logo, 'single');
    } else {
      topAM.logo = topAfterMarket.logo;
    }
    let otherImgs;
    if (otherImgChange[0]) {
      otherImgs = await imgUpload(otherImgChange);
      await imgDelete(otherImages, 'array');
    } else {
      otherImgs = otherImages;
    }
    const t = tags.split(',');
    var parts = releaseDate.split('-');
    const rd = new Date(parts[0], parts[1] - 1, parts[2]);
    const ams = afterMarkets.map((item) => {
      let temp = { ...item };
      parts = temp.marketDate.split('-');
      temp.marketDate = new Date(parts[0], parts[1] - 1, parts[2]);
      return temp;
    });

    const result = await axios
      .put(
        '/products/updateProduct',
        {
          _id: id,
          mainImage: mImg,
          productName,
          author,
          description,
          shortDesc,
          color,
          price,
          releaseDate: rd,
          styleCode,
          topAfterMarket: topAM,
          afterMarkets: ams,
          whereToBuy: await Promise.all(wtb),
          tags: t,
          style,
          size,
          category,
          brand,
          otherImages: otherImgs,
          inStock,
          productFor,
          isPublished: true,
          topic
        },
        {
          headers: {
            Authorization: localStorage.getItem('accessToken')
          }
        }
      )
      .catch((error) => {
        if (error.response.data.msg == 'Invalid token') {
          axios
            .get('/refresh', {
              headers: {
                Refresh: localStorage.getItem('refreshToken')
              }
            })
            .then((res) => {
              localStorage.setItem('accessToken', res.data.accessToken);
              axios.put(
                '/products/updateProduct',
                {
                  mainImage: mImg,
                  productName,
                  author,
                  description,
                  shortDesc,
                  color,
                  price,
                  releaseDate: rd,
                  styleCode,
                  topAfterMarket: topAM,
                  afterMarkets: ams,
                  whereToBuy: wtb,
                  tags: t,
                  style,
                  size,
                  category,
                  brand,
                  otherImages: otherImgs,
                  inStock,
                  productFor,
                  isPublished: true,
                  topic
                },
                {
                  headers: {
                    Authorization: localStorage.getItem('accessToken')
                  }
                }
              );
            })
            .catch((err) => (window.location.href = '/'));
        }
      });
    window.location.href = '/admin/product/all';
  }

  async function saveProduct(e) {
    e.preventDefault();
    let mImg;
    if (mainImgChange !== '') {
      mImg = await imgUpload(mainImgChange);
      await imgDelete(mainImage, 'single');
    } else {
      mImg = mainImage;
    }
    const wtb = wtbChange.map(async (item, index) => {
      let wtbT = item;
      if (wtbT.date !== '') {
        let p = wtbT.date.split('-');
        wtbT.date = new Date(p[0], p[1] - 1, p[2]);
      }
      if (item.img !== '') {
        wtbT.img = await imgUpload(item.img);
        if (whereToBuy.length < index + 1) {
          await imgDelete(whereToBuy[index].img, 'single');
        }
      } else {
        wtbT.img = whereToBuy[index].img;
      }
      return wtbT;
    });
    let topAM = { ...topAMChange };
    if (topAM.img !== '') {
      topAM.img = await imgUpload(topAMChange.img);
      await imgDelete(topAfterMarket.img, 'single');
    } else {
      topAM.img = topAfterMarket.img;
    }
    if (topAM.logo !== '') {
      topAM.logo = await imgUpload(topAMChange.logo);
      await imgDelete(topAfterMarket.logo, 'single');
    } else {
      topAM.logo = topAfterMarket.logo;
    }
    let otherImgs;
    if (otherImgChange[0]) {
      otherImgs = await imgUpload(otherImgChange);
      await imgDelete(otherImages, 'array');
    } else {
      otherImgs = otherImages;
    }
    const t = tags.split(',');
    var parts = releaseDate.split('-');
    const rd = new Date(parts[0], parts[1] - 1, parts[2]);
    const ams = afterMarkets.map((item) => {
      let temp = { ...item };
      parts = temp.marketDate.split('-');
      temp.marketDate = new Date(parts[0], parts[1] - 1, parts[2]);
      return temp;
    });

    const result = await axios
      .put(
        '/products/updateProduct',
        {
          _id: id,
          mainImage: mImg,
          productName,
          author,
          description,
          shortDesc,
          color,
          price,
          releaseDate: rd,
          styleCode,
          topAfterMarket: topAM,
          afterMarkets: ams,
          whereToBuy: await Promise.all(wtb),
          tags: t,
          style,
          size,
          category,
          brand,
          otherImages: otherImgs,
          inStock,
          productFor,
          isPublished: false,
          topic
        },
        {
          headers: {
            Authorization: localStorage.getItem('accessToken')
          }
        }
      )
      .catch((error) => {
        if (error.response.data.msg == 'Invalid token') {
          axios
            .get('/refresh', {
              headers: {
                Refresh: localStorage.getItem('refreshToken')
              }
            })
            .then((res) => {
              localStorage.setItem('accessToken', res.data.accessToken);
              axios.put(
                '/products/updateProduct',
                {
                  mainImage: mImg,
                  productName,
                  author,
                  description,
                  shortDesc,
                  color,
                  price,
                  releaseDate: rd,
                  styleCode,
                  topAfterMarket: topAM,
                  afterMarkets: ams,
                  whereToBuy: wtb,
                  tags: t,
                  style,
                  size,
                  category,
                  brand,
                  otherImages: otherImgs,
                  inStock,
                  productFor,
                  isPublished: false,
                  topic
                },
                {
                  headers: {
                    Authorization: localStorage.getItem('accessToken')
                  }
                }
              );
            })
            .catch((err) => (window.location.href = '/'));
        }
      });
    window.location.href = '/admin/product/all';
  }

  return (
    <>
      {/* <!-- section: add-product-form --> */}
      <section id="add-product-form" class={toggler ? 'left-margin-container' : 'left-margin-container margin-remove'}>
        <div class="container add-product-form-container">
          <form onSubmit={editProduct} class="add-product-form">
            <div class="product-container">
              <div class="top-text-container add-product-header">
                <h1>
                  <span>Add product</span>
                  <i onClick={handleProductC} class="fa fa-dot-circle expand-circle"></i>
                </h1>
                <p>Add the product to list on the website!</p>
              </div>
              <div class="line-bar"></div>
              <div class={productContainer}>
                <div class="product-and-author">
                  <div class="form-group">
                    <label for="product_name">Product name</label>
                    <input
                      type="text"
                      name="product_name"
                      id="product_name"
                      placeholder="Product name"
                      value={productName}
                      onChange={handleProductName}
                    />
                  </div>
                  <div class="form-group">
                    <label for="author">Author</label>
                    <input
                      type="text"
                      name="author"
                      id="author"
                      placeholder="author"
                      value={author}
                      onChange={handleAuthor}
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="product_image">Product Image</label>
                  <input onChange={handleMainImage} type="file" name="product_image" id="product_image" />
                </div>
                <div class="category-and-brand">
                  <div class="form-group">
                    <label for="category">Category</label>
                    <select name="category" value={category} onChange={handleCategory} id="category">
                      {allCategory.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="brand">Brand</label>
                    <select value={brand} onChange={handleBrand} name="brand" id="brand">
                      {allBrand.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="size">Size</label>
                    <select value={size} onChange={handleSize} name="size" id="brand">
                      <option value="All">All</option>
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="XXL">XXL</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="style_code">Style code</label>
                    <input
                      value={styleCode}
                      onChange={handleStyleCode}
                      type="text"
                      name="style_code"
                      id="style_code"
                      placeholder="style code"
                    />
                  </div>
                </div>
                <div class="price-and-color">
                  <div class="form-group">
                    <label for="price">Price</label>
                    <input
                      value={price}
                      onChange={handlePrice}
                      type="number"
                      name="price"
                      id="price"
                      placeholder="price"
                    />
                  </div>
                  <div class="form-group">
                    <label for="color">Color</label>
                    <input
                      value={color}
                      onChange={handleColor}
                      type="text"
                      name="color"
                      id="color"
                      placeholder="color"
                    />
                  </div>
                  <div class="form-group">
                    <label for="shortDesc">Short Description</label>
                    <input
                      value={shortDesc}
                      onChange={handleShortDesc}
                      type="text"
                      name="shortDesc"
                      id="color"
                      placeholder="short description"
                    />
                  </div>
                  <div class="form-group">
                    <label for="topic">Topic</label>
                    <select value={topic} onChange={handleTopic} name="topic" id="topic">
                      <option value="NONE">None</option>;
                      {allTopics.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="release_date">Release date</label>
                    <input
                      value={releaseDate}
                      onChange={handleReleaseDate}
                      type="date"
                      name="release_date"
                      id="release_date"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="description">Description</label>
                  <div style={{ marginRight: '10em' }}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDesc(data);
                      }}
                    />
                  </div>
                  {/* <textarea
                    value={description}
                    onChange={handleDescription}
                    name="description"
                    id="description"
                    class="description"
                    placeholder="description goes here..."></textarea> */}
                </div>
                <div class="filter-and-stock">
                  <div class="form-group">
                    <label for="product_for">Product for</label>
                    <select value={productFor} onChange={handleProductFor} name="product_for" id="product_for">
                      {allProductFor.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="stock_status">In stock</label>
                    <select
                      value={inStock ? 'yes' : 'no'}
                      onChange={handleInStock}
                      name="stock_status"
                      id="stock_status"
                    >
                      <option value="yes">yes</option>
                      <option value="no">no</option>
                    </select>
                  </div>
                </div>
                <div class="tags-and-styles">
                  <div class="form-group">
                    <label for="tags">tags</label>
                    <input value={tags} onChange={handleTags} type="text" name="tags" id="tags" placeholder="tags" />
                    <span class="tag-sample">example: nike,branded shoes</span>
                  </div>
                  <div class="form-group">
                    <label for="styles">Style</label>
                    <select value={style} onChange={handleStyle} name="style">
                      {allStyle.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="after-market">
              <div class="inner-title flex-title">
                <span>
                  <span>After market</span>
                  <i onClick={handleAMContainer} class="fa fa-dot-circle expand-circle"></i>
                </span>
                <i onClick={addAMs} class="fa fa-plus add-btn"></i>
              </div>
              <div class={afterMarketContainer}>
                <div class="form-group">
                  <label for="prod_aftermarket">Product image</label>
                  <input
                    onChange={(e) => {
                      let a = topAMChange;
                      let reader = new FileReader();
                      reader.readAsDataURL(e.target.files[0]);
                      reader.onload = () => {
                        a.img = reader.result;
                        setTopAmChange({ ...a });
                      };
                    }}
                    type="file"
                    name="prod_aftermarket"
                    id="prod_aftermarket"
                  />
                </div>
                <div class="form-group">
                  <label for="prod_aftermarket">MarketPlace logo</label>
                  <input
                    onChange={(e) => {
                      let a = topAMChange;
                      let reader = new FileReader();
                      reader.readAsDataURL(e.target.files[0]);
                      reader.onload = () => {
                        a.logo = reader.result;
                        setTopAmChange(a);
                      };
                    }}
                    type="file"
                    name="prod_aftermarket_logo"
                    id="prod_aftermarket"
                  />
                </div>
                <div class="form-group">
                  <label for="marketplace">Marketplace</label>
                  <select
                    value={topAMChange.name}
                    onChange={(e) => {
                      let a = topAMChange;
                      a.name = e.target.value;
                      setTopAmChange({ ...a });
                    }}
                    name="marketplace"
                    id="marketplace"
                    class="pointer"
                  >
                    {allMarketplace.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                    {/* <option value="amazon">Amazon</option>
                    <option value="Ebay">Ebay</option> */}
                  </select>
                </div>
                <div class="form-group">
                  <label for="product_link">Product Link</label>
                  <input
                    value={topAMChange.affiliateLink}
                    onChange={(e) => {
                      let a = topAMChange;
                      a.affiliateLink = e.target.value;
                      setTopAmChange({ ...a });
                    }}
                    type="text"
                    name="product_link"
                    id="product_link"
                    placeholder="product link"
                  />
                </div>
              </div>
              {afterMarkets.map((item, index) => (
                <AfterMarketForm index={index} item={item} setAfterMs={setAfterMs} />
              ))}
            </div>
            <div class="where-to-buy">
              <div class="inner-title flex-title">
                <span>
                  <span>Where to buy </span>
                  <i onClick={handleWTBContainer} class="fa fa-dot-circle expand-circle"></i>
                </span>
                <i onClick={addWTB} class="fa fa-plus add-btn"></i>
              </div>
              <div class={whereToBuyContainer}>
                {wtbChange.map((item, index) => {
                  return <WhereToBuyForm key={index} index={index} item={item} setWTBChange={setWTBChange} />;
                })}
              </div>
            </div>
            <div class="images-and-save">
              <div class="form-group">
                <label for="images">Images</label>
                <input onChange={handleOtherImages} type="file" name="images" id="images" multiple />
              </div>
              <div class="submit">
                <button type="button" onClick={saveProduct} class="save-button" name="save_draft">
                  <span class="lnr lnr-checkmark-circle"></span> Draft
                </button>
                <button type="submit" class="publish-button" name="publish_live">
                  <span class="lnr lnr-pencil"></span> Live
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default EditProduct;
