import React, { useState, useEffect } from 'react';

const AdminProfile = () => {
  return (
    <>
      <section id="profile" class="left-margin-container">
        <div class="container profile-container">
          <h1>Profile</h1>
          <span class="text-sm">Note: please enter old password to make changes.</span>
          <form action="" class="profile-form">
            <div class="form-group">
              <label for="profile_name">Name</label>
              <input type="text" name="profile_name" id="profile_name" placeholder="Name" />
            </div>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" name="email" id="email" placeholder="Email" />
            </div>
            <div class="form-group">
              <label for="profile_image">Profile image</label>
              <input type="file" name="profile_image" id="profile_image" />
            </div>
            <div class="form-group">
              <label for="old_password">Old Password</label>
              <input type="password" name="old_password" id="old_password" placeholder="old password" />
            </div>
            <div class="form-group">
              <label for="new_password">New Password</label>
              <input type="password" name="new_password" id="new_password" placeholder="new password" />
            </div>
            <button type="submit" class="save-button" name="save">
              <span class="lnr lnr-checkmark-circle"></span> Edit
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default AdminProfile;
