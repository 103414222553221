import React, { useState } from 'react';
import ResetEmail from './resetEmail';
import ResetEmailLink from './resetLinkSent';

const Reset = () => {
  const [emailSent, setEmailSent] = useState(false);

  function handleEmailSent(value) {
    setEmailSent(value);
  }

  return (
    <>
      {!emailSent && <ResetEmail setEmailSent={handleEmailSent} />}
      {emailSent && <ResetEmailLink />}
    </>
  );
};

export default Reset;
