import { useState, useEffect } from 'react';

const PopularStories = ({ news }) => {
  const [stories, setStories] = useState([]);

  useEffect(() => {
    let filteredNews = news.filter((item) => item.topic.toUpperCase() === 'POPULAR');

    setStories(filteredNews);
  }, [news]);

  return (
    <section id="popular-stories">
      <div className="container-blog-img">
        <h2>Popular Stories</h2>
        <div className="popular-cards">
          {stories.length > 0
            ? stories.slice(0, 8).map((story) => {
                return (
                  <div
                    key={story._id}
                    onClick={() => (window.location.href = `/blog/${story._id}`)}
                    href="#"
                    className="popular-card"
                  >
                    <img src={story.mainImage.url} alt="popular" />
                    <p>{story.title}</p>
                  </div>
                );
              })
            : 'Nothing to show '}
        </div>
      </div>
    </section>
  );
};

export default PopularStories;
