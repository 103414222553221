import { useEffect, useState } from 'react';

const useDisableBodyScroll = (open) => {
  const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    const width = window?.innerWidth - document?.documentElement?.clientWidth;
    return setScrollWidth(width);
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollWidth}px`;
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0px';
    }
  }, [open]);
};

export default useDisableBodyScroll;
