import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import axios from 'axios';

import { useLocalStorage } from './hooks/useLocalStorage';

import MainLayout from './layouts/MainLayout';
import Admin from './admin';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import Search from './pages/Search';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import Support from './pages/Support';
import News from './pages/News';
import Blog from './pages/Blog';
import Styles from './pages/Styles';
import SavedItem from './pages/SavedItem';
import Apparel from './pages/Apparel';
import Home from './pages/HomePage/Home';

import Overview from './adminPages/overview';
import PagesContent from './adminPages/PagesContent';
import AddProduct from './adminPages/products/addProduct';
import AdminAbout from './adminPages/about';
import Brand from './adminPages/brand';
import Category from './adminPages/category';
import ProductFor from './adminPages/productFor';
import Email from './adminPages/email';
import Subscribers from './adminPages/Subscribers';
import Marketplaces from './adminPages/marketplaces';
import AdminProfile from './adminPages/adminProfile';
import StylesCatagories from './adminPages/styles/StylesCatagories';
import StylesListings from './adminPages/styles/StylesListings';
import StylesImages from './adminPages/styles/StylesImages';
import ProductTopics from './adminPages/products/addTopics';
import AllListingsProduct from './adminPages/products/allListings';
import AddBlog from './adminPages/blogs/addBlog';
import BlogTopics from './adminPages/blogs/addTopics';
import AllListingsBlog from './adminPages/blogs/allListings';
import EditProduct from './adminPages/products/editProduct';
import EditBlog from './adminPages/blogs/editBlog';
import Product from './pages/PBlog';

import Reset from './pages/resetPage';
import ResetPassword from './pages/resetPassword';

import PrivacyPolicy from './pages/privacyPolicy';
import Terms from './pages/terms';
import ErrorPage from './pages/404';

function App() {
  const [msg, setMsg] = useState('');
  const [selectedTab, setSelectedTab] = useState(-1);
  const [products, setProducts] = useState([]);
  const [styles, setStyles] = useState([]);
  const [filters, setFilters] = useState({});
  const [pagesContent, setPagesContent] = useState([]);
  const [news, setNews] = useState([]);
  const [savedSlides, setSavedSlides] = useState([[], [], [], [], []]);

  const tab0 = useRef([]);
  const tab1 = useRef();
  const tab2 = useRef();
  const tab3 = useRef();
  const tab4 = useRef();
  const refs = [tab0, tab1, tab2, tab3, tab4];

  const [savedItems, setSavedItems] = useLocalStorage('_sneakers_products_');

  function handleMessage(message) {
    setMsg(message);
  }

  const fetchProducts = async () => {
    const res = await axios.get('/products/getProducts').then((res) => res.data.products);

    return res;
  };

  const fetchStyles = async () => {
    const res = await axios.get('styles/get').then((res) => res.data.styles);

    return res;
  };

  const fetchFilters = async () => {
    const res = await axios.get('/filter/all').then((res) => res.data.filters);

    return res;
  };

  const fetchContent = async () => {
    const res = await axios.get('/content/get').then((res) => res.data.content);

    return res;
  };

  const fetchNews = async () => {
    const res = await axios.get('/blog/getBlog').then((res) => res.data.blogs);

    return res;
  };

  useEffect(() => {
    const getProducts = async () => {
      const productsFromServer = await fetchProducts();

      setProducts(productsFromServer);
    };

    const getStyles = async () => {
      const stylesFromServer = await fetchStyles();

      setStyles(stylesFromServer);
    };

    const getFilters = async () => {
      const filtersFromServer = await fetchFilters();
      setFilters(filtersFromServer);
    };

    const getContent = async () => {
      const contentFromServer = await fetchContent();

      setPagesContent(contentFromServer);
    };

    const getNews = async () => {
      const newsFromServer = await fetchNews();

      setNews(newsFromServer);
    };

    getProducts();
    getStyles();
    getFilters();
    getContent();
    getNews();
  }, []);

  useEffect(() => {
    savedSlidesSetter();
  }, []);

  const savedSlidesSetter = () => {
    let t = JSON.parse(localStorage.getItem('slider'));

    if (t) {
      t = t.map((catItems) => {
        if (catItems) {
          const parsed = catItems?.map((products) => JSON.parse(products));

          return parsed;
        } else {
          return [];
        }
      });

      setSavedSlides([...t]);
    }
  };

  return (
    <>
      <Router>
        <Routes>
          <Route
            element={
              <MainLayout savedItems={savedItems} pagesContent={pagesContent} products={products} filters={filters} />
            }
          >
            <Route
              index
              path="/"
              element={
                <Home
                  products={products}
                  savedItems={savedItems}
                  setSavedItems={setSavedItems}
                  refs={refs}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  savedSlides={savedSlides}
                  savedSlidesSetter={savedSlidesSetter}
                  pagesContent={pagesContent}
                />
              }
            />
            <Route
              path="/product/:id"
              element={
                <Product
                  products={products}
                  news={news}
                  savedItems={savedItems}
                  setSavedItems={setSavedItems}
                  setSelectedTab={setSelectedTab}
                  savedSlidesSetter={savedSlidesSetter}
                />
              }
            />
            <Route
              path="/apparel"
              element={
                <Apparel
                  products={products}
                  savedItems={savedItems}
                  setSavedItems={setSavedItems}
                  refs={refs}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  savedSlides={savedSlides}
                  savedSlidesSetter={savedSlidesSetter}
                  pagesContent={pagesContent}
                />
              }
            />
            <Route
              path="/search/:search"
              element={
                <Search
                  products={products}
                  savedItems={savedItems}
                  setSavedItems={setSavedItems}
                  refs={refs}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  savedSlides={savedSlides}
                  savedSlidesSetter={savedSlidesSetter}
                />
              }
            />
            <Route path="/contact_us" element={<ContactUs />} />
            <Route path="/support" element={<Support />} />
            <Route path="/saved_item" element={<SavedItem savedItems={savedItems} setSavedItems={setSavedItems} />} />
            <Route path="/styles" element={<Styles styles={styles} filters={filters} pagesContent={pagesContent} />} />
            <Route path="/news" element={<News />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog/:id" element={<Blog news={news} />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/resetPassword/:id" element={<ResetPassword />} />
          </Route>

          <Route path="/admin" element={<Admin message={msg} />}>
            <Route path="/admin/" element={<Overview getMessage={handleMessage} />} />
            <Route
              path="/admin/pages-content/"
              element={
                <PagesContent
                  getMessage={handleMessage}
                  pagesContent={pagesContent}
                  setPagesContent={setPagesContent}
                />
              }
            />
            <Route path="/admin/about" element={<AdminAbout getMessage={handleMessage} />} />
            <Route path="/admin/brand" element={<Brand getMessage={handleMessage} />} />
            <Route path="/admin/category" element={<Category getMessage={handleMessage} />} />
            <Route path="/admin/productFor" element={<ProductFor getMessage={handleMessage} />} />
            <Route path="/admin/email" element={<Email getMessage={handleMessage} />} />
            <Route path="/admin/subscribers" element={<Subscribers getMessage={handleMessage} />} />
            <Route path="/admin/marketplaces" element={<Marketplaces getMessage={handleMessage} />} />
            <Route path="/admin/adminProfile" element={<AdminProfile getMessage={handleMessage} />} />
            <Route path="/admin/styles/categories" element={<StylesCatagories getMessage={handleMessage} />} />
            <Route
              path="/admin/styles/images"
              element={<StylesImages getMessage={handleMessage} products={products} />}
            />
            <Route path="/admin/styles/all" element={<StylesListings getMessage={handleMessage} />} />
            <Route path="/admin/product/add" element={<AddProduct getMessage={handleMessage} />} />
            <Route path="/admin/product/addTopics" element={<ProductTopics getMessage={handleMessage} />} />
            <Route path="/admin/product/all" element={<AllListingsProduct getMessage={handleMessage} />} />
            <Route path="/admin/product/:id" element={<EditProduct getMessage={handleMessage} />} />
            <Route path="/admin/blog/add" element={<AddBlog getMessage={handleMessage} />} />
            <Route path="/admin/blog/addTopics" element={<BlogTopics getMessage={handleMessage} />} />
            <Route path="/admin/blog/all" element={<AllListingsBlog getMessage={handleMessage} />} />
            <Route path="/admin/blog/:id" element={<EditBlog getMessage={handleMessage} />} />
          </Route>
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
