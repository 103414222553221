import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';

import '../css/about_us.css';

const About = () => {
  const [email, setEmail] = useState('');
  const [about, setAbout] = useState({});

  useEffect(() => {
    axios.get('/about/getAbout').then((res) => {
      if (res.data.about) {
        setAbout(res.data.about);
      } else {
        window.location.href = '/';
      }
    });
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);
  };

  return (
    <>
      <div id="about-us">
        <div
          className="about-us-container"
          style={{ background: `url(${about.mainImage?.url}) no-repeat center center/cover` }}
        >
          {/* <h1 className="heading-text">We're changing the <br /> <span className="center-heading">whole game.</span></h1> */}
          <h1 className="heading-text">{about.heading}</h1>
          <div className="links-container">
            <Link to="/register" className="get-started-btn">
              Get started
            </Link>
            <Link to="/styles" className="pricing-btn">
              View products
            </Link>
          </div>
        </div>
      </div>

      {/* <!-- section: our-story --> */}
      <section id="our-story">
        <div className="container our-story-container">
          <div className="left-section">
            <p className="uppercase our-story-text">our story</p>
            <h2 className="story-description-text">{about.shortDescription}</h2>
          </div>
          <div className="right-section">
            {about.story?.map((story, index) => {
              return (
                <div key={index + 'story'} className="story">
                  <div className="story-heading">
                    <span>0{index + 1}.</span>
                    <p>{story.title}</p>
                  </div>
                  <p className="story-paragraph">{story.subtitle}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* <!-- section: our-mission --> */}
      <div id="our-mission">
        <div className="container our-mission-container">
          <div className="text-container">
            <div className="our-mission-text">Our Mission</div>
            <p className="description-text">{about.mission?.text}</p>
          </div>
          <div className="img-container">
            <img src={about.mission?.img.url} alt="our mission" />
          </div>
        </div>
      </div>

      {/* <!-- section: our-vision --> */}
      <section id="our-vision" className="py-5">
        <div className="container our-vision-container">
          <div className="img-container">
            <img src={about.vision?.img.url} alt="our mission" />
          </div>
          <div className="text-container">
            <div className="our-vusion-text">Our Vision</div>
            <p className="description-text">{about.vision?.text}</p>
          </div>
        </div>
      </section>

      {/* <!-- section: trial-and-subscribe --> */}
      <div id="trial-and-subscribe">
        <div className="container trial-and-subscribe-container">
          <div className="text">
            Subscribe our <br /> News-letter
          </div>
          <form action="" method="" className="subscribe-form">
            <div className="form-group">
              <input
                value={email}
                type="email"
                name="email_subscribe"
                id="email_subscribe"
                onChange={handleChange}
                placeholder="Enter your email address"
              />
              <button type="submit">Subscribe Now</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default About;
