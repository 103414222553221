export const addClass = (selector, classToAdd) => {
  const element = document.querySelector(`.${selector}`);

  element.classList.add(classToAdd);
};

export const removeClass = (selector, classToRemove) => {
  const element = document.querySelector(`.${selector}`);

  element.classList.remove(classToRemove);
};
