import Product from './Product';

const Products = ({ products, topic, topicLimit, refs, savedSlidesSetter, savedItems, setSavedItems }) => {
  let productWithTopic;
  let limit = topicLimit;

  if (topic) {
    productWithTopic = products.filter((item) => item.topic === topic);
  } else {
    productWithTopic = products;
    limit = Infinity;
  }

  return (
    <div className="releases">
      {productWithTopic.map(
        (product, index) =>
          index < limit && (
            <Product
              key={product._id}
              refs={refs}
              product={product}
              savedSlidesSetter={savedSlidesSetter}
              savedItems={savedItems}
              setSavedItems={setSavedItems}
            />
          )
      )}
    </div>
  );
};

export default Products;
