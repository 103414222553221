import { useState, useEffect } from 'react';

import Products from './Products';

// const categoryDict = {
//   Sneaker: 0,
//   Pant: 1,
//   'T-shirt': 2,
//   Cap: 3,
//   Accessories: 4
// };
const HomeProductList = ({ products, refs, savedSlidesSetter, savedItems, setSavedItems }) => {
  // const [productTopic, setProductTopic] = useState([]);
  const [topicLimit, setTopicLimit] = useState(8);
  const [randoms, setRandoms] = useState([]);
  // let top = 25;

  const productTopics = ['Trending', 'NEW RELEASES', "Jordan's"];

  useEffect(() => {
    const shuffled = products.sort(() => 0.5 - Math.random());
    setRandoms(shuffled.slice(0, 4));
  }, [products]);

  // const saveToLocalStorage = (index) => {
  //   const slider = JSON.parse(localStorage.getItem('slider')) || [[], [], [], [], []];

  //   if (slider[categoryDict[randoms[index].category]].includes(JSON.stringify(randoms[index]))) {
  //     const indexofElement = slider[categoryDict[randoms[index].category]].indexOf(JSON.stringify(randoms[index]));
  //     slider[categoryDict[randoms[index].category]].splice(indexofElement, 1);
  //   }

  //   slider[categoryDict[randoms[index].category]].push(JSON.stringify(randoms[index]));
  //   localStorage.setItem('slider', JSON.stringify(slider));
  //   savedSlidesSetter();

  //   if (refs[categoryDict[randoms[index].category]].current.className != 'tab') {
  //     refs[categoryDict[randoms[index].category]].current.click();
  //   }

  //   refs[categoryDict[randoms[index].category]].current.click();
  //   window.scrollTo({
  //     top: refs[categoryDict[randoms[index].category]].current.offsetTop,
  //     behavior: 'smooth'
  //   });
  // };

  // const getProductTopic = async () => {
  //   const res = await axios.get('/filter/productTopics').then((res) => res.data.result);
  //   const r = res.filter((item) => item !== 'LANDING' && item !== 'NONE');
  //   console.log('res', res);

  //   const topic = topicOrder.map((item) => {
  //     const tempLimit = topicLimit;
  //     console.log('tempLimit', tempLimit);
  //     tempLimit[item] = 7;
  //     setTopicLimit({ ...tempLimit });

  //     return { topic: item, hide: false };
  //   });

  //   setProductTopic(topic);
  // };

  // const hideSection = (item, index) => {
  //   const newTopic = productTopic;
  //   newTopic[index].hide = true;

  //   setProductTopic([...newTopic]);
  // };

  // const showSection = (item, index) => {
  //   const newTopic = productTopic;
  //   newTopic[index].hide = false;

  //   setProductTopic([...newTopic]);
  // };

  return (
    <>
      {/* {productTopic.map((item, index) => {
        let t = top * (index + 1);

        console.log('t', t);

        return (
          <div
            key={index}
            onClick={() => {
              showSection(item, index);
            }}
            className={
              item.hide
                ? `red-sidebar-click top-${t} new-releases-category`
                : `red-sidebar-click top-${t} new-releases-category hide`
            }
          >
            {item.topic}
          </div>
        );
      })} */}

      {productTopics.length > 0
        ? productTopics.map(
            (topic, index) =>
              products.filter((it) => it.topic === topic).length > 0 && (
                <section id="new-releases" key={index}>
                  <div className="container new-releases-container">
                    <h1 className="uppercase">
                      {topic}{' '}
                      {/* <span
                    className="hide-show-btn"
                    onClick={() => hideSection(topic, index)}>
                    hide
                  </span> */}
                    </h1>
                    {products.length > 0 ? (
                      <Products
                        products={products}
                        topic={topic}
                        topicLimit={topicLimit}
                        refs={refs}
                        savedSlidesSetter={savedSlidesSetter}
                        savedItems={savedItems}
                        setSavedItems={setSavedItems}
                      />
                    ) : (
                      <p>No Item to show</p>
                    )}
                    {products.filter((it) => it.topic === topic).length > topicLimit && (
                      <div
                        onClick={() => {
                          const tempLimit = topicLimit + 8;
                          setTopicLimit(tempLimit);
                        }}
                        className="last-button"
                      >
                        <a style={{ cursor: 'pointer' }} className="uppercase classic-black-btn">
                          Load more
                        </a>
                      </div>
                    )}
                  </div>
                </section>
              )
          )
        : 'Nothing to show '}
      {randoms.length > 0 && (
        <section id="new-releases">
          <div id="lit-kicks" className="container new-releases-container">
            <h1 className="uppercase">Lit Kicks</h1>
            <Products
              products={randoms}
              refs={refs}
              savedSlidesSetter={savedSlidesSetter}
              savedItems={savedItems}
              setSavedItems={setSavedItems}
            />
          </div>
        </section>
      )}
    </>
  );
};

export default HomeProductList;
