import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import axios from 'axios';

import EnlargedImage from '../../components/Modals/EnlargedImage';

import CloudinaryUploadWidget from '../../cloudinaryWidget/CloudinaryUploadWidget';

import { imgDelete } from '../../utils/img';
import { pagesWithMedia } from '../../constants/data';

import './styles.css';

const PagesContent = ({ getMessage, pagesContent, setPagesContent }) => {
  getMessage('Upload video or image');

  const layouts = ['center', 'left', 'right'];

  const [contentTitle, setContentTitle] = useState('');
  const [page, setPage] = useState('main');
  const [layout, setLayout] = useState('center');
  const [sound, setSound] = useState(false);

  const filteredContent = pagesContent.filter((content) => content.page === page);

  const [uploadSignature, setUploadSignature] = useState({});
  const [uploadResult, setUploadResult] = useState(null);
  const [uploadError, setUploadError] = useState({});

  console.log('uploadError', uploadError);

  const { toggler } = useOutletContext();

  const [enlargedImage, setEnlargeImage] = useState('');
  const [showEnlImage, setShowEnlImage] = useState(false);

  function handleTitle(e) {
    e.preventDefault();
    setContentTitle(e.target.value);
  }

  function handlePage(e) {
    e.preventDefault();
    setPage(e.target.value);
  }

  function handleLayout(e) {
    e.preventDefault();
    setLayout(e.target.value);
  }

  function handleCheckBox(e) {
    setSound(!sound);
  }

  useEffect(() => {
    async function getSignature() {
      axios
        .get('/image/sign', {
          headers: {
            Authorization: localStorage.getItem('accessToken')
          }
        })
        .then((result) => {
          setUploadSignature(result.data);
        })
        .catch((error) => {
          console.log('error', error);
        });
    }

    getSignature();
  }, []);

  useEffect(() => {
    async function addPageContent() {
      const content = {
        publicId: uploadResult.public_id,
        url: 'https' + uploadResult.url.slice(4),
        resourceType: uploadResult.resource_type,
        format: uploadResult.format,
        thumbnailUrl: uploadResult.thumbnail_url
      };

      axios
        .post(
          '/content/add',
          {
            content: content,
            contentTitle,
            page,
            layout,
            sound
          },
          {
            headers: {
              Authorization: localStorage.getItem('accessToken')
            }
          }
        )
        .then((res) => {
          const result = res.data.data;

          setPagesContent([...pagesContent, result]);
          setContentTitle('');

          /*to add notification*/
        })
        .catch((error) => {
          if (error.response.data.msg === 'Invalid token') {
            axios
              .get('/refresh', {
                headers: {
                  Refresh: localStorage.getItem('refreshToken')
                }
              })
              .then((res) => {
                localStorage.setItem('accessToken', res.data.accessToken);
                axios
                  .post(
                    '/content/add',
                    {
                      content: content,
                      contentTitle,
                      page,
                      layout,
                      sound
                    },
                    {
                      headers: {
                        Authorization: localStorage.getItem('accessToken')
                      }
                    }
                  )
                  .then((res) => {
                    const result = res.data.data;

                    setPagesContent([...pagesContent, result]);
                    setContentTitle('');

                    /*to add notification*/
                  })
                  .catch((err) => console.log('error', err));
              })
              .catch((err) => console.log('error', err));
          }
        });
    }

    if (uploadResult) {
      addPageContent();
    }
  }, [uploadResult]);

  async function handleDelete(content, e) {
    e.stopPropagation();

    const index = pagesContent.findIndex((c) => c._id === content._id);
    const tempArr = pagesContent;
    tempArr.splice(index, 1);

    await axios
      .delete(`/content/delete/${content._id}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken')
        }
      })
      .then((_res) => {
        imgDelete(content.content, 'single');

        setPagesContent([...tempArr]);
      })
      .catch((error) => {
        if (error.response.data.msg === 'Invalid token') {
          axios
            .get('/refresh', {
              headers: {
                Refresh: localStorage.getItem('refreshToken')
              }
            })
            .then((res) => {
              localStorage.setItem('accessToken', res.data.accessToken);

              axios.delete(`/content/delete/${content._id}`, {
                headers: {
                  Authorization: localStorage.getItem('accessToken')
                }
              });
            })
            .then((_res) => {
              imgDelete(content.content, 'single');

              setPagesContent([...tempArr]);
            })
            .catch((err) => (window.location.href = '/'));
        }
      });
  }

  return (
    <>
      <section id="add-product-form" class={toggler ? 'left-margin-container' : 'left-margin-container margin-remove'}>
        <div class="container add-product-form-container">
          <form class="add-product-form">
            <div class="product-container">
              <div class="top-text-container add-product-header">
                <h1>
                  <span>Add video or image to selected page</span>
                </h1>
              </div>
              <div class="line-bar"></div>
              <div class="product-container-content">
                <div class="product-and-author">
                  <div class="form-group">
                    <label for="content_title">Content title </label>
                    <input
                      type="text"
                      name="content_title"
                      id="content_title"
                      placeholder="Type title to display with image on the page"
                      value={contentTitle}
                      onChange={handleTitle}
                    />
                  </div>
                </div>
                <div class="filter-and-stock">
                  <div class="form-group">
                    <label for="page">Page</label>
                    <select value={page} onChange={handlePage} name="page" id="page">
                      {pagesWithMedia.map((page) => {
                        return <option value={page}>{page}</option>;
                      })}
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="layout">Layout</label>
                    <select value={layout} onChange={handleLayout} name="page" id="page">
                      {layouts.map((layout) => {
                        return <option value={layout}>{layout}</option>;
                      })}
                    </select>
                  </div>

                  <div class="sound-group">
                    <label for="sound">Play sound</label>
                    <input type="checkbox" checked={sound} onChange={handleCheckBox} />
                  </div>
                </div>
              </div>
              <div class="filter-and-stock"></div>
            </div>
          </form>
        </div>
        {filteredContent.length ? (
          <>
            <div>
              Remove the {filteredContent[0].content.resourceType} below before uploading the new one to the{' '}
              {page.toUpperCase()} page.
            </div>
            <div class="listing-table-wrapper">
              <table class="listings-table">
                <thead>
                  <tr>
                    <th>Page</th>
                    <th>Title</th>
                    <th>Layout</th>
                    <th>Type</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    key={'index'}
                    onClick={() => {
                      setEnlargeImage(filteredContent[0].content.url);
                      setShowEnlImage(true);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <td>{filteredContent[0].page}</td>
                    <td>{filteredContent[0].contentTitle}</td>
                    <td>{filteredContent[0].layout}</td>
                    <td>{filteredContent[0].content.resourceType}</td>
                    <td
                      class="options"
                      onClick={(e) => handleDelete(filteredContent[0], e)}
                      style={{ cursor: 'default' }}
                    >
                      <div class="lnr lnr-trash" style={{ cursor: 'pointer' }}></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <CloudinaryUploadWidget
            uploadSignature={uploadSignature}
            setUploadResult={setUploadResult}
            setUploadError={setUploadError}
          />
        )}
        <EnlargedImage showEnlImage={showEnlImage} setShowEnlImage={setShowEnlImage} enlargedImage={enlargedImage} />
      </section>
    </>
  );
};

export default PagesContent;
