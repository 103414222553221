import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';

import parse from 'html-react-parser';

import PopularStories from '../components/Stories/PopularStories';

import InstagramIcon from '../styles/icons/InstagramIcon';
import FacebookIcon from '../styles/icons/FacebookIcon';

import '../css/slick.css';
import '../css/slick-theme.css';
import '../css/style.css';
import '../css/sneaker_news.css';
import '../css/sneaker_blogpost.css';

const Blog = ({ news }) => {
  const { id } = useParams();
  const [blogToDisplay, setBlogToDisplay] = useState([]);
  const [afterMarket, setAfterMarket] = useState({});
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    const getBlogToDisplay = async () => {
      const blog = await fetchBlogToDisplay(id);
      let temp = {};
      let dest = [];
      blog.afterMarkets?.map((item) => {
        if (!temp[item.destination]) {
          dest.push(item.destination);
          temp[item.destination] = [item];
        } else {
          temp[item.destination].push(item);
        }
      });
      setDestinations([...dest]);
      setAfterMarket({ ...temp });
      setBlogToDisplay(blog);
    };

    getBlogToDisplay();
  }, []);

  const fetchBlogToDisplay = async () => {
    const res1 = await axios.get(`/blog/getBlog/${id}`).catch((error) => {
      window.location.href = '/';
    });

    return res1.data.blog;
  };

  function displayProduct() {
    return (
      <>
        <section id="blog">
          <div class="container-blog-img" style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={blogToDisplay.mainImage?.url} alt="MainImg" />
          </div>
          <div class="container-blog-content">
            <h1>{blogToDisplay.productName}</h1>
            <div class="content-info">
              <div class="post-info">
                <div className="post-info">
                  <span>by</span> <span className="posted-by"> {blogToDisplay.author}</span>
                </div>
              </div>
              <div class="post-disclaimer">
                <span class="lnr lnr-file-empty"></span>
                <span class="disclaimer-text">2feat may receive compensation when you click on links to products</span>
              </div>
            </div>
            <div className="social-media">
              <a href="https://www.instagram.com/2.feat/" rel="noreferrer" target="_blank">
                <InstagramIcon />
              </a>
              <a href="https://www.instagram.com/2.feat/" rel="noreferrer" target="_blank">
                <FacebookIcon />
              </a>
              <h3>Be sure to check out our social media for more styling tips and photos</h3>
            </div>
            <p>{blogToDisplay.description ? parse(blogToDisplay.description) : ''}</p>
            <div class="product-info">
              <h2>{blogToDisplay.title}</h2>
              <div class="major-info release-date">
                Release Date: {new Date(blogToDisplay.releaseDate).toDateString()}
              </div>
              <div class="minor-info product-color">Color: {blogToDisplay.color}</div>

              <div class="major-info product-price">Mens: ${blogToDisplay.price}</div>
              <div class="minor-info style-code">Style Code: {blogToDisplay.styleCode}</div>
            </div>
            {blogToDisplay?.whereToBuy?.length > 0 ? (
              <>
                <div className="where-to-buy-title">
                  <h2>Where to buy Retail</h2>
                </div>
                <div className="cards">
                  {blogToDisplay.whereToBuy?.map((item) => {
                    if (item !== null)
                      return (
                        <a key={item.url} href={item?.url} rel="noreferrer" target="_blank">
                          <div className="buy-card">
                            <h3>{item?.name.toUpperCase()}</h3>
                          </div>
                        </a>
                      );
                  })}
                </div>
              </>
            ) : null}
            <div class="available-market">
              <div class="after-market">
                <div class="heading">
                  <h2>After Market</h2>
                  <span>Available Now</span>
                </div>
                <div class="banner">
                  <img src={blogToDisplay.topAfterMarket?.img.url} alt="shoe" />
                  <div class="buy-cta">
                    <img src={blogToDisplay.topAfterMarket?.logo.url} alt="ebay" />
                    <h2>{blogToDisplay.afterMarket?.marketPlace}</h2>
                    <div class="button">
                      <a href={blogToDisplay.topAfterMarket?.affiliateLink}>BUY IT NOW</a>
                    </div>
                  </div>
                </div>
              </div>
              {destinations.map((dest) => {
                return (
                  <div class="north-america">
                    <div class="heading">
                      <h2>{dest}</h2>
                      <span>{new Date(afterMarket[dest][0].marketDate).toDateString()}</span>
                    </div>
                    <ul>
                      {afterMarket[dest].map((place) => {
                        return (
                          <li>
                            <a href={place.affiliateLink}>
                              <strong>{place.name}</strong>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            class="container-blog-img"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {blogToDisplay.otherImages?.map((item) => {
              return <img src={item.url} alt="nike" />;
            })}
          </div>
          <div class="container-blog-img">
            <div class="blog-footer">
              <div class="tags">
                <span>TAGS / CATEGORIES:</span>
                <span>
                  {blogToDisplay.tags?.map((item, index) => {
                    return (
                      <Link to={`/search/${item}`} key={index} href="#">
                        {item}
                      </Link>
                    );
                  })}
                </span>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  return (
    <>
      {blogToDisplay && (
        <>
          {blogToDisplay && displayProduct()}
          <PopularStories news={news} />
        </>
      )}
    </>
  );
};

export default Blog;
