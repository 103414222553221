import { useNavigate } from 'react-router-dom';

import { categoryDict } from '../../constants/data';

import './styles.css';

const ProductPreview = ({ product, searchPreview, setShow, savedSlidesSetter, refs, freestyle }) => {
  const navigate = useNavigate();

  const navigateToProduct = (id) => {
    if (!freestyle) {
      document.body.style.overflow = 'unset';
      setShow(false);

      navigate(`/product/${id}`, { replace: true });
      // navigate(0);
    } else {
      const slider = JSON.parse(localStorage.getItem('slider')) || [[], [], [], [], []];

      if (slider[categoryDict[product.category]].includes(JSON.stringify(product))) {
        const indexofElement = slider[categoryDict[product.category]].indexOf(JSON.stringify(product));
        slider[categoryDict[product.category]].splice(indexofElement, 1);
      }

      slider[categoryDict[product.category]].push(JSON.stringify(product));
      localStorage.setItem('slider', JSON.stringify(slider));
      savedSlidesSetter();

      if (refs[categoryDict[product.category]].current.className != 'tab') {
        refs[categoryDict[product.category]].current.click();
      }

      refs[categoryDict[product.category]].current.click();

      window.scrollTo({
        top: refs[categoryDict[product.category]].current.offsetParent.offsetTop - 88,
        behavior: 'smooth'
      });

      setShow(false);
    }
  };

  return (
    <div
      className={searchPreview ? 'product-preview-tab-container' : 'product-preview-container'}
      onClick={() => navigateToProduct(product._id)}
    >
      <div className="product-image">
        <img
          style={{ objectFit: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }}
          src={product.mainImage.url}
          alt="product-preview"
        />
      </div>
      <div className="product-name">
        <span>{product.productName}</span>
      </div>
    </div>
  );
};

export default ProductPreview;
