import { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import axios from 'axios';
import Popup from 'reactjs-popup';

import '../../css/slick.css';
import '../../css/slick-theme.css';
import '../../css/style.css';
import '../../css/sneaker_news.css';
import '../../css/sneaker_blogpost.css';
import '../../css/saved_items.css';
import '../../css/toast_notification.css';

const SavedItem = ({ savedItems, setSavedItems }) => {
  const [product, setProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      const productsFromServer = await fetchnews();
      const filter = productsFromServer.filter((item) => savedItems.toString().includes(item._id));
      setProducts(filter);
    };

    getProducts();
  }, [savedItems]);

  const fetchnews = async () => {
    const res1 = await axios.get('/products/getProducts').then((res) => res.data.products);

    return res1;
  };

  const removeProduct = (id) => {
    const fil = [...savedItems];
    const newProductIds = fil.filter((t) => t !== id);
    setSavedItems(newProductIds);

    popup('Item Removed Successfully', { type: 'info', timeout: 1000 });
  };

  const node = document.createElement('div');
  const popup = (message, { type, timeout }) => {
    document.body.appendChild(node);
    const PopupContent = () => {
      return (
        <Popup type={type} open={true} timeout={timeout}>
          <div id="toast-notification">
            <div className="notice">
              <span className="check-circle redish-notice">
                <i className="lnr lnr-checkmark-circle"></i>
              </span>
              <span className="saved-text">{message}</span>
            </div>
          </div>
          {/* {message} */}
          {/* <button
            onClick={clear}
            >&times;</button> */}
        </Popup>
      );
    };

    setTimeout(function () {
      // element.remove();
      clear();
    }, 3000);

    const clear = () => {
      ReactDOM.unmountComponentAtNode(node);
      node.remove();
    };

    ReactDOM.render(<PopupContent />, node);
  };

  const renderSavedList = product.map((item, index) => {
    return (
      <>
        <div className="item" id="PROD_121" key={index}>
          <div className="img-container">
            <img src={item.mainImage.url} alt="" />
          </div>
          <div className="text-container">
            <div className="top-text">
              <div className="left-text">
                <div className="saved-item-name">{item.productName}</div>
                <div className="pricing-details" style={{ paddingTop: '.25 rem' }}>
                  <span className="item-price">${item.price} </span>
                  <span> | </span>
                  {/* <span className={item.inStock ? 'stock-details in-stock-color' : 'stock-details  out-of-stock-color'}>
                    {item.inStock ? 'In stock' : 'Out of Stock'}
                  </span> */}
                  {/* <span> | </span> */}
                  <Link to={`/product/${item._id}`} class="underline">
                    {' '}
                    Read details{' '}
                  </Link>
                </div>
              </div>
              <div className="right-text">
                <div>&#36;{item.price}</div>
              </div>
            </div>
            <div className="bottom-text">
              <span className="visit-link underline">
                <a href={item.topAfterMarket.affiliateLink} target="_blank">
                  <i className="lnr lnr-arrow-right-circle"></i> Visit {item.topAfterMarket.name} to buy
                </a>
              </span>
              <span className="pipe-sign"> | </span>
              <span className="remove-saved-item" onClick={() => removeProduct(item._id)}>
                <i className="lnr lnr-trash"></i> Remove
              </span>
            </div>
          </div>
        </div>
        <div className="saved-items-line"></div>
      </>
    );
  });

  return (
    <>
      <div id="saved-items">
        <div className="container saved-items-container">
          <h1 className="saved-items-title-text">My Saved items</h1>
          <div className="first-saved-items-line"></div>
        </div>
      </div>
      <div id="saved-items-list" style={{ minHeight: '34vh' }}>
        <div className="container saved-items">
          {renderSavedList.length ? (
            renderSavedList
          ) : (
            <h4 style={{ padding: '1.25rem .5rem' }}>
              You've not saved any items, please{' '}
              <Link to="/" className="underline">
                visit
              </Link>{' '}
              here to save items for purchasing.
            </h4>
          )}
        </div>
      </div>
    </>
  );
};

export default SavedItem;
