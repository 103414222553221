export const pagesWithMedia = ['main', 'styles', 'apparel'];

export const categoryDict = {
  Sneaker: 0,
  Pant: 1,
  'T-shirt': 2,
  Cap: 3,
  Accessories: 4,
  Hoodie: 5
};
