import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../css/reset_email_form.css";
import KeyChain from "../images/keychain.png";

const ResetEmail = ({ setEmailSent }) => {
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");

  function handleEmail(e) {
    e.preventDefault();
    setEmail(e.target.value);
  }

  function validate(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!emailRegex.test(email)) {
      setErrMsg("This is not a valid email format!");
      return false;
    }
    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const isValid = validate(email);
    if (isValid) {
      setErrMsg("");
      await axios
        .post("/reset/emailSend", { email })
        .then((res) => setEmailSent(true))
        .catch((err) => {
          if (err.response.status == 400) {
            setErrMsg(err.response.data.msg);
          } else {
            console.log(err.response);
          }
        });
    }
  }

  return (
    <>
      <section id="reset-email">
        <div class="container reset-email-container">
          <img src={KeyChain} alt="keychain" class="keychain-image" />
          <h1>Forgot password?</h1>
          <p>No worries, we'll send you reset instructions.</p>
          <form action="" class="reset-email">
            {errMsg != "" && (
              <p style={{ color: "red", fontSize: 12 }}>{errMsg}</p>
            )}
            <div class="form-group">
              <label for="email">Email</label>
              <input
                value={email}
                onChange={handleEmail}
                type="email"
                name="email"
                id="email"
                placeholder="enter reset email here..."
              />
            </div>
            <input
              type="submit"
              onClick={handleSubmit}
              value="Reset password"
            />
            <Link to={"/login"} class="login-link">
              <span class="lnr lnr-arrow-left"></span> Back to log in
            </Link>
          </form>
        </div>
      </section>
    </>
  );
};

export default ResetEmail;
