import { useState } from 'react';

export function useLocalStorage(localItem) {
  // const [loc,setState] = useState(localStorage.getItem(localItem))
  const [loc, setState] = useState(() => {
    const item = localStorage.getItem(localItem);
    return item ? JSON.parse(item) : '';
    // return item
  });

  function setLocalStorage(newItem) {
    localStorage.setItem(localItem, JSON.stringify(newItem));
    setState(newItem);
    // window.dispatchEvent(new Event('storage'));
  }

  return [loc, setLocalStorage];
}
