import { useState } from 'react';
import { Link } from 'react-router-dom';

import EnlargedImage from '../../../components/Modals/EnlargedImage';

import { categoryDict } from '../../../constants/data';

const Product = ({ product, refs, savedSlidesSetter, savedItems, setSavedItems }) => {
  const [enlargedImage, setEnlargeImage] = useState('');
  const [showEnlImage, setShowEnlImage] = useState(false);

  const saveToLocalStorage = () => {
    const slider = JSON.parse(localStorage.getItem('slider')) || [[], [], [], [], []];

    if (slider[categoryDict[product.category]].includes(JSON.stringify(product))) {
      const indexofElement = slider[categoryDict[product.category]].indexOf(JSON.stringify(product));
      slider[categoryDict[product.category]].splice(indexofElement, 1);
    }

    slider[categoryDict[product.category]].push(JSON.stringify(product));

    localStorage.setItem('slider', JSON.stringify(slider));

    savedSlidesSetter();

    if (refs[categoryDict[product.category]].current.className != 'tab') {
      refs[categoryDict[product.category]].current.click();
    }

    refs[categoryDict[product.category]].current.click();

    window.scrollTo({
      top: refs[categoryDict[product.category]].current.offsetParent.offsetTop - 88,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <div className="card release">
        <div
          className="img-container"
          onClick={() => {
            setEnlargeImage(product.mainImage.url);
            setShowEnlImage(true);
          }}
        >
          <img style={{ maxHeight: 150 }} src={product.mainImage.url} alt="product" />
        </div>
        <div className="card-text">
          <Link to={`/product/${product._id}`}>
            <span className="product-title">
              {product.productName.slice(0, 22)}
              {product.productName.length > 22 ? '...' : ''}
            </span>
          </Link>
          <b className="uppercase">
            {product.shortDesc.slice(0, 22)}
            {product.shortDesc.length > 22 ? '...' : ''}
          </b>
          <div className="read-more-container">
            <button
              onClick={() => {
                saveToLocalStorage();
              }}
              className="read-more-btns"
            >
              Freestyle It
            </button>
          </div>
        </div>
      </div>
      <EnlargedImage
        showEnlImage={showEnlImage}
        setShowEnlImage={setShowEnlImage}
        enlargedImage={enlargedImage}
        productId={product._id}
        savedItems={savedItems}
        setSavedItems={setSavedItems}
      />
    </>
  );
};

export default Product;
