import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import "../css/reset_link_sent.css"

const ResetEmailLink = ()=>{

    return(
        <>
            <section id="reset-link-sent">
                <div class="container reset-link-sent-container">
                    <h1>Sent!</h1>
                    <p>Check your email address and <br /> follow the instructions</p>
                    <span class="lnr lnr-location lnr-4"></span>
                    <Link to={'/'} class="underline home-link">goto home</Link>
                </div>
            </section>
        </>
    )
}

export default ResetEmailLink;
