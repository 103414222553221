import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import '../css/slick.css';
import '../css/slick-theme.css';
import '../css/style.css';
import '../css/sneaker_news.css';

const News = () => {
  const [FreeSec, setFreeSec] = useState([]);
  const [EditorPick, setEditorPick] = useState([]);
  const [TopThree, setTopThree] = useState([]);
  const [Latest, setLatestSec] = useState([]);
  const [filteredBlogs, setFiltered] = useState({});
  const [gotRes, setGotRes] = useState(false);

  useEffect(() => {
    const getNews = async () => {
      let FreeSection = [];
      let EditorPickSection = [];
      let TopThreeSection = [];
      let LatestSection = [];
      const newsFromServer = await fetchnews();
      let temp = {};
      let free = newsFromServer.filter((item) => {
        if (item.topic.toUpperCase().includes('Editor')) {
          EditorPickSection.push(item);
          return false;
        } else if (item.topic.toUpperCase().includes('TOP 3')) {
          TopThreeSection.push(item);
          return false;
        } else if (item.topic !== 'NONE') {
          if (temp[item.topic]) {
            temp[item.topic].push(item);
          } else {
            temp[item.topic] = [item];
          }
          return false;
        }
        return true;
      });

      LatestSection = free.slice(0, 6);
      FreeSection = free.slice(6, free.length);

      setEditorPick(EditorPickSection);
      setTopThree(TopThreeSection);
      setFiltered(temp);
      setFreeSec(FreeSection);
      setLatestSec(LatestSection);
      setGotRes(true);
    };
    getNews();
  }, []);

  const fetchnews = async () => {
    const res1 = await axios.get('/blog/getBlog').then((res) => res.data.blogs);
    return res1;
  };

  //loads 6 free post
  function LoadFreeNews(ppnews) {
    return (
      <div className="free-section" key={ppnews}>
        <div className="container free-section-container">
          <div className="free-news">
            {ppnews.length > 0
              ? ppnews.map((pnews) => (
                  <div className="news" key={pnews}>
                    <div className="img-container">
                      <Link to={`/blog/${pnews._id}`}>
                        <img src={pnews.mainImage} alt="" />
                      </Link>
                    </div>
                    <div className="story-text-container">
                      <Link to={`/blog/${pnews._id}`} className="link-title">
                        {pnews.title}
                      </Link>
                      <div className="ratings">
                        <span>
                          By:{' '}
                          <Link to={`/blog/${pnews._id}`} className="news-page-color">
                            {pnews.author}
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              : 'Nothing to show '}
          </div>
        </div>
      </div>
    );
  }
  function LoadEditorPick(editPick) {
    return (
      <section className="editor-pick" key={editPick}>
        <div className="container editor-pick-container">
          <Link to={`/blog/${editPick._id}`} className="img-container">
            <img src={editPick.img} alt="Editor's pick" />
          </Link>
          <div className="text-container">
            <p className="news-page-color">Editor's Pick:</p>
            <Link to={`/blog/${editPick._id}`} className="font-2 bold">
              {editPick.title}
            </Link>
            <div className="ratings">
              <span className="rating-score">
                <i className="lnr lnr-star"></i> {editPick.rating}/5 <span className="dot-div"></span>
              </span>
              <span className="date">
                {editPick.date}
                <span className="dot-div"></span>
              </span>
              <span>
                By:{' '}
                <Link to={`/blog/${editPick._id}`} className="news-page-color">
                  {editPick.author}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function divideNews() {
    const chunkSize = 6;
    let chunk = [];
    for (let i = 0; i < FreeSec.length; i += chunkSize) {
      chunk.push(FreeSec.slice(i, i + chunkSize));
    }
    return chunk;
  }

  function showRepeatingData() {
    const chunk = divideNews();
    const result = chunk.map((items, index) => {
      return (
        <>
          {LoadEditorPick(EditorPick[index])}
          {LoadFreeNews(items)}
        </>
      );
    });

    return <>{result}</>;
  }

  return (
    <>
      {!gotRes && (
        <div className="spinner-container" id="news-header">
          <div className="loading-spinner"></div>
        </div>
      )}
      {gotRes && (
        <>
          <section id="news-header">
            <div class="container news-header-container">
              {/* <!-- main-news --> */}
              <div
                class="main-news"
                style={{
                  backgroundImage: `url(${TopThree[0]?.mainImage?.url})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat'
                }}
              >
                <Link to={`/blog/${TopThree[0]?._id}`} class="news-front-text-container">
                  <h1>{TopThree[0]?.title ? TopThree[0].title : 'No Title'}</h1>
                  <p>{TopThree[0]?.shortDesc ? TopThree[0].shortDesc : 'No Shoet Description'}</p>
                </Link>
              </div>
              {/* <!-- sidebar-news-items --> */}
              <div class="sidebar-news-items">
                {/* <!-- top-item-container --> */}
                <Link to={`/blog/${TopThree[1]?._id}`} class="top-item-container">
                  <div
                    class="top-item-text-container"
                    style={{
                      backgroundImage: `url(${TopThree[1]?.mainImage?.url})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat'
                    }}
                  >
                    <h1>{TopThree[1]?.title ? TopThree[1].title : 'No Title'}</h1>
                    <p>{TopThree[1]?.shortDesc ? TopThree[1].shortDesc : 'No Shoet Description'}</p>
                  </div>
                </Link>
                {/* <!-- bottom-item-container --> */}
                <Link to={`/blog/${TopThree[2]?._id}`} class="bottom-item-container">
                  <div
                    class="bottom-item-text-container"
                    style={{
                      backgroundImage: `url(${TopThree[2]?.mainImage?.url})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat'
                    }}
                  >
                    <h1>{TopThree[2]?.title ? TopThree[2].title : 'No Title'}</h1>
                    <p>{TopThree[2]?.shortDesc ? TopThree[2].shortDesc : 'No Shoet Description'}</p>
                  </div>
                </Link>
              </div>
            </div>
          </section>
          {filteredBlogs &&
            Object.keys(filteredBlogs).map((key, value) => {
              return (
                <div id="popular-stories">
                  <div className="container popular-stories-container">
                    <div className="heading-text">
                      <h1 className="uppercase">{key}</h1>
                      <div className="liner-heading"></div>
                    </div>

                    <div className="stories">
                      {value.length > 0
                        ? value.slice(0, 8).map((news) => (
                            <Link to={`/blog/${news._id}`} className="story">
                              <div className="img-container">
                                <img src={news.mainImage.url} alt="product" />
                              </div>
                              <p>{news.title}</p>
                            </Link>
                          ))
                        : 'Nothing to show '}
                    </div>
                  </div>
                </div>
              );
            })}
          <div id="latest-stories">
            <div class="container latest-stories-container">
              <div class="latest-stories-header">
                <h1 class="uppercase">Latest stories</h1>
                <div class="liner-heading-2"></div>
              </div>
              <div class="stories">
                {Latest.length > 0
                  ? Latest.slice(0, 6).map((news) => (
                      <div class="story">
                        <div class="img-container">
                          <Link to={`/blog/${news._id}`}>
                            <img src={news.mainImage.url} alt="product" />
                          </Link>
                        </div>
                        <div class="story-text-container">
                          <Link to={`/blog/${news._id}`} class="link-title">
                            {news.title}
                          </Link>
                          <div class="ratings">
                            <span>
                              By:{' '}
                              <Link to={`/blog/${news._id}`} class="news-page-color">
                                {news.author}
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  : 'Nothing to show '}
              </div>
            </div>
          </div>
          {showRepeatingData()}
          {/* <ScrollToTopOnMount /> */}
        </>
      )}
    </>
  );
};

export default News;
