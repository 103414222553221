import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import axios from 'axios';

const StylesListings = ({ getMessage }) => {
  getMessage(`Styles section`);

  const { toggler } = useOutletContext();

  const [styleItems, setStyleItems] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [filters, setFilters] = useState([]);
  const [values, setValues] = useState('');

  useEffect(() => {
    axios.get('/styles/get').then((res) => {
      setStyleItems(res.data.styles);
      setFiltered(res.data.styles);
    });

    axios.get('/filter/all').then((res) => {
      const styleFilter = res.data.filters.styles;
      setFilters(styleFilter);
    });
  }, []);

  async function handleDelete(id, index) {
    const temp = styleItems;
    temp.splice(index, 1);

    await axios
      .delete(`/styles/delete/${id}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken')
        }
      })
      .catch((error) => {
        if (error.response.data.msg == 'Invalid token') {
          axios
            .get('/refresh', {
              headers: {
                Refresh: localStorage.getItem('refreshToken')
              }
            })
            .then((res) => {
              localStorage.setItem('accessToken', res.data.accessToken);

              axios.delete(`/styles/delete/${id}`, {
                headers: {
                  Authorization: localStorage.getItem('accessToken')
                }
              });
            })
            .catch((err) => (window.location.href = '/'));
        }
      });

    setStyleItems([...temp]);
  }

  function handleValues(e) {
    e.preventDefault();

    const temp = styleItems.filter((item) => {
      if (e.target.value === 'all') {
        return true;
      } else if (item.style === e.target.value) {
        return true;
      } else {
        return false;
      }
    });

    setFiltered([...temp]);
    setValues(e.target.value);
  }

  return (
    <>
      <section id="brand-" class={toggler ? 'left-margin-container' : 'left-margin-container margin-remove'}>
        <div class="container add-brand-container">
          <h1>All Style Items</h1>
          <div class="listing-table-wrapper">
            <select value={values} onChange={handleValues} name="filter_by" id="filter_by" class="email-filter">
              <option value="all">All</option>
              {filters.map((item, idx) => {
                return (
                  <option key={idx} value={item}>
                    {item.toUpperCase()}
                  </option>
                );
              })}
            </select>
            <table class="listings-table">
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Product name</th>
                  <th>Short description</th>
                  <th>Tags</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((style, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{style.styleName}</td>
                      <td>{style.shortDesc}</td>
                      <td>{style.tags.map((item) => item + ', ')}</td>
                      <td class="options">
                        {/* <Link
                          to={`/admin/style/${style._id}`}
                          class="edit"
                        >
                          <div class="lnr lnr-pencil"></div>
                        </Link> */}
                        <div onClick={() => handleDelete(style._id, index)} class="lnr lnr-trash"></div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default StylesListings;
