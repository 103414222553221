import { useState } from 'react';

import axios from 'axios';

import useMobile from '../hooks/useMobile';

import '../css/slick.css';
import '../css/slick-theme.css';
import '../css/style.css';
import '../css/contact_us.css';

const ContactUs = () => {
  const isMobile = useMobile();

  const [fName, SetfName] = useState('');
  const [lName, SetlName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = `<h3>Name: ${fName} ${lName}<h3/> <br /><h3>Email: ${email}<h3/> <br /><h3><b>Message: </b> ${message}<h3/>`;

    await axios.post('/email/contact', { body: body });
    SetfName('');
    SetlName('');
    setEmail('');
    setMessage('');
  };

  return (
    <>
      <div id="contact-us">
        <div className="container contact-us-container">
          {!isMobile ? <div className="img-container"></div> : null}
          <div className="text-container">
            <h1 className="header-text">Get in touch</h1>
            <p className="description-text">
              Have an inquiry or some feedback for us? Fill out the form below to contact out team.
            </p>
            <form className="contact-us-form-container" onSubmit={(e) => handleSubmit(e)}>
              <div className="names-container">
                <div className="form-group">
                  <label for="first_name">First name</label>
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    placeholder="Jane"
                    value={fName}
                    onChange={(e) => SetfName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label for="last_name">Last name</label>
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder="Smith"
                    value={lName}
                    onChange={(e) => SetlName(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <label for="email">Email address</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="janesmith@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label for="message">Message</label>
                <textarea
                  name="message"
                  id="message"
                  placeholder="Hit us up"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="form-group submit-btn-form-group">
                <input type="submit" value="Get in touch" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
