import './styles.css';

const Support = () => {
  return (
    <>
      <div id="support">
        <p className="uppercase support-title">
          Thank you for choosing 2feat.com as your affiliate marketing platform. We are committed to providing
          exceptional customer support to ensure your experience with our website is smooth and enjoyable.
        </p>
        <p>
          1. Contact Information: If you require assistance or have any inquiries, please reach out to our customer
          support team.
        </p>
        <p>
          2. Account Assistance: If you are facing any issues related to your account, such as login problems, password
          reset, or updating your account information, our support team is here to help. Please contact us via email.
          Please provide us with the necessary details so that we can assist you promptly.
        </p>
        <p>
          3. Technical Support: For technical issues, such as website errors, broken links, or any other technical
          difficulties you may encounter while using our platform, our technical support team is ready to assist you.
          Please report these issues to our customer support team, including any relevant details, screenshots, or error
          messages you have encountered.
        </p>
        <p>
          4. Affiliate Program Inquiries: If you have questions about our affiliate program, commission structure, or
          payout processes, please reach out to us via email.
        </p>
        <p>
          5. Feedback and Suggestions: We value your feedback and suggestions as they help us improve our services. If
          you have any ideas, feature requests, or general feedback, please feel free to share them with us via email.
          We appreciate your input and strive to make 2feat.com a better platform for all our users.
        </p>
        <p>Best regards, The 2feat.com </p>
        <p>
          Customer Support Team - Email:{' '}
          <a href="mailto:support@2feat.com" rel="noopener noreferrer" className="support-title">
            support@2feat.com
          </a>
        </p>
      </div>
    </>
  );
};

export default Support;
