import Tabs from './Tabs';
import HomeProductList from './HomeProducts/HomeProductList';
import MediaContent from '../../components/MediaContent';

import '../../css/slick-theme.css';
import '../../css/index_utilities.css';
import '../../css/modal.css';
import '../../css/toast_notification.css';
import '../../css/style.css';

const Home = ({
  products,
  setSelectedTab,
  selectedTab,
  savedItems,
  setSavedItems,
  refs,
  savedSlides,
  savedSlidesSetter,
  pagesContent
}) => {
  return (
    <>
      <MediaContent pagesContent={pagesContent} />
      <Tabs
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
        refs={refs}
        savedSlides={savedSlides}
        savedSlidesSetter={savedSlidesSetter}
        savedItems={savedItems}
        setSavedItems={setSavedItems}
        products={products}
      />
      <HomeProductList
        products={products}
        refs={refs}
        savedSlidesSetter={savedSlidesSetter}
        savedItems={savedItems}
        setSavedItems={setSavedItems}
      />
    </>
  );
};

export default Home;
