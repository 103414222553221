import { useState, useRef } from 'react';

import useOutsideClick from '../../../hooks/useOutsideClick';
import useEscapeKey from '../../../hooks/useEscapeKey';

import ArrowIcon from '../../../styles/icons/ArrowIcon';

import './styles.css';

const DropDown = ({ ...props }) => {
  const [openDropDown, setOpenDropDown] = useState(false);

  const dropDownRef = useRef(null);

  const sendNo = () => setOpenDropDown(false);

  useEscapeKey(sendNo);
  useOutsideClick(sendNo, dropDownRef);

  return (
    <div className="custom-dropdown" ref={dropDownRef}>
      <div
        className="label"
        onClick={() => {
          if (props.children) {
            setOpenDropDown(!openDropDown);
          }

          if (props.onClick) {
            props.onClick();
          }
        }}
      >
        <span color="secondary" variant="b2" cursor="pointer">
          {props.label}
        </span>
        {props.children ? <ArrowIcon direction={openDropDown ? 'up' : 'down'} /> : null}
      </div>
      {openDropDown ? (
        <div className={props.lastChild ? 'dropdown-children last' : 'dropdown-children'}>{props.children}</div>
      ) : null}
    </div>
  );
};

export default DropDown;
