import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import ProductPreview from '../../ProductPreview';
import Filters from '../../Filters';

import useMobile from '../../../hooks/useMobile';
import useDisableBodyScroll from '../../../hooks/useDisableBodyScroll';
import useEscapeKey from '../../../hooks/useEscapeKey';
import useOutsideClick from '../../../hooks/useOutsideClick';

import './styles.css';

const SearchModal = ({ products, filters, savedSlidesSetter, refs, freestyle }) => {
  const navigate = useNavigate();
  const isMobile = useMobile();

  const [show, setShow] = useState(false);

  const [productSearch, setProductSearch] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(products);

  const modalSearchRef = useRef(null);
  const inputRef = useRef(null);

  const productsPreviewNumber = 12;

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  });

  const sendNo = () => {
    setProductSearch('');
    setShow(false);
  };

  useEscapeKey(sendNo);
  useOutsideClick(sendNo, modalSearchRef);
  useDisableBodyScroll(show);

  useEffect(() => {
    setFilteredProducts([...products.sort(() => 0.5 - Math.random()).slice(0, productsPreviewNumber)]);

    const searchProduct = products.filter((product) => {
      if (productSearch && product.productName.toLowerCase().includes(productSearch.toLowerCase())) {
        return true;
      } else if (productSearch && product.tags.find((el) => el.includes(productSearch.toLowerCase()))) return true;
      else if (productSearch && product.description?.includes(productSearch.toLowerCase())) return true;
      else if (productSearch && product.shortDesc.includes(productSearch.toLowerCase())) return true;
      else if (productSearch && product.brand.toLowerCase().includes(productSearch.toLowerCase())) return true;
      else if (productSearch && product.productFor.toLowerCase().includes(productSearch.toLowerCase())) return true;
      else if (productSearch && product.category.toLowerCase().includes(productSearch.toLowerCase())) return true;
    }); //need to make an export function form this search

    if (productSearch) {
      setSearchResult(searchProduct);
      setFilteredProducts(() => searchProduct.slice(0, productsPreviewNumber));
    }
  }, [productSearch, products]);

  function handleProductSearch(e) {
    e.preventDefault();
    setProductSearch(e.target.value);
  }

  const moreResults = () => {
    navigate(`/search/${productSearch}`);
    setShow(false);
  };

  const navigateTo = (path) => {
    navigate(path);
    setShow(false);
  };

  return (
    <>
      <div className={freestyle ? 'modal-search-icon' : 'search uppercase'} onClick={() => setShow(true)}>
        <span className="lnr lnr-magnifier"></span> {freestyle ? <span>SEARCH</span> : null}
      </div>
      {show ? (
        <section className="search-section">
          <div ref={modalSearchRef} className="search-container">
            <div className="search-form-container">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  navigate(`/search/${productSearch}`);
                  setShow(false);
                }}
              >
                <input
                  className="modal-search-input"
                  type="search"
                  name="search_products"
                  id="search_products"
                  placeholder="search products..."
                  ref={inputRef}
                  onChange={(e) => handleProductSearch(e)}
                />
              </form>
              {searchResult.length > productsPreviewNumber && productSearch.length > 0 ? (
                <div className="more-products" onClick={moreResults}>
                  More products...
                </div>
              ) : null}
              <div className="filter-and-close-container">
                <div>
                  <Filters products={products} unionData={filteredProducts} setUnionData={setFilteredProducts} isTab />
                </div>
                <div className="close-modal" onClick={() => setShow(false)}>
                  <span className="lnr lnr-cross" id="close-btn"></span>
                </div>
              </div>
            </div>
            {freestyle ? <div className="preview-title">Tap product to freestyle it</div> : null}
            {products.length > 0 ? (
              <>
                <div className="propuct-preview-container">
                  <div className={freestyle ? 'propuct-preview-freestyle' : 'propuct-preview'}>
                    <div className="product-preview-title">
                      <span>Popular products</span>
                    </div>
                    <div className="propuct-preview-results">
                      {filteredProducts.length
                        ? filteredProducts?.map((product) => {
                            return (
                              <ProductPreview
                                key={'preview' + product._id}
                                product={product}
                                setShow={setShow}
                                searchPreview
                                savedSlidesSetter={savedSlidesSetter}
                                refs={refs}
                                freestyle={freestyle}
                              />
                            );
                          })
                        : 'NO PRODUCTS FOUND'}
                    </div>
                  </div>
                  {filters && !isMobile ? (
                    <div className="categories-container">
                      <div className="category sneaker-brands">
                        <div className="categories-title link" onClick={() => navigateTo('/search/sneaker')}>
                          <span>Sneaker brands</span>
                        </div>
                        {filters.brand.length
                          ? filters.brand.slice(0, 14).map((brand) => {
                              return (
                                <div
                                  key={'nav' + brand}
                                  className="link"
                                  onClick={() => navigateTo(`/search/${brand}`)}
                                >
                                  {brand}
                                </div>
                              );
                            })
                          : null}
                        {/* {filters.brand.length > 15 ? (
                          <div className="link" onClick={() => navigateTo('brand')}>
                            More brands
                          </div>
                        ) : null} */}
                      </div>
                      <div className="category apparel">
                        <div className="categories-title link" onClick={() => navigateTo('/apparel')}>
                          <span>Apparel</span>
                        </div>
                        <div className="link" onClick={() => navigateTo('/search/men')}>
                          Mens
                        </div>
                        <div className="link" onClick={() => navigateTo('/search/women')}>
                          Womens
                        </div>
                        <div className="link" onClick={() => navigateTo('/search/t-shirt')}>
                          T-shirts
                        </div>
                        <div className="link" onClick={() => navigateTo('/search/pant')}>
                          Pants
                        </div>
                        <div className="link" onClick={() => navigateTo('/search/cap')}>
                          Caps
                        </div>
                        <div className="link" onClick={() => navigateTo('/search/accessories')}>
                          Accessories
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default SearchModal;
