import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
const ProductTopics = ({ getMessage }) => {
  getMessage('Product section');
  const { toggler } = useOutletContext();
  const [productTopics, setProductTopics] = useState('');
  const [allProductTopics, setAllProductTopics] = useState([]);
  const [edit, setEditing] = useState(-1);

  useEffect(() => {
    axios.get('/filter/productTopics').then((res) => setAllProductTopics(res.data.result));
  }, []);

  function handleProductTopics(e) {
    e.preventDefault();
    setProductTopics(e.target.value);
  }

  function handleEdit(index) {
    console.log(index);
    setEditing(index);
    setProductTopics(allProductTopics[index]);
  }

  async function handleDelete(index) {
    const temp = allProductTopics;
    temp.splice(index, 1);
    setAllProductTopics([...temp]);
    await axios
      .put(
        '/filter/deleteFilter',
        { filter: temp, key: 'productTopics' },
        {
          headers: {
            Authorization: localStorage.getItem('accessToken')
          }
        }
      )
      .catch((error) => {
        if (error.response.data.msg == 'Invalid token') {
          axios
            .get('/refresh', {
              headers: {
                Refresh: localStorage.getItem('refreshToken')
              }
            })
            .then((res) => {
              localStorage.setItem('accessToken', res.data.accessToken);
              axios.put(
                '/filter/deleteFilter',
                { filter: temp, key: 'productTopics' },
                {
                  headers: {
                    Authorization: localStorage.getItem('accessToken')
                  }
                }
              );
            })
            .catch((err) => (window.location.href = '/'));
        }
      });
  }

  async function addProductTopics(e) {
    e.preventDefault();
    let a;
    let temp = allProductTopics;
    if (edit !== -1) {
      a = await axios
        .put(
          '/filter/updateFilter',
          {
            filter: { key: 'productTopics', index: edit, value: productTopics }
          },
          {
            headers: {
              Authorization: localStorage.getItem('accessToken')
            }
          }
        )
        .catch((error) => {
          if (error.response.data.msg == 'Invalid token') {
            axios
              .get('/refresh', {
                headers: {
                  Refresh: localStorage.getItem('refreshToken')
                }
              })
              .then((res) => {
                localStorage.setItem('accessToken', res.data.accessToken);
                axios.put(
                  '/filter/updateFilter',
                  {
                    filter: { key: 'productTopics', index: edit, value: productTopics }
                  },
                  {
                    headers: {
                      Authorization: localStorage.getItem('accessToken')
                    }
                  }
                );
              })
              .catch((err) => (window.location.href = '/'));
          }
        });
      temp[edit] = productTopics;
      setProductTopics('');
      setEditing(-1);
    } else {
      a = await axios
        .post(
          '/filter/addFilter',
          {
            filter: { key: 'productTopics', value: productTopics }
          },
          {
            headers: {
              Authorization: localStorage.getItem('accessToken')
            }
          }
        )
        .catch((error) => {
          if (error.response.data.msg == 'Invalid token') {
            axios
              .get('/refresh', {
                headers: {
                  Refresh: localStorage.getItem('refreshToken')
                }
              })
              .then((res) => {
                localStorage.setItem('accessToken', res.data.accessToken);
                axios.post(
                  '/filter/addFilter',
                  {
                    filter: { key: 'productTopics', value: productTopics }
                  },
                  {
                    headers: {
                      Authorization: localStorage.getItem('accessToken')
                    }
                  }
                );
              })
              .catch((err) => (window.location.href = '/'));
          }
        });
      temp.push(productTopics);
    }
    setAllProductTopics([...temp]);
    console.log(a);
  }

  return (
    <>
      <section id="brand-" class={toggler ? 'left-margin-container' : 'left-margin-container margin-remove'}>
        <div class="container add-brand-container">
          <h1>Add Product Topics</h1>
          <form onSubmit={addProductTopics} class="add-brand-form">
            <div class="form-group">
              <label for="add_productTopics">Product Topic</label>
              <input
                value={productTopics}
                onChange={handleProductTopics}
                type="text"
                name="productTopics_name"
                id="add_brand"
                placeholder="topics name"
              />
            </div>
            <button type="submit" class="save-button" name="save">
              <span class="lnr lnr-checkmark-circle"></span> {edit !== -1 ? 'Edit' : 'Add'} Topic
            </button>
          </form>
          {/* <!-- div: Topic listings --> */}
          <div class="listing-table-wrapper">
            <table class="listings-table">
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Product Topics</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {allProductTopics.map((productTopic, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{productTopic}</td>
                      <td class="options">
                        <a href="#" onClick={() => handleEdit(index)} class="edit">
                          <div class="lnr lnr-pencil"></div>
                        </a>
                        <a href="#" onClick={() => handleDelete(index)} class="delete">
                          <div class="lnr lnr-trash"></div>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductTopics;
