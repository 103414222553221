import axios from 'axios';

export async function imgUpload(base64) {
  const result = await axios
    .post(
      '/image/upload',
      { img: base64 },
      {
        headers: {
          Authorization: localStorage.getItem('accessToken')
        }
      }
    )
    .catch((error) => {
      console.log('Something went wrong while uploading an image', error);
    });

  return result.data.result;
}

export async function imgDelete(img, type) {
  const result = await axios.post(
    '/image/delete',
    { img: img, type: type },
    {
      headers: {
        Authorization: localStorage.getItem('accessToken')
      }
    }
  );

  return result.data.msg;
}
