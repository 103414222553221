import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/reset_password.css"

const ResetPassword = () => {
  const { id } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [errMsg, setErrMsg] = useState("");

  function handlePassword(e) {
    e.preventDefault();
    setPassword(e.target.value);
  }

  function handleConfirmPass(e) {
    e.preventDefault();
    setConfirmPass(e.target.value);
  }

  function validate() {
    const passwordRegex =
      /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

    if (password != confirmPass) {
      setErrMsg("Password doesn't match!");
      return false;
    } else if (!passwordRegex.test(password)) {
      setErrMsg(
        "Password must be consists of at least one uppercase, lowercase, numeric and symbol"
      );
      return false;
    }
    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setErrMsg("");
    const isValid = validate();
    if (isValid) {
      await axios
        .post("/reset/resetPass", { accessToken: id, password: password })
        .then((res)=> alert(res.data.msg))
        .catch((err) => {
          if (err.response.data.msg == "Token expired") {
            setErrMsg("Token expired or Invalid");
          } else {
            console.log(err.response);
          }
        });
    }
  }

  return (
    <>
      <div id="reset-password">
        <div class="container reset-password-container">
          <div class="left-img-container">
            <div class="text-container">
              <p class="user-quote">
                "It was an amazing moment finding products with clear
                descriptions and with easy purchase options."
              </p>
              <span class="username">Emily</span>
            </div>
          </div>
          <div class="right-text-container">
            <h1 class="top-title">
              easy<span class="blue-text">Reset</span>
            </h1>
            <div class="center-texts">
              <b class="title-text">Reset password</b>
              <p class="info-text">
                Just type it twice and try not to forget it. Password should be
                and must contain:
              </p>
              <div class="instructions">
                <div class="character-validation">
                  <b>8+</b>
                  <span>Character</span>
                </div>
                <div class="uppercase-validation">
                  <b>AA</b>
                  <span>Uppercase</span>
                </div>
                <div class="lowercase-validation">
                  <b>aa</b>
                  <span>Lowercase</span>
                </div>
                <div class="number-validation">
                  <b>123</b>
                  <span>Number</span>
                </div>
                <div class="symbol-validation">
                  <b>@$#</b>
                  <span>Symbol</span>
                </div>
              </div>
              <form onSubmit={handleSubmit} class="reset-form">
                {errMsg != "" && <p style={{ color: "red", fontSize: 12 }}>{errMsg}</p>}
                <div class="form-group">
                  <label for="new_password">New Password</label>
                  <input
                    value={password}
                    onChange={handlePassword}
                    type="password"
                    name="new_password"
                    id="new_password"
                    placeholder="New password"
                  />
                </div>
                <div class="form-group">
                  <label for="confirm_password">Confirm Password</label>
                  <input
                    value={confirmPass}
                    onChange={handleConfirmPass}
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    placeholder="Confirm your password"
                  />
                </div>
                <button type="submit" class="reset-btn">
                  Reset Password
                </button>
                <Link to={"/login"} class="login-link">
                  Back To Login
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
