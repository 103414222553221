import { useEffect, useState, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';

import axios from 'axios';

import EnlargedImage from '../../components/Modals/EnlargedImage';

import useEscapeKey from '../../hooks/useEscapeKey';
import useOutsideClick from '../../hooks/useOutsideClick';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const StylesImages = ({ getMessage, products }) => {
  getMessage('Styles Section');

  const searchDropdownRef = useRef(null);
  const searchRef = useRef(null);

  const { toggler } = useOutletContext();

  const [styleName, setProductName] = useState('');
  const [author, setAuthor] = useState('');
  const [mainImage, setMainImage] = useState();
  const [description, setDesc] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [tags, setTags] = useState(['']);
  const [style, setStyle] = useState('');
  const [productFor, setProductFor] = useState('');
  const [allStyle, setAllStyle] = useState([]);
  const [allProductFor, setAllProductFor] = useState([]);
  const [productSearch, setProductSearch] = useState('');
  const [productSearchResult, setProductsSearchResult] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedProductsId, setRelatedProductsId] = useState([]);

  const [enlargedImage, setEnlargeImage] = useState('');
  const [showEnlImage, setShowEnlImage] = useState(false);

  const sendNo = () => {
    setProductsSearchResult(false);
    setProductSearch('');
  };

  useEscapeKey(sendNo);
  useOutsideClick(sendNo, searchDropdownRef);

  useEffect(() => {
    axios.get('/filter/all').then((res) => {
      const result = { ...res.data.filters };

      setAllStyle(result.styles);
      setStyle(result.styles[0]);

      setAllProductFor(result.productFor);
      setProductFor(result.productFor[0]);
    });
  }, []);

  useEffect(() => {
    const searchProduct = products.filter((product) => {
      if (productSearch && product.productName.toLowerCase().includes(productSearch.toLowerCase())) {
        return true;
      } else if (productSearch && product.tags.find((el) => el.includes(productSearch.toLowerCase()))) return true;
      else if (productSearch && product.description?.includes(productSearch.toLowerCase())) return true;
      else if (productSearch && product.shortDesc.includes(productSearch.toLowerCase())) return true;
    });

    setTimeout(() => {
      setProductsSearchResult(() => searchProduct);
    }, 1000);
  }, [productSearch, products]);

  const addRelatedProduct = (product) => {
    if (!relatedProductsId.includes(product._id) || !relatedProductsId.length) {
      setRelatedProductsId([...relatedProductsId, product._id]);
      setRelatedProducts([...relatedProducts, product]);
    }
    searchRef.current.focus();
  };

  const deleteChosenProduct = (id) => {
    setRelatedProducts([...relatedProducts.filter((product) => product._id !== id)]);
    setRelatedProductsId([...relatedProductsId.filter((productId) => productId !== id)]);
  };

  function handleProductName(e) {
    e.preventDefault();
    setProductName(e.target.value);
  }

  function handleAuthor(e) {
    e.preventDefault();
    setAuthor(e.target.value);
  }

  function handleShortDesc(e) {
    e.preventDefault();
    setShortDesc(e.target.value);
  }

  function handleProductSearch(e) {
    e.preventDefault();
    setProductSearch(e.target.value);
  }

  function handleTags(e) {
    e.preventDefault();
    setTags(e.target.value);
  }

  function handleStyle(e) {
    e.preventDefault();
    setStyle(e.target.value);
  }

  function handleProductFor(e) {
    e.preventDefault();
    setProductFor(e.target.value);
  }

  function handleMainImage(e) {
    e.preventDefault();
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setMainImage(reader.result);
    };
  }

  async function addProduct(e) {
    e.preventDefault();

    let t = tags.split(',');
    t = t.map((item) => {
      return item.toLowerCase();
    });

    axios
      .post(
        '/image/upload',
        { img: mainImage },
        {
          headers: {
            Authorization: localStorage.getItem('accessToken')
          }
        }
      )
      .then((result) => {
        const mImg = result.data.result;

        axios
          .post(
            '/styles/add',
            {
              mainImage: mImg,
              styleName,
              author,
              description,
              shortDesc,
              tags: t,
              style,
              productFor,
              relatedProducts: relatedProductsId
            },
            {
              headers: {
                Authorization: localStorage.getItem('accessToken')
              }
            }
          )
          .then((res) => {
            const result = res.data;

            console.log('result', result);
            /*to add notification*/
          })
          .catch((error) => {
            if (error.response.data.msg == 'Invalid token') {
              axios
                .get('/refresh', {
                  headers: {
                    Refresh: localStorage.getItem('refreshToken')
                  }
                })
                .then((res) => {
                  localStorage.setItem('accessToken', res.data.accessToken);
                  axios.post(
                    '/styles/add',
                    {
                      mainImage: mImg,
                      styleName,
                      author,
                      description,
                      shortDesc,
                      tags: t,
                      style,
                      productFor,
                      relatedProducts: relatedProductsId
                    },
                    {
                      headers: {
                        Authorization: localStorage.getItem('accessToken')
                      }
                    }
                  );
                })
                .catch((err) => (window.location.href = '/'));
            }
          });

        window.location.href = '/admin/styles/all';
        /*to add notification instead of navigation*/
      })
      .catch((error) => {
        console.log('error', error);
        /*to add notification*/
      });
  }

  return (
    <>
      <section id="add-product-form" class={toggler ? 'left-margin-container' : 'left-margin-container margin-remove'}>
        <div class="container add-product-form-container">
          <form onSubmit={addProduct} class="add-product-form">
            <div class="product-container">
              <div class="top-text-container add-product-header">
                <h1>
                  <span>Add style image</span>
                </h1>
                <p>Add style image to the styles page on the website!</p>
              </div>
              <div class="line-bar"></div>
              <div class="product-container-content">
                <div class="product-and-author">
                  <div class="form-group">
                    <label for="product_name">Style image name</label>
                    <input
                      type="text"
                      name="style_name"
                      id="style_name"
                      placeholder="Style image name"
                      value={styleName}
                      onChange={handleProductName}
                    />
                  </div>
                  <div class="form-group">
                    <label for="author">Author</label>
                    <input
                      type="text"
                      name="author"
                      id="author"
                      placeholder="Author"
                      value={author}
                      onChange={handleAuthor}
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="product_image">Product Image</label>
                  <input onChange={handleMainImage} type="file" name="product_image" id="product_image" />
                </div>
                <div class="price-and-color">
                  <div class="form-group">
                    <label for="shortDesc">Short Description</label>
                    <input
                      value={shortDesc}
                      onChange={handleShortDesc}
                      type="text"
                      name="shortDesc"
                      id="color"
                      placeholder="Short description"
                    />
                  </div>
                  <div class="form-group search-related-products">
                    <label for="related-products">Related products</label>
                    <input
                      type="search"
                      name="related-products"
                      id="related-products"
                      placeholder="Search for products"
                      value={productSearch}
                      onChange={handleProductSearch}
                      ref={searchRef}
                    />
                    {productSearchResult.length > 0 ? (
                      <div ref={searchDropdownRef} className="search-products-dropdown">
                        {productSearchResult.map((product) => {
                          return (
                            <div className="search-product-item" onClick={() => addRelatedProduct(product)}>
                              {product.productName}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group related-products-result">
                    <div className="chosen-products-label">Chosen products</div>
                    {relatedProducts.length ? (
                      <div className="chosen-related-products">
                        {relatedProducts.map((product) => {
                          return (
                            <div className="chosen-related-product">
                              <div
                                className="chosen-related-product-name"
                                onClick={() => {
                                  setEnlargeImage(product.mainImage.url);
                                  setShowEnlImage(true);
                                }}
                              >
                                {product.productName}
                              </div>
                              <div class="lnr lnr-trash" onClick={() => deleteChosenProduct(product._id)}></div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <span>No chosen products yet</span>
                    )}
                  </div>
                </div>
                <div class="form-group">
                  <label for="description">Description</label>
                  <div style={{ marginRight: '10em' }}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDesc(data);
                      }}
                    />
                  </div>
                </div>
                <div class="filter-and-stock">
                  <div class="form-group">
                    <label for="product_for">Product for</label>
                    <select value={productFor} onChange={handleProductFor} name="product_for" id="product_for">
                      {allProductFor.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div class="tags-and-styles">
                  <div class="form-group">
                    <label for="tags">Tags</label>
                    <input value={tags} onChange={handleTags} type="text" name="tags" id="tags" placeholder="tags" />
                    <span class="tag-sample">example: nike,branded shoes</span>
                  </div>
                  <div class="form-group">
                    <label for="styles">Style</label>
                    <select value={style} onChange={handleStyle} name="style">
                      {allStyle.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="images-and-save">
              <div class="submit">
                <button type="submit" class="publish-button" name="publish_live">
                  <span class="lnr lnr-pencil"></span> Save
                </button>
              </div>
            </div>
          </form>
        </div>
        <EnlargedImage showEnlImage={showEnlImage} setShowEnlImage={setShowEnlImage} enlargedImage={enlargedImage} />
      </section>
    </>
  );
};

export default StylesImages;
