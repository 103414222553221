import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import { imgDelete, imgUpload } from '../utils/img';

const AdminAbout = ({ getMessage }) => {
  getMessage('About section');
  const { toggler } = useOutletContext();

  const [heading, setHeading] = useState('');
  const [mainImage, setMainImg] = useState('');
  const [shortDescription, setHDesc] = useState('');
  const [story, setStory] = useState([{ title: '', subtitle: '' }]);
  const [mission, setMission] = useState({ text: '', img: '' });
  const [vision, setVision] = useState({ text: '', img: '' });
  const [about, setAbout] = useState({});

  useEffect(() => {
    axios.get('/about/getAbout').then((res) => {
      if (res.data.about) {
        const ab = res.data.about;
        setAbout(ab);
        setHeading(res.data.about.heading);
        setHDesc(res.data.about.shortDescription);
        setStory(res.data.about.story);
        // let a = { text: ab.mission.text, img: "" };
        let a = { ...ab.mission };
        a.img = '';
        setMission(a);
        a = { ...ab.vision };
        a.img = '';
        setVision(a);
      }
    });
  }, []);

  function handleMainImg(e) {
    e.preventDefault();
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setMainImg(reader.result);
    };
  }

  function handleHeading(e) {
    e.preventDefault();
    setHeading(e.target.value);
  }

  function handleHDesc(e) {
    e.preventDefault();
    if (e.target.value.length <= 50) setHDesc(e.target.value);
  }

  function handleMission(e) {
    e.preventDefault();
    let m = mission;
    m.text = e.target.value;
    setMission({ ...m });
  }

  function handleMissionImg(e) {
    e.preventDefault();
    let m = mission;
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      m.img = reader.result;
      setMission({ ...m });
    };
  }

  function handleVision(e) {
    e.preventDefault();
    let v = vision;
    v.text = e.target.value;
    setVision({ ...v });
  }

  function handleVisionImg(e) {
    e.preventDefault();
    let v = vision;
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      v.img = reader.result;
      setVision({ ...v });
    };
  }

  function addStory(e) {
    e.preventDefault();
    if (story.length < 4) {
      const t = { title: '', subtitle: '' };
      setStory([...story, t]);
    }
  }

  const StoryField = ({ item, index, setStory }) => {
    const [title, setTitle] = useState(item.title);
    const [subtitle, setSubTitle] = useState(item.subtitle);

    function handleTitle(e) {
      e.preventDefault();
      let a = story;
      a[index].title = e.target.value;
      setTitle(e.target.value);
      setStory(a);
    }

    function handleSubTitle(e) {
      e.preventDefault();
      let a = story;
      a[index].subtitle = e.target.value;
      setSubTitle(e.target.value);
      setStory(a);
    }

    return (
      <>
        {index > 0 ? <hr /> : ''}
        <div class="dotted-bottom">
          <div class="form-group">
            <label>(Each) Story title</label>
            <input value={title} onChange={handleTitle} type="text" name="each_story_title" class="each_story_title" />
          </div>
          <div class="form-group">
            <label>(Each) Story subtitle</label>
            <input
              value={subtitle}
              onChange={handleSubTitle}
              type="text"
              name="each_story_subtitle"
              class="each_story_subtitle"
            />
          </div>
        </div>
      </>
    );
  };

  async function addAbout(e) {
    e.preventDefault();
    let route = '/about/add';
    let m = { ...mission },
      v = { ...vision };
    if (mission.img !== '') {
      about.mission && (await imgDelete(about.mission.img, 'single'));
      m.img = await imgUpload(mission.img);
    }
    if (vision.img !== '') {
      about.vision && (await imgDelete(about.vision.img, 'single'));
      v.img = await imgUpload(vision.img);
    }
    if (about.heading) {
      route = '/about/edit';
    }

    let tempImg;

    if (mainImage == '' && about.mainImage) {
      setMainImg(about.mainImage);
    } else {
      about.mainImage && (await imgDelete(about.mainImage, 'single'));
      tempImg = await imgUpload(mainImage);
    }

    const result = await axios
      .post(
        route,
        {
          heading,
          mainImage: tempImg,
          shortDescription,
          story,
          mission: m,
          vision: v
        },
        {
          headers: {
            Authorization: localStorage.getItem('accessToken')
          }
        }
      )
      .catch((error) => {
        if (error.response.data.msg == 'Invalid token') {
          axios
            .get('/refresh', {
              headers: {
                Refresh: localStorage.getItem('refreshToken')
              }
            })
            .then((res) => {
              localStorage.setItem('accessToken', res.data.accessToken);
              axios.post(
                route,
                {
                  heading,
                  shortDescription,
                  story,
                  mission: m,
                  vision: v
                },
                {
                  headers: {
                    Authorization: localStorage.getItem('accessToken')
                  }
                }
              );
            })
            .catch((err) => (window.location.href = '/'));
        }
      });

    const temp = about;
    temp.mission = m;
    temp.vision = v;
    setAbout(temp);
  }

  return (
    <>
      <section id="about-section" class={toggler ? 'left-margin-container' : 'left-margin-container margin-remove'}>
        <div class="container about-section-container">
          <h1>About</h1>
          <form onSubmit={addAbout} class="about-section-form">
            <div class="top-stories">
              {/* <!-- story: left-section --> */}
              <div class="left-story-section">
                <div class="form-group">
                  <label for="our-story-heading">Our story heading text</label>
                  <input
                    value={heading}
                    onChange={handleHeading}
                    type="text"
                    name="our_story_heading"
                    id="our-story-heading"
                  />
                </div>
                <div class="form-group">
                  <label for="short_description">Short description</label>
                  <input
                    value={shortDescription}
                    onChange={handleHDesc}
                    type="text"
                    name="short_description"
                    id="short_description"
                  />
                  <span class="text-sm">Max. 50 words</span>
                </div>
                <div class="form-group">
                  <label for="our-mission-image">About Main-image</label>
                  <input onChange={handleMainImg} type="file" name="our_mission_file" />
                </div>
              </div>
              <div class="left-story-section">
                <div class="form-section">
                  {story.map((item, index) => {
                    return <StoryField item={item} index={index} setStory={setStory} />;
                  })}
                </div>
                <div class="add-more-story">
                  <i onClick={addStory} class="fa fa-plus add-btn"></i>
                </div>
              </div>
            </div>
            <div class="our-mission">
              <div class="form-group">
                <label for="our-mission-text">Our mission text</label>
                <textarea
                  value={mission.text}
                  onChange={handleMission}
                  name="our_mission"
                  id="our-mission-text"
                ></textarea>
              </div>
              <div class="form-group">
                <label for="our-mission-image">Our mission image</label>
                <input onChange={handleMissionImg} type="file" name="our_mission_file" />
              </div>
            </div>
            {/* <!-- our vision --> */}
            <div class="our-vision">
              <div class="form-group">
                <label for="our-vision-text">Our vision text</label>
                <textarea
                  value={vision.text}
                  onChange={handleVision}
                  name="our_vision_text"
                  id="our-vision-text"
                ></textarea>
              </div>
              <div class="form-group">
                <label for="our-vision-image">Our vision image</label>
                <input onChange={handleVisionImg} type="file" name="our_vision_text" id="our-vision-image" />
              </div>
            </div>
            <button type="submit" class="save-button" name="save">
              <span class="lnr lnr-checkmark-circle"></span> Save
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default AdminAbout;
