const SoundsOn = () => {
  return (
    <svg xmlns="http=//www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 256 256">
      <defs></defs>
      <g
        stroke="none"
        strokeWidth={0}
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill="none"
        fillRule="nonzero"
        opacity={1}
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
      >
        <path
          d="M 52.587 73.49 c -0.493 0 -0.98 -0.182 -1.357 -0.531 L 34.848 57.836 H 17 c -1.104 0 -2 -0.895 -2 -2 V 34.164 c 0 -1.104 0.896 -2 2 -2 h 17.848 l 16.381 -15.123 c 0.584 -0.539 1.431 -0.682 2.159 -0.363 c 0.728 0.318 1.198 1.038 1.198 1.832 V 71.49 c 0 0.794 -0.47 1.514 -1.198 1.832 C 53.13 73.435 52.857 73.49 52.587 73.49 z"
          stroke="none"
          strokeWidth={1}
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          fill="#ffffff"
          fillRule="nonzero"
          opacity={1}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z M 45 4 C 22.393 4 4 22.393 4 45 s 18.393 41 41 41 s 41 -18.393 41 -41 S 67.607 4 45 4 z"
          stroke="none"
          strokeWidth={1}
          strokeDasharray="none"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          fill="#ffffff"
          fillRule="nonzero"
          opacity={1}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default SoundsOn;
