import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import OtpInput from 'react-otp-input';

import '../css/slick.css';
import '../css/slick-theme.css';
import '../css/style.css';
import '../css/sneaker_blogpost.css';
import '../css/register.css';

const Register = () => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    tiktokLink: '',
    instagramLink: '',
    facebookLink: '',
    receiveEmail: false,
    agreeTerm: false,
    isDisabled: 0
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [errMsg, setErrMsg] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [contd, setContinue] = useState(false);
  const [code, setCode] = useState('');
  const [cooldown, setCooldown] = useState(0);

  let interval;

  const handleOtp = (code) => setCode(code);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCheckbox = (e) => {
    const { name } = e.target;
    setFormValues({ ...formValues, [name]: !formValues[`${name}`] });
  };

  async function handleContinue(e) {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    try {
      const response = await axios.post('/user/register', formValues);

      await axios.post('/user/verification', { email: formValues.email });
      setContinue(true);

      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Email Taken');
      } else {
        setErrMsg('Registration Failed');
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post('/user/verify', {
        verificationCode: parseInt(code),
        email: formValues.email
      });

      window.location.href = '/';
    } catch (error) {
      setErrMsg('Verification failed!');
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  useEffect(() => {
    setErrMsg('');
  }, []);

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const passwordRegex = /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    const phoneRegex = /^(\+(([0-9]){1,2})[-.])?((((([0-9]){2,3})[-.]){1,2}([0-9]{4,10}))|([0-9]{10}))$/;
    // This Regex Expression works for India, Canada, Europe, New Zealand, Australia,
    // United States phone numbers, along with their country codes:
    if (!values.firstName) {
      errors.firstName = 'Firstname is required!';
    }

    if (!values.lastName) {
      errors.lastName = 'Lastname is required!';
    }

    if (!values.phone) {
      errors.phone = 'Phone number is required';
    } else if (!phoneRegex.test(values.phone)) {
      errors.phone = 'This is not a valid phone format!';
    }

    if (!values.email) {
      errors.email = 'Email is required!';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'This is not a valid email format!';
    }

    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values.password.length < 8) {
      errors.password = 'password length must be greater than or equal to 8';
    } else if (!passwordRegex.test(values.password)) {
      errors.password = 'Password must be consists of at least one uppercase, lowercase, numeric and symbol';
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password doesn't match";
    }

    return errors;
  };

  return (
    <>
      <div id="register">
        <div className="container register-container">
          <div className="img-container">{/* <!-- <img src="images/trek_register.jpg" alt=""> --> */}</div>
          <div className="text-container">
            <div className="header-text">Register</div>
            <div className="description-text">
              <h2>Connect into our platform!</h2>
              <p className="register-text">
                Let's get registered into our platform for exciting offers and deals notices.
              </p>
            </div>
            <p className="register-text" style={{ color: 'red' }}>
              {errMsg ? '*' + errMsg : ''}
            </p>
            <form className="register-form" onSubmit={handleSubmit}>
              {!contd && (
                <>
                  <div className="usernames">
                    <div className="form-group">
                      <label className="label" for="first_name">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        id="first_name"
                        value={formValues.firstName}
                        onChange={handleChange}
                      />
                      <p style={{ color: 'red' }}>{formErrors.firstName}</p>
                    </div>
                    <div className="form-group">
                      <label for="last_name">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        id="last_name"
                        value={formValues.lastName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="contact-info">
                    <div className="form-group">
                      <label for="phone_number">Phone Number</label>
                      <input
                        type="tel"
                        name="phone"
                        id="phone_number"
                        value={formValues.phone}
                        onChange={handleChange}
                      />
                      <p style={{ color: 'red' }}>{formErrors.phone}</p>
                    </div>
                    <div className="form-group">
                      <label for="email">Email</label>
                      <input type="email" name="email" id="email" value={formValues.email} onChange={handleChange} />
                      <p style={{ color: 'red' }}>{formErrors.email}</p>
                    </div>
                  </div>
                  <div className="contact-info">
                    <div className="form-group">
                      <label for="facebookLink">Facebook</label>
                      <input
                        type="text"
                        name="facebookLink"
                        id="facebook"
                        value={formValues.facebookLink}
                        onChange={handleChange}
                      />
                      <p style={{ color: 'red' }}>{formErrors.phone}</p>
                    </div>
                    <div className="form-group">
                      <label for="instagramLink">Instagram</label>
                      <input
                        type="text"
                        name="instagramLink"
                        id="instagram"
                        value={formValues.instagramLink}
                        onChange={handleChange}
                      />
                      <p style={{ color: 'red' }}>{formErrors.email}</p>
                    </div>
                    <div className="form-group">
                      <label for="tiktokLink">Tik-Tok</label>
                      <input
                        type="text"
                        name="tiktokLink"
                        id="tiktok"
                        value={formValues.tiktokLink}
                        onChange={handleChange}
                      />
                      <p style={{ color: 'red' }}>{formErrors.email}</p>
                    </div>
                  </div>
                  <div className="password-group">
                    <div className="form-group">
                      <label for="password">Password</label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        value={formValues.password}
                        onChange={handleChange}
                      />
                      <p style={{ color: 'red' }}>{formErrors.password}</p>
                    </div>
                    <div className="form-group">
                      <label for="confirm_password">Confirm Password</label>
                      <input
                        type="password"
                        name="confirmPassword"
                        id="confirm_password"
                        value={formValues.confirmPassword}
                        onChange={handleChange}
                      />
                      <p style={{ color: 'red' }}>{formErrors.confirmPassword}</p>
                    </div>
                  </div>
                  <div className="email-sub-container">
                    <input
                      type="checkbox"
                      name="receiveEmail"
                      id="email-sub"
                      value={formValues.receiveEmail}
                      onChange={handleCheckbox}
                      checked={formValues.receiveEmail}
                    />
                    <span> Yes, I want to receive emails</span>
                  </div>
                  <div className="email-sub-container">
                    <input
                      type="checkbox"
                      name="agreeTerm"
                      id="email-sub"
                      value={formValues.agreeTerm}
                      onChange={handleCheckbox}
                      checked={formValues.agreeTerm}
                    />
                    <span>
                      {' '}
                      I agree all the{' '}
                      <a href="/terms" className="terms">
                        Term
                      </a>
                      ,{' '}
                      <a href="/privacyPolicy" className="privacy-policy">
                        Privacy Policy
                      </a>
                      , and{' '}
                      <a href="#" className="fees">
                        Fees
                      </a>
                    </span>
                  </div>
                  <div className="crete-account-container">
                    <input
                      style={{
                        textAlign: 'center',
                        border: 'none',
                        cursor: 'pointer'
                      }}
                      onClick={handleContinue}
                      value="Continue"
                      disabled={formValues.isDisabled}
                      className="create-account-btn"
                    />
                  </div>{' '}
                </>
              )}
              {contd && (
                <>
                  <div onClick={() => setContinue(false)} style={{ fontSize: '1.5 rem', cursor: 'pointer' }}>
                    <i className="lnr lnr-chevron-left" style={{ fontSize: '1.5 rem' }} /> BACK
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'right',
                      flexDirection: 'column'
                    }}
                  >
                    <OtpInput
                      value={code}
                      onChange={handleOtp}
                      numInputs={6}
                      separator={<span style={{ width: '8px' }}></span>}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      inputStyle={{
                        border: '1px solid black transparent',
                        borderRadius: '8px',
                        width: '54px',
                        height: '54px',
                        fontSize: '16px',
                        color: '#000',
                        fontWeight: '400',
                        caretColor: 'blue'
                      }}
                      focusStyle={{
                        border: '1px solid #CFD3DB',
                        outline: 'none'
                      }}
                    />
                    <span
                      onClick={() => {
                        let temp = 60;
                        interval = setInterval(function () {
                          temp -= 1;
                          setCooldown(temp);
                          if (temp == 0) {
                            clearInterval(interval);
                          }
                        }, 1000);
                      }}
                      className="uppercase news-page-color"
                      style={{
                        fontStyle: 'normal',
                        cursor: 'pointer',
                        width: 'fitContent',
                        display: cooldown == 0 ? 'block' : 'none'
                      }}
                    >
                      Resend code
                    </span>
                    {cooldown != 0 && (
                      <span className="uppercase" style={{ fontStyle: 'italic', color: 'gray' }}>
                        Resend code <span>{cooldown}</span>
                      </span>
                    )}
                  </div>
                  <div className="crete-account-container">
                    <input type="submit" value="Create Account" className="create-account-btn" />
                  </div>
                </>
              )}
              <div className="already-account">
                <p>
                  Already have an account?{' '}
                  <Link to={'/login'} className="login-text">
                    Log in
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
