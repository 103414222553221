import '../css/privacy_policy.css';

const PrivacyPolicy = () => {
  return (
    <>
      <div id="privacy-policy">
        <div class="container privacy-policy-container">
          <p class="uppercase privacy-policy-title">privacy policy</p>
          <p class="privacy-policy-subtitle">These privacy and policy of Use are effective from 2022.</p>
          <p class="privacy-policy-release-date">Last Updated Date: February 28, 2020</p>
          <p class="privacy-policy-description">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum, laudantium suscipit? Dolore at distinctio
            vero nihil expedita consequatur ab eligendi quas beatae tempora ipsa, non eveniet vel, iste, fuga enim neque
            placeat similique nisi. Distinctio repudiandae aspernatur reiciendis porro ut ipsam architecto blanditiis
            eos minima iure ullam aliquam sequi nam doloremque numquam excepturi, neque, nemo suscipit vel animi.
            Doloribus ad iure facere maiores a explicabo, culpa sit earum illo quaerat? Non suscipit, nisi molestiae
            architecto beatae consectetur ad eligendi. Consequuntur voluptate totam labore fugit deserunt, reiciendis
            consectetur fugiat incidunt facilis autem ad. Ea laudantium officiis saepe provident quod necessitatibus
            nisi!
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
