import { useState } from 'react';
import { Link } from 'react-router-dom';

import useDisableBodyScroll from '../../hooks/useDisableBodyScroll';

const Burger = ({ savedItemsLenght, headerVariant }) => {
  const [open, setOpen] = useState(false);

  useDisableBodyScroll(open);

  return (
    <>
      <div
        className={headerVariant === 'transparent' ? 'navbar' : 'navbar-v2'}
        id="hamburger-menu"
        onClick={() => setOpen(!open)}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
      <nav id="navbar" className={open ? 'showNavBar' : ''}>
        <span className="lnr lnr-cross" id="close-btn" onClick={() => setOpen(!open)}></span>
        {savedItemsLenght > 0 ? (
          <Link to="/saved_item" className="uppercase" onClick={() => setOpen(!open)}>
            Saved Items
          </Link>
        ) : null}
        <Link to={'/apparel'} className="uppercase" onClick={() => setOpen(!open)}>
          Apparel
        </Link>
        <Link to={'/styles'} className="uppercase" onClick={() => setOpen(!open)}>
          Styles
        </Link>
        <Link to={'/news'} className="uppercase" onClick={() => setOpen(!open)}>
          News
        </Link>
        <Link to={'/contact_us'} className="uppercase" onClick={() => setOpen(!open)}>
          Contact us
        </Link>
      </nav>
    </>
  );
};

export default Burger;
