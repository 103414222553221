import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import jwt_decode from 'jwt-decode';

import GoogleImg from '../images/google_login.jpg';

import '../css/login.css';
import '../css/style.css';
import '../css/sneaker_blogpost.css';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [errMsg, setErrMsg] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .post('/user/login', {
        email: formData.email,
        password: formData.password
      })
      .then(function (response) {
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        window.location.href = '/';
        setErrMsg('');
      })
      .catch(function (error) {
        setErrMsg(error.response.data.msg);
      });
  };

  async function handleCallbackResponse(response) {
    var userObject = jwt_decode(response.credential);
    if (userObject.email_verified) {
      await axios
        .post('/user/login', { email: userObject.email, google: true })
        .then(function (response) {
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          window.location.href = '/';
          setErrMsg('');
        })
        .catch(function (error) {
          setErrMsg(error.response.data.msg);
        });
      window.location.href = '/';
    }
  }

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: '857031668998-kge4j0h218g53fm6m7gol0bum3dlvli7.apps.googleusercontent.com',
      callback: handleCallbackResponse
    });

    google.accounts.id.renderButton(document.getElementById('googleSignIn'), {
      theme: 'outline',
      size: 'large'
    });
  }, []);

  return (
    <>
      {/* <!-- section: sidebar-container --> */}
      <div id="loginUSER">
        <div class="container login-container">
          <div class="left-container">
            <p class="login-text">
              Let's get started with <br /> your <span class="trial-period">30 day free trial</span>
            </p>
            <div href="#" class="google-login" id="googleSignIn">
              {/* <img src={GoogleImg} alt="google_login" class="login-w-google-image"/> */}
              {/* <span class="google-sign-up-text">Sign up with Google</span> */}
            </div>
            <form action="" class="login-form" onSubmit={handleSubmit}>
              <div class="form-group">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </div>
              <div class="form-group">
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                />
              </div>
              <button type="submit" class="login-button">
                <span class="lnr lnr-enter"></span> Login
              </button>
              <p style={{ color: 'red' }}>{errMsg}</p>
              <p class="register-text">
                Don't have an account ?{' '}
                <a href="register.html" class="register-link">
                  <Link to="/register">Register here</Link>
                </a>
              </p>
            </form>
          </div>
          <div class="right-container">
            <div class="story">
              <div class="story-text">
                "With untitled, your support process can be as enjoyable as your product. When it's this easy, we're
                finding customers come back again and again."
              </div>
              <div class="story-footer">
                <div class="story-author">
                  <p class="author-name">Annie Singer</p>
                  <span class="author-job">Founder, Sisyphus Ventures</span>
                </div>
                <div class="author-rating">&#9734;&#9734;&#9734;&#9734;&#9734;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
