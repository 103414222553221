const Logo = ({ logoColor }) => {
  return (
    <svg width="125" height="40" viewBox="0 0 254 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3_77)">
        <path
          d="M255 23.625C252.36 27.1393 249.042 27.8925 244.95 28.0066C236.661 28.2376 228.396 29.283 220.119 29.9699C216.568 30.2646 214.328 31.6113 213.888 35.6428C213.022 43.5581 211.835 51.4419 210.607 59.3117C210.179 62.0553 210.651 66.2971 206.32 65.7748C202.373 65.2987 202.15 61.3902 202.552 58.2113C203.632 49.6816 204.998 41.1881 206.36 31.9346C202.56 32.4261 198.732 32.9715 194.891 33.4016C191.927 33.7337 188.861 34.4484 186.001 33.991C184.034 33.6765 181.056 31.8697 180.79 30.3377C180.214 27.0168 182.005 24.3632 186.023 23.9381C193.625 23.1338 201.205 22.1179 208.799 21.2377C221.667 19.7462 234.532 18.2223 247.415 16.8743C250.367 16.5655 253.651 16.4429 254.664 20.8294C255 21.75 255 22.5 255 23.625Z"
          fill="#FF0000"
        />
        <path
          d="M148.402 55.3795C146.419 56.9518 144.643 58.213 142.866 59.4741C142.348 57.1195 140.694 54.2403 141.498 52.4957C146.64 41.35 152.271 30.4273 157.88 19.5027C158.773 17.7628 160.842 14.8928 161.55 15.1178C163.631 15.7797 166.205 17.3637 166.98 19.2473C170.885 28.7461 174.323 38.4387 177.832 48.0973C179.417 52.4583 180.586 56.9778 182.316 61.2757C183.572 64.3983 183.411 67.1207 180.384 68.518C177.156 70.0086 175.343 67.5048 174.554 64.7946C171.889 55.6461 166.072 50.4839 156.42 51.0493C153.747 51.2059 151.208 53.6654 148.402 55.3795ZM161.866 27.1343C159.001 32.1799 156.137 37.2255 153.295 42.2324C159.124 43.4208 163.897 44.394 170.192 45.6774C167.502 39.0402 165.039 32.9634 161.866 27.1343Z"
          fill="#FF0000"
        />
        <path
          d="M50.9385 26.1383C51.4046 26.2932 51.8442 26.8003 52.1825 27.644C51.6916 27.4838 51.3018 26.9871 50.9385 26.1383Z"
          fill="#FBFCFB"
        />
        <path
          d="M59.9778 61.2933C65.2412 62.3504 70.5836 63.1382 75.7161 64.6423C77.1068 65.0499 78.7977 67.7243 78.6407 69.1729C78.4913 70.551 76.2593 72.4902 74.6791 72.7895C68.0571 74.0439 61.3263 74.7106 54.6533 75.7119C42.2715 77.5697 29.9023 79.511 17.2639 81.7088C14.3 82 11.6 82 8.26002 81.7206C3.81393 79.433 1.46128 76.5257 1.87759 71.6409C5.39242 67.5 8.60332 63.5 12.238 59.9317C18.0382 54.2374 24.4998 49.1791 29.9852 43.2115C36.5686 36.0494 41.7813 27.9273 42.0936 17.6307C42.195 14.2872 40.7014 12.3411 37.3616 12.8838C33.8107 13.4607 30.1724 14.23 26.9075 15.6736C21.4473 18.0878 16.3533 21.3325 10.8855 23.7262C9.28525 24.4267 5.91473 24.1231 5.18327 23.0426C4.13741 21.4976 4.20734 18.6247 4.86215 16.6755C5.4233 15.005 7.33422 13.647 8.89277 12.4764C16.688 6.62164 25.6261 3.29978 35 1C36.7144 1 38.4287 1 40.5715 1C42.0082 1.40479 42.9942 1.88092 44.0282 2.203C51.6682 4.58274 55.0342 9.98844 54.4922 20.0772C53.1635 22.4053 52.0642 24.0957 50.9385 26.1383C51.3018 26.9871 51.6916 27.4838 52.0463 27.9921C52.0112 28.0037 52.0575 27.9461 51.9553 28.3297C48.4689 35.4234 45.5807 42.4506 41.5537 48.7498C37.909 54.4508 33.0216 59.3572 27.919 65.5384C35.7201 64.538 42.4062 63.6805 49.507 62.8539C53.2737 62.3543 56.6257 61.8238 59.9778 61.2933Z"
          fill={logoColor}
        />
        <path
          d="M60.0693 60.5233C56.6948 61.6061 53.271 62.2509 49.2333 62.4972C49.5452 54.4635 50.5168 46.8334 51.3761 39.1907C51.7566 35.8072 51.91 32.3981 52.1664 29.0005C49.6339 25.218 50.9873 22.5063 54.8052 20.1595C65.3575 18.9462 75.6257 17.6915 85.9318 17.1236C90.7976 16.8554 95.8297 17.6983 98.874 23.2369C108.848 16.4814 120.762 18.3774 131.825 16.1062C133.436 15.7755 135.492 16.3014 137.007 17.0816C140.694 18.9802 140.829 22.9383 137.007 24.33C131.407 26.3696 125.5 27.6106 119.669 28.9597C115.551 29.9126 111.43 30.6019 109.932 36.0545C115.087 35.6828 119.828 35.4223 124.551 34.9742C128.267 34.6217 131.578 35.641 131.703 39.5984C131.831 43.6521 128.241 43.8003 124.925 44.0892C120.387 44.4844 115.76 45.0546 111.455 46.435C109.115 47.1853 107.363 49.7681 105.346 51.5244C105.677 52.1384 106.008 52.7524 106.34 53.3664C112.218 52.9369 118.095 52.2316 123.977 52.1784C127.682 52.1449 131.542 52.6403 135.041 53.7879C136.701 54.3324 139.086 57.6054 138.672 58.5066C137.804 60.395 135.495 62.1849 133.416 62.8064C130.966 63.5389 128.104 62.7384 125.473 63.0483C118.007 63.9275 110.477 64.6226 103.158 66.2358C99.8286 66.9696 96.8101 67.2161 95.2988 64.4873C93.8442 61.8608 93.2733 58.3454 93.4744 55.2971C93.7592 50.9805 95.1176 46.7359 95.9934 42.4557C96.1257 41.8092 95.8784 40.7736 96.2304 40.5298C102.312 36.3185 97.4548 32.4664 95.7744 28.6271C95.4284 27.8366 93.9528 27.0585 93.0189 27.0828C84.7486 27.2973 76.45 27.3648 68.2311 28.1879C64.5921 28.5523 61.8929 31.0526 63.4551 35.9282C72.4602 36.2388 81.6778 31.0161 91.241 36.1706C89.0604 42.1842 83.4604 42.4129 79.0944 43.8555C74.1896 45.4761 68.8951 45.8843 63.8401 47.1089C62.7446 47.3742 61.3295 48.7516 61.1265 49.8192C60.4858 53.1896 60.3507 56.656 60.0693 60.5233Z"
          fill="#FF0000"
        />
      </g>
    </svg>
  );
};

export default Logo;
