const CloudinaryUploadWidget = ({ uploadSignature, setUploadResult, setUploadError }) => {
  const cloudName = 'feat';
  const key = '585438851578627';

  const myWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: cloudName,
      apiKey: key,
      uploadSignatureTimestamp: uploadSignature.timestamp,
      uploadSignature: uploadSignature.signature,
      cropping: true,
      croppingShowDimensions: true,
      singleUploadAutoClose: false,
      multiple: false, //restrict upload to a single file
      folder: 'affiliate/pages_content',
      secure: true
      // showCompletedButton: true,
      // showAdvancedOptions: true,  //add advanced options (public_id and tag)
      // sources: [ "local", "url"], // restrict the upload sources to URL and local files
      // tags: ["users", "profile"], //add the given tags to the uploaded files
      // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
      // clientAllowedFormats: ["images"], //restrict uploading to image files only
      // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
      // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
      // theme: "purple", //change to a purple theme
    },
    (error, result) => {
      if (!error && result && result.event === 'success') {
        setUploadResult(result.info);
      }

      if (error) {
        setUploadError(error);
      }
    }
  );

  const openWidget = (e) => {
    e.preventDefault();

    myWidget.open();
  };

  return (
    <div
      className="cloudinary-button"
      onClick={(e) => {
        openWidget(e);
      }}
    >
      Upload
    </div>
  );
};

export default CloudinaryUploadWidget;
