import { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';

import axios from 'axios';

import AvatarImg from './images/avatar.jpg';
import CelebImg from './images/celebrate.png';

import './styles/adminStyle.css';

function Admin({ message }) {
  const [userDropdown, setUserDropdown] = useState('user-dropdown-menu hidden');
  const [productSubmenu, setProductSubmenu] = useState('sub-menu hidden');
  const [blogSubmenu, setBlogSubmenu] = useState('sub-menu hidden');
  const [stylesSubmenu, setStylesSubmenu] = useState('sub-menu hidden');
  const [isAdmin, setIsAdmin] = useState(false);
  const [toggler, setToggler] = useState(true);

  useEffect(() => {
    axios
      .get('/user/isAdmin', {
        headers: {
          Authorization: localStorage.getItem('accessToken')
        }
      })
      .then((res) => setIsAdmin(true))
      .catch((error) => {
        console.log(error.response);
        // <Navigate to="/" replace={true} />;
        window.location.href = '/login';
        /*navigation not working properly*/
      });
  });

  function handleUserDropdown(e) {
    e.preventDefault();
    if (userDropdown.includes('hidden')) setUserDropdown('user-dropdown-menu');
    else setUserDropdown('user-dropdown-menu hidden');
  }

  function handleProductSubmenu(e) {
    e.preventDefault();
    if (productSubmenu.includes('hidden')) setProductSubmenu('sub-menu');
    else setProductSubmenu('sub-menu hidden');
  }

  function handleBlogSubmenu(e) {
    e.preventDefault();
    if (blogSubmenu.includes('hidden')) setBlogSubmenu('sub-menu');
    else setBlogSubmenu('sub-menu hidden');
  }

  function handleStylesSubmenu(e) {
    e.preventDefault();
    if (stylesSubmenu.includes('hidden')) setStylesSubmenu('sub-menu');
    else setStylesSubmenu('sub-menu hidden');
  }

  return (
    isAdmin && (
      <>
        <section id="top-header" className={toggler ? '' : 'header-margin-remove'}>
          <div class="left-text">
            <h1 class="welcome-text flex-vertical">
              Welcome, Admin{' '}
              <img
                // src="../assets/images/celebrate.png"
                src={CelebImg}
                class="celebration-image"
                alt="successful login"
              />
            </h1>
            <p>{message}</p>
            <div onClick={() => setToggler(!toggler)} class={toggler ? 'toggler-arrow' : 'toggler-arrow rotate-class'}>
              <span class="lnr lnr-arrow-left left-toggler-arrow"></span>
            </div>
          </div>
          <div class="right-admin-tools">
            <img
              onClick={handleUserDropdown}
              // src="../assets/images/avatar.jpg"
              src={AvatarImg}
              class="avatar-img"
              alt="admin"
            />
            <ul class={userDropdown}>
              <li class="dropdown-item">
                <Link to="/profile">Profile</Link>
              </li>
              <li class="dropdown-item">
                <Link
                  to="/login"
                  onClick={() => {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                  }}
                >
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </section>
        <section id="sidebar-menu" className={toggler ? '' : 'hide-sidebar'}>
          <div class="sidebar-container">
            <div class="sidebar-logo-container">
              <img src="../assets/images/logo.png" alt="2feat" />
            </div>
            <div class="menu-items">
              <p class="uppercase menu-title">menu</p>
              <div class="items">
                <Link to="/admin/" class="item">
                  <div class="menu-icon">
                    <i class="fa fa-bar-chart"></i>
                  </div>
                  <div class="menu-text">Overview</div>
                </Link>
                <Link to="/admin/pages-content/" class="item">
                  <div class="menu-icon">
                    <i class="fa fa-bar-chart"></i>
                  </div>
                  <div class="menu-text">Pages content</div>
                </Link>
                <div onClick={handleProductSubmenu} class="item product-menu">
                  <div class="menu-icon">
                    <i class="fa fa-shopping-basket"></i>
                  </div>
                  <div class="menu-text">Product</div>
                  <div class="click-category">
                    <i class="fa fa-chevron-circle-down option-click"></i>
                  </div>
                </div>
                <div class={productSubmenu}>
                  <div class="submenu-item">
                    <Link to="/admin/product/add">
                      <i class="fa fa-plus"></i>
                      <span>Add Product</span>
                    </Link>
                    <Link to="/admin/product/addTopics">
                      <i class="fa fa-fire" aria-hidden="true"></i>
                      <span>Add Topics</span>
                    </Link>
                    <Link to="/admin/product/all">
                      <i class="fa fa-th-list"></i>
                      <span>All Listings</span>
                    </Link>
                  </div>
                </div>
                <div onClick={handleBlogSubmenu} class="item blog-menu" id="blog-menu">
                  <div class="menu-icon">
                    <i class="fa fa-database"></i>
                    {/* <!-- <span class="lnr lnr-book"></span> --> */}
                  </div>
                  <div class="menu-text">Blog</div>
                  <div class="click-category">
                    <i class="fa fa-chevron-circle-down option-click blog-click"></i>
                  </div>
                </div>
                <div class={blogSubmenu}>
                  <div class="submenu-item">
                    <Link to="/admin/blog/add">
                      <span class="lnr lnr-plus-circle"></span>
                      <span>Add blog</span>
                    </Link>
                    <Link to="/admin/blog/addTopics">
                      <i class="fa fa-fire" aria-hidden="true"></i>
                      <span>Add Topics</span>
                    </Link>
                    <Link to="/admin/blog/all">
                      <span class="lnr lnr-list"></span>
                      <span>All Listings</span>
                    </Link>
                  </div>
                </div>
                <Link to="/admin/brand" class="item">
                  <div class="menu-icon">
                    <i class="fa fa-tag"></i>
                  </div>
                  <div class="menu-text">Brand</div>
                </Link>
                <div onClick={handleStylesSubmenu} class="item product-menu">
                  <div class="menu-icon">
                    <i class="fa fa-shopping-basket"></i>
                  </div>
                  <div class="menu-text">Styles</div>
                  <div class="click-category">
                    <i class="fa fa-chevron-circle-down option-click"></i>
                  </div>
                </div>
                <div class={stylesSubmenu}>
                  <div class="submenu-item">
                    <Link to="/admin/styles/images">
                      <i class="fa fa-plus"></i>
                      <span>Add Styles Image</span>
                    </Link>
                    <Link to="/admin/styles/categories">
                      <i class="fa fa-fire" aria-hidden="true"></i>
                      <span>Add Styles Category</span>
                    </Link>
                    <Link to="/admin/styles/all">
                      <i class="fa fa-th-list"></i>
                      <span>All Listings</span>
                    </Link>
                  </div>
                </div>
                <Link to="/admin/category" class="item">
                  <div class="menu-icon">
                    <span class="lnr lnr-chart-bars"></span>
                  </div>
                  <div class="menu-text">Category</div>
                </Link>
                <Link to="/admin/productFor" class="item">
                  <div class="menu-icon">
                    <i class="fa fa-child"></i>
                  </div>
                  <div class="menu-text">Product For</div>
                </Link>
                <Link to="/admin/marketplaces" class="item">
                  <div class="menu-icon">
                    <i class="fa fa-building"></i>
                  </div>
                  <div class="menu-text">Marketplaces</div>
                </Link>
                <Link to="/admin/email" class="item">
                  <div class="menu-icon">
                    <i class="fa fa-envelope"></i>
                  </div>
                  <div class="menu-text">Email</div>
                </Link>
                <Link to="/admin/subscribers" class="item">
                  <div class="menu-icon">
                    <i class="fa fa-envelope"></i>
                  </div>
                  <div class="menu-text">Subscribers</div>
                </Link>
                <Link to="/admin/about" class="item">
                  <div class="menu-icon">
                    <i class="fa fa-info-circle"></i>
                  </div>
                  <div class="menu-text">About</div>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <Outlet context={{ toggler }} />
      </>
    )
  );
}

export default Admin;
