import { useState } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import './styles.css';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [showSaveModal, setShowSaveModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post('/subscriber/subscribe', { email: email })
      .then((result) => {
        // const res = result.data.data;

        setShowSaveModal(true);
        setEmail('');
      })
      .catch((error) => {
        console.log('error', error);
        /*to add notification*/
      });
  };

  return (
    <footer>
      <div className="footer-links">
        <Link to={'/contact_us'}>Contact Us</Link>
        <a href="https://www.instagram.com/2.feat/" rel="noreferrer" target="_blank">
          Instagram
        </a>
        <Link to={'/support'}>Support</Link>
        <form className="subscribe-form" onSubmit={(e) => handleSubmit(e)}>
          <label htmlFor="subscribe_email">Let's be in touch:</label>
          <input
            id="subscribe_email"
            name="subscribe_email"
            type="email"
            className="subscibe-input"
            value={email}
            placeholder="your@email.com"
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* <button type="submit" className="subscibe-submit"></button> */}
        </form>
      </div>
      <div className="copyright">&copy; 2023 2feat. All rights reserved</div>
      {/* temporary notification */}
      <div id="toast-notification">
        <div className={showSaveModal ? 'notice' : 'notice_none'}>
          <span className="check-circle">
            <i className="lnr lnr-checkmark-circle"></i>
          </span>
          <span className="toast-text">Subscribed successfully </span>
          <span className="close-notice" onClick={() => setShowSaveModal(false)}>
            &times;
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
