import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import useMobile from '../../hooks/useMobile';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import { addClass, removeClass } from '../../utils/utils';

import { AdvancedVideo } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import SoundsOn from '../../styles/icons/SoundsOn';
import SoundsOff from '../../styles/icons/SoundsOff';

import 'react-lazy-load-image-component/src/effects/blur.css';
import './styles.css';

const MediaContent = ({ pagesContent }) => {
  const videoRef = useRef(null);

  let location = useLocation();
  const isMobile = useMobile();
  const { height, width } = useWindowDimensions();

  const filteredContent = pagesContent.filter((page) => {
    if (location.pathname === '/') {
      return page.page === 'main';
    } else {
      return page.page === location.pathname.substring(1);
    }
  });

  useEffect(() => {
    if (!isMobile && filteredContent[0]?.layout === 'left') {
      addClass('media-text-container', 'text-container-split');
      addClass('image-content', 'img-split');
    } else if (!isMobile && filteredContent[0]?.layout === 'right') {
      addClass('media-text-container', 'text-container-split');
      addClass('image-content', 'img-split');
      addClass('image-container', 'image-container-reverse');
    }
  }, [filteredContent, isMobile]);

  const renderText = () => {
    if (!isMobile) {
      addClass('text', 'text-visible');
      removeClass('text-visible', 'text');
    }
  };

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'feat'
    }
  });

  const myVideo = cld.video(filteredContent[0]?.content.publicId);
  myVideo.resize(
    fill()
      .width(isMobile ? height : width)
      .height(isMobile ? width : height)
  );

  const playSound = () => {
    if (videoRef.current.videoRef.current.muted === true) {
      videoRef.current.videoRef.current.muted = !videoRef.current.videoRef.current.muted;

      addClass('sounds-off', 'invisible');
      removeClass('sounds-on', 'invisible');
    } else {
      videoRef.current.videoRef.current.muted = !videoRef.current.videoRef.current.muted;

      addClass('sounds-on', 'invisible');
      removeClass('sounds-off', 'invisible');
    }
  };

  return (
    <>
      {filteredContent.length > 0 ? (
        <section id="heroHome">
          {filteredContent[0]?.content.resourceType === 'image' ? (
            <div className="image-container">
              <LazyLoadImage
                alt={filteredContent[0]?.content.contentTitle}
                src={filteredContent[0]?.content.url}
                placeholderSrc={filteredContent[0]?.content.thumbnail_url}
                width={!isMobile ? (filteredContent[0]?.layout === 'center' ? '100%' : '50%') : '100%'}
                className="image-content"
                effect="blur"
                afterLoad={() => renderText()}
              />
              {!isMobile ? (
                <div className="media-text-container">
                  <h1 className="text uppercase">{filteredContent[0]?.contentTitle}</h1>
                </div>
              ) : null}
              <div className="rockin">
                <span>"What you rockin with those?"</span>
              </div>
            </div>
          ) : (
            <>
              <AdvancedVideo ref={videoRef} cldVid={myVideo} cldPoster="auto" autoPlay muted loop playsInline />
              <div className="sounds-icon sounds-on invisible" onClick={playSound}>
                <SoundsOn />
              </div>
              <div className="sounds-icon sounds-off" onClick={playSound}>
                <SoundsOff />
              </div>
            </>
          )}
          <div id="scroll-position"></div>
        </section>
      ) : null}
    </>
  );
};

export default MediaContent;
