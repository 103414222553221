import { useState } from 'react';

import EnlargedImage from '../../components/Modals/EnlargedImage';

const Style = ({ style }) => {
  const [enlargedImage, setEnlargeImage] = useState('');
  const [showEnlImage, setShowEnlImage] = useState(false);

  return (
    <>
      <div
        className="style gallery-item Streetwear"
        onClick={() => {
          setEnlargeImage(style.mainImage.url);
          setShowEnlImage(true);
        }}
      >
        <img src={style.mainImage.url} alt="style" />
      </div>
      <EnlargedImage
        showEnlImage={showEnlImage}
        setShowEnlImage={setShowEnlImage}
        enlargedImage={enlargedImage}
        relatedProducts={style.relatedProducts}
      />
    </>
  );
};

export default Style;
