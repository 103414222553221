import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
// import SubscriptionModal from '../components/Modals/SubscriptionModal';

import '../css/style.css';

const MainLayout = ({ savedItems, pagesContent, products, filters }) => {
  let location = useLocation();
  // const [isShown, setIsShown] = useState(false);

  const hash = window.location.hash;

  useEffect(() => {
    if (!hash) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location, hash]);

  // const modal = localStorage.getItem('subscriptionModal');

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (modal !== 'opened') {
  //       setIsShown(true);
  //       localStorage.setItem('subscriptionModal', 'opened');
  //     }
  //   }, 45000);
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <main>
      <Header savedItems={savedItems} pagesContent={pagesContent} products={products} filters={filters} />
      <Outlet />
      <Footer />
      {/* <SubscriptionModal isShown={isShown} setIsShown={setIsShown} /> */}
    </main>
  );
};

export default MainLayout;
