import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Result from './Result';
import Tabs from '../HomePage/Tabs';

import './styles.css';

const Search = ({
  products,
  savedItems,
  setSavedItems,
  setSelectedTab,
  selectedTab,
  refs,
  savedSlides,
  savedSlidesSetter
}) => {
  const navigate = useNavigate();
  let { search } = useParams();

  const [searchResult, setSearchResult] = useState(products);
  const [hasItem, setHasItem] = useState(false);

  useEffect(() => {
    let res = products.filter((item) => {
      if (item.productName.toLowerCase().includes(search.toLowerCase())) return true;
      else if (item.tags.find((el) => el.includes(search.toLowerCase()))) return true;
      else if (item.description?.toLowerCase().includes(search.toLowerCase())) return true;
      else if (item.shortDesc.toLowerCase().includes(search.toLowerCase())) return true;
      else if (item.brand.toLowerCase().includes(search.toLowerCase())) return true;
      else if (item.productFor.toLowerCase().includes(search.toLowerCase())) return true;
      else if (item.category.toLowerCase().includes(search.toLowerCase())) return true;
    });

    if (!res.length) {
      setHasItem(false);
    } else {
      setHasItem(true);
    }

    setSearchResult(res);
  }, [navigate, search, products]);

  return (
    <>
      <Tabs
        savedItems={savedItems}
        setSavedItems={setSavedItems}
        refs={refs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        savedSlides={savedSlides}
        savedSlidesSetter={savedSlidesSetter}
        products={products}
      />
      <div className="search-result-container">
        {hasItem ? (
          <Result
            products={searchResult}
            savedItems={savedItems}
            setSavedItems={setSavedItems}
            hasItem={hasItem}
            refs={refs}
            savedSlidesSetter={savedSlidesSetter}
          />
        ) : (
          <div class="info-section">
            <div class="header-text">
              <h2>
                <p>
                  <span class="greyish-text">WE COULD NOT FIND ANYTHING FOR</span> "
                  <span class="blackish-text">{search}</span>"
                </p>
              </h2>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Search;
