import React, { useState, useEffect } from 'react';

import axios from 'axios';

import './styles.css';
import '../../css/toast_notification.css';

const Subscribers = ({ getMessage }) => {
  getMessage(`Subscribers section`);

  const [subscribers, setSubscribers] = useState([]);
  const [actionModal, setActionModal] = useState(false);

  useEffect(() => {
    axios.get('/subscriber/getSubscribers').then((res) => {
      setSubscribers(res.data.subscribers);
    });
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(subscribers.map((sub) => sub.email));
    setActionModal(true);
  };

  return (
    <>
      <section id="brand-" className={'left-margin-container'}>
        <div className="listing-table-wrapper">
          <div className="container add-brand-container">
            <h1>All Subscribers</h1>
            <table className="listings-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Emails</th>
                </tr>
              </thead>
              <tbody>
                {subscribers.map((subscriber, index) => {
                  return (
                    <tr key={'subscriber' + index}>
                      <td>{index + 1}</td>
                      <td>{subscriber.email}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="copy-to-clipboard" onClick={copyToClipboard}>
          Copy to clipboard
        </div>
        {/* temporary notification */}
        <div id="toast-notification">
          <div className={actionModal ? 'notice' : 'notice_none'}>
            <span className="check-circle">
              <i className="lnr lnr-checkmark-circle"></i>
            </span>
            <span className="toast-text">Copied to clipboard</span>
            <span className="close-notice" onClick={() => setActionModal(false)}>
              &times;
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default Subscribers;
