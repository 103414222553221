import { useRef } from 'react';

import ProductPreview from '../../ProductPreview';

import useOutsideClick from '../../../hooks/useOutsideClick';
import useEscapeKey from '../../../hooks/useEscapeKey';
import useDisableBodyScroll from '../../../hooks/useDisableBodyScroll';

import BagIcon from '../../../styles/icons/BagIcon';

import './styles.css';

const EnlargedImage = ({
  showEnlImage,
  setShowEnlImage,
  productId,
  enlargedImage,
  savedItems,
  setSavedItems,
  relatedProducts
}) => {
  const enlargeRef = useRef(null);

  const saveItem = () => {
    const newSavedItemsList = [...new Set([...savedItems, productId])];
    setSavedItems(newSavedItemsList);

    setShowEnlImage(false);
  };

  const sendNo = () => setShowEnlImage(false);

  useEscapeKey(sendNo);
  useOutsideClick(sendNo, enlargeRef);
  useDisableBodyScroll(showEnlImage);

  return (
    <div id="enlarge-modal" className={showEnlImage ? '' : 'hidden-enlarge'}>
      <div className="container enlarged-modal-container">
        <span onClick={() => setShowEnlImage(false)} className="close-modal">
          &times;
        </span>
        <div className={relatedProducts && relatedProducts.length > 0 ? 'image-style-container' : 'image-container'}>
          <div className={relatedProducts ? 'image-style-wrapper' : 'image-wrapper'} ref={enlargeRef}>
            <div className="image-div">
              <img alt="" src={enlargedImage} className={relatedProducts ? 'image-style' : 'image'} />
              {productId ? (
                <div onClick={() => saveItem()} className="enlarged-view-icons">
                  <BagIcon />
                </div>
              ) : null}
            </div>
            {relatedProducts && relatedProducts.length ? (
              <div className="related-products-container">
                <div className="related-products-title-container">
                  <h1 className="related-products-title">RELATED PRODUCTS</h1>
                  <div className="related-products-line"></div>
                </div>
                {relatedProducts.map((product) => (
                  <ProductPreview key={product._id} product={product} />
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnlargedImage;
