import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import parse from 'html-react-parser';

import PopularStories from '../components/Stories/PopularStories';

import { categoryDict } from '../constants/data';

import InstagramIcon from '../styles/icons/InstagramIcon';
import FacebookIcon from '../styles/icons/FacebookIcon';

import '../css/slick.css';
import '../css/slick-theme.css';
import '../css/style.css';
import '../css/sneaker_news.css';
import '../css/sneaker_blogpost.css';

const Product = ({ products, news, savedItems, setSavedItems, setSelectedTab, savedSlidesSetter }) => {
  const { id } = useParams();

  const product = products.filter((item) => item._id === id)[0];

  console.log('product', product);

  const [showSaveModal, setShowSaveModal] = useState(false);
  const [saveOrSlider, setSavSli] = useState(true);

  const saveLocalStorage = () => {
    setSavSli(true);
    setShowSaveModal(true);
    const newProductIds = [...new Set([...savedItems, product._id])];
    setSavedItems(newProductIds);
  };

  const saveToSlider = () => {
    setShowSaveModal(true);
    setSavSli(false);
    setSelectedTab(categoryDict[product.category]);

    const tempBlog = {
      mainImage: product.mainImage,
      topAfterMarket: product.topAfterMarket,
      _id: product._id,
      productName: product.productName,
      author: product.author,
      shortDesc: product.shortDesc,
      price: product.price,
      releaseDate: product.releaseDate,
      afterMarkets: product.afterMarkets,
      tags: product.tags,
      style: product.style,
      size: product.size,
      category: product.category,
      brand: product.brand,
      inStock: product.inStock,
      productFor: product.productFor,
      isPublished: product.isPublished,
      topic: product.topic,
      __v: product.__v
    };

    const slider = JSON.parse(localStorage.getItem('slider')) || [[], [], [], [], []];

    if (slider[categoryDict[product.category]].includes(JSON.stringify(tempBlog))) {
      const indexofElement = slider[categoryDict[tempBlog.category]].indexOf(JSON.stringify(tempBlog));
      slider[categoryDict[tempBlog.category]].splice(indexofElement, 1);
    }

    slider[categoryDict[tempBlog.category]].push(JSON.stringify(tempBlog));
    localStorage.setItem('slider', JSON.stringify(slider));

    savedSlidesSetter();
  };

  return (
    <>
      {!product && (
        <div className="spinner-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      {product ? (
        <>
          <div id="toast-notification">
            <div className={showSaveModal ? 'notice' : 'notice_none'}>
              <span className="check-circle">
                <i className="lnr lnr-checkmark-circle"></i>
              </span>
              <span className="saved-text">
                Saved successfully{' '}
                <Link to={{ pathname: saveOrSlider ? '/saved_item' : '/', hash: saveOrSlider ? '' : '#tabs' }}>
                  <a href="#" className="underline">
                    show
                  </a>
                </Link>
              </span>
              <span className="close-notice" onClick={() => setShowSaveModal(false)}>
                &times;
              </span>
            </div>
          </div>
          <section id="blog">
            <div className="container-blog-img" style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={product.mainImage?.url} alt="MainImg" />
            </div>
            <div className="container-blog-content">
              <h1>{product.productName}</h1>
              <div className="blogpost-buttons">
                <button id="add-to-slider" className="product-save-btn" onClick={() => saveToSlider()}>
                  Add to slider
                </button>
                <button
                  id="save-item"
                  className="product-save-btn"
                  onClick={() => saveLocalStorage() && setShowSaveModal}
                >
                  Save Item
                </button>
              </div>
              <div className="content-info">
                <div className="post-info">
                  <span>by</span> <span className="posted-by"> {product.author}</span>
                </div>
                <div className="post-disclaimer">
                  <span className="lnr lnr-file-empty"></span>
                  <span className="disclaimer-text">
                    2feat may receive compensation when you click on links to products
                  </span>
                </div>
              </div>
              <div className="social-media">
                <a href="https://www.instagram.com/2.feat/" rel="noreferrer" target="_blank">
                  <InstagramIcon />
                </a>
                <a href="https://www.instagram.com/2.feat/" rel="noreferrer" target="_blank">
                  <FacebookIcon />
                </a>
                <h3>Be sure to check out our social media for more styling tips and photos</h3>
              </div>
              <p>{product.description ? parse(product.description) : ''}</p>
              <div className="product-info">
                <h2>{product.title}</h2>
                <div className="major-info release-date">
                  Release Date: {new Date(product.releaseDate).toDateString()}
                </div>
                <div className="minor-info product-color">Color: {product.color}</div>
                <div className="major-info product-price">Mens: ${product.price}</div>
                <div className="minor-info style-code">Style Code: {product.styleCode}</div>
              </div>
              {product?.whereToBuy?.length > 0 ? (
                <>
                  <div className="where-to-buy-title">
                    <h2>Where to buy Retail</h2>
                  </div>
                  <div className="cards">
                    {product.whereToBuy?.map((item) => {
                      if (item !== null)
                        return (
                          <a key={item.url} href={item?.url} rel="noreferrer" target="_blank">
                            <div className="buy-card">
                              <h3>{item?.name.toUpperCase()}</h3>
                            </div>
                          </a>
                        );
                    })}
                  </div>
                </>
              ) : null}
              {product?.afterMarkets?.length > 0 ? (
                <>
                  <div className="where-to-buy-title">
                    <h2>Where to buy Aftermarket</h2>
                  </div>
                  <div className="cards">
                    {product.afterMarkets?.map((item) => {
                      if (item !== null)
                        return (
                          <a href={item?.affiliateLink} rel="noreferrer" target="_blank">
                            <div className="buy-card">
                              <h3>{item?.name.toUpperCase()}</h3>
                            </div>
                          </a>
                        );
                    })}
                  </div>
                </>
              ) : (
                <>
                  {product?.topAfterMarket ? (
                    <div className="where-to-buy-title">
                      <h2>Where to buy Aftermarket</h2>
                    </div>
                  ) : null}
                  {product?.topAfterMarket ? (
                    <div className="cards">
                      <a href={product?.topAfterMarke?.affiliateLink} rel="noreferrer" target="_blank">
                        <div className="buy-card">
                          <h3>{product?.topAfterMarket.name.toUpperCase()}</h3>
                        </div>
                      </a>
                    </div>
                  ) : null}
                </>
              )}
            </div>
            <div
              className="container-blog-img"
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              {product.otherImages?.map((item) => {
                return <img key={'img' + item.url} src={item.url} alt="nike" />;
              })}
            </div>
            <div className="container-blog-img">
              <div className="blog-footer">
                <div className="tags">
                  <span>TAGS / CATEGORIES:</span>
                  <span>
                    {product.tags?.map((item, index) => {
                      return (
                        <Link to={`/search/${item}`} key={'tag' + index} href="#">
                          {item}
                        </Link>
                      );
                    })}
                  </span>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}
      <PopularStories news={news} />
    </>
  );
};

export default Product;
