import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { imgUpload } from '../../utils/img';

const AddProduct = ({ getMessage }) => {
  getMessage('Product Section');

  const { toggler } = useOutletContext();

  const [productContainer, setProductContainer] = useState('product-container-content hidden');
  const [afterMarketContainer, setAMContainer] = useState('after-market-container hidden');
  const [whereToBuyContainer, setWTBContainer] = useState('where-to-buy-container hidden');

  const [productName, setProductName] = useState('');
  const [author, setAuthor] = useState('');
  const [mainImage, setMainImage] = useState();
  const [description, setDesc] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [color, setColor] = useState('');
  const [price, setPrice] = useState(0);
  const [releaseDate, setReleaseDate] = useState('');
  const [styleCode, setStyleCode] = useState('');
  const [topAfterMarket, setTopAM] = useState({});
  const [afterMarkets, setAfterMs] = useState([]);
  const [whereToBuy, setWhereToBuy] = useState([]);
  const [tags, setTags] = useState([]);
  const [style, setStyle] = useState('');
  const [size, setSize] = useState('All');
  const [category, setCategory] = useState('');
  const [brand, setBrand] = useState('');
  const [otherImages, setOtherImages] = useState([]);
  const [inStock, setInStock] = useState(false);
  const [productFor, setProductFor] = useState('');
  const [topic, setTopic] = useState('NONE');
  const [allTopics, setAllTopics] = useState([]);
  const [allBrand, setAllBrand] = useState([]);
  const [allStyle, setAllStyle] = useState([]);
  const [allProductFor, setAllProductFor] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [allMarketplace, setAllMarketplace] = useState([]);

  useEffect(() => {
    axios.get('/filter/all').then((res) => {
      const result = { ...res.data.filters };

      setAllBrand([...result.brand]);
      setBrand(result.brand[0]);

      setAllCategory(result.categories);
      setCategory(result.categories[0]);

      setAllTopics(result.productTopics);

      setAllMarketplace(result.marketplace);

      setAllStyle(result.styles);
      setStyle(result.styles[0]);

      setAllProductFor(result.productFor);
      setProductFor(result.productFor[0]);
    });
  }, []);

  function handleProductName(e) {
    e.preventDefault();
    setProductName(e.target.value);
  }

  function handleAuthor(e) {
    e.preventDefault();
    setAuthor(e.target.value);
  }

  function handleShortDesc(e) {
    e.preventDefault();
    setShortDesc(e.target.value);
  }

  function handleColor(e) {
    e.preventDefault();
    setColor(e.target.value);
  }

  function handlePrice(e) {
    e.preventDefault();
    setPrice(e.target.value);
  }

  function handleReleaseDate(e) {
    e.preventDefault();
    setReleaseDate(e.target.value);
  }

  function handleStyleCode(e) {
    e.preventDefault();
    setStyleCode(e.target.value);
  }

  function handleTags(e) {
    e.preventDefault();

    let t = e.target.value.split(',');
    t = t.map((item) => {
      return item.toLowerCase();
    });

    setTags(t);
  }

  function handleStyle(e) {
    e.preventDefault();
    setStyle(e.target.value);
  }

  function handleSize(e) {
    e.preventDefault();
    setSize(e.target.value);
  }

  function handleCategory(e) {
    e.preventDefault();
    setCategory(e.target.value);
  }

  function handleBrand(e) {
    e.preventDefault();
    setBrand(e.target.value);
  }

  function handleOtherImages(e) {
    e.preventDefault();

    const a = [];

    for (let i = 0; i < e.target.files.length; i++) {
      let reader = new FileReader();
      reader.onload = () => {
        a.push(reader.result);
        setOtherImages(a);
      };

      reader.readAsDataURL(e.target.files[i]);
    }
  }

  function handleInStock(e) {
    e.preventDefault();

    if (e.target.value === 'yes') setInStock(true);
    else setInStock(false);
  }

  function handleProductFor(e) {
    e.preventDefault();
    setProductFor(e.target.value);
  }

  function handleTopic(e) {
    e.preventDefault();
    setTopic(e.target.value);
  }

  function handleMainImage(e) {
    e.preventDefault();

    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setMainImage(reader.result);
    };
  }

  function addWTB(e) {
    e.preventDefault();

    const t = { name: '', url: '' };

    if (whereToBuy.length < 5) {
      setWhereToBuy([...whereToBuy, t]);
    }
  }

  function addAMs(e) {
    e.preventDefault();

    if (allMarketplace.length > afterMarkets.length + 1) {
      const t = {
        affiliateLink: '',
        name: ''
      };
      setAfterMs([...afterMarkets, t]);
    }
  }

  function handleProductC(e) {
    e.preventDefault();

    if (productContainer.includes('hidden')) setProductContainer('product-container-content');
    else setProductContainer('product-container-content hidden');
  }

  function handleAMContainer(e) {
    e.preventDefault();

    if (afterMarketContainer.includes('hidden')) setAMContainer('after-market-container');
    else setAMContainer('after-market-container hidden');
  }

  function handleWTBContainer(e) {
    e.preventDefault();

    if (whereToBuyContainer.includes('hidden')) {
      setWTBContainer('where-to-buy-container');
      setWhereToBuy([{ name: '', url: '' }]);
    } else setWTBContainer('where-to-buy-container hidden');
  }

  const WhereToBuyForm = ({ index, item, setWhereToBuy }) => {
    const [name, setName] = useState(item.name);
    const [url, setUrl] = useState(item.url);

    function handleName(e) {
      e.preventDefault();

      let a = whereToBuy;
      a[index].name = e.target.value;
      setWhereToBuy(a);
      setName(e.target.value);
    }

    function handleUrl(e) {
      e.preventDefault();

      let a = whereToBuy;
      a[index].url = e.target.value;
      setWhereToBuy(a);
      setUrl(e.target.value);
      console.log(whereToBuy);
    }

    return (
      <>
        <div class="form-group">
          <label for="title_text">Title text</label>
          <input
            value={name}
            onChange={handleName}
            type="text"
            name="title_text"
            id="title_text"
            class="title_text"
            placeholder="where to buy title"
          />
        </div>
        <div class="form-group">
          <label for="where_to_buy_link">Link</label>
          <input
            value={url}
            onChange={handleUrl}
            type="text"
            name="where_to_buy_link"
            class="where_to_buy_link"
            id="where_to_buy_link"
            placeholder="where to buy link"
          />
        </div>
      </>
    );
  };

  const AfterMarketForm = ({ index, item, setAfterMs }) => {
    const [name, setName] = useState(item.name);
    const [affiliateLink, setAffiliateLink] = useState(item.affiliateLink);

    function handleName(e) {
      e.preventDefault();
      let a = afterMarkets;
      a[index].name = e.target.value;
      setAfterMs(a);
      setName(e.target.value);
    }

    function handleUrl(e) {
      e.preventDefault();
      let a = afterMarkets;
      a[index].affiliateLink = e.target.value;
      setAfterMs(a);
      setAffiliateLink(e.target.value);
    }

    return (
      <>
        <hr />
        <div class={afterMarketContainer}>
          <div class="form-group">
            <label for="marketplace">Marketplace</label>
            <select value={name} onChange={handleName} name="marketplace" id="marketplace" class="pointer">
              <option value="">None</option>
              {allMarketplace.map((item) => {
                return <option value={item}>{item}</option>;
              })}
            </select>
          </div>
          <div class="form-group">
            <label for="marketplace_link">Affiliate Link</label>
            <input
              value={affiliateLink}
              onChange={handleUrl}
              type="text"
              name="marketplace_link"
              class="affiliate_link"
              id="affiliate_link"
              placeholder="affiliate link"
            />
          </div>
        </div>
      </>
    );
  };

  async function addProduct(e) {
    e.preventDefault();

    const mImg = await imgUpload(mainImage);

    const otherImgs = await imgUpload(otherImages);

    var parts = releaseDate.split('-');
    const rd = new Date(parts[0], parts[1] - 1, parts[2]);

    const ams = afterMarkets;

    if (topAfterMarket.affiliateLink) ams.push(topAfterMarket);

    const finalAMS = ams.map((item) => {
      if (item.affiliateLink && item.name) return item;
    });

    const finalWTB = whereToBuy.map((item) => {
      if (item.url && item.name) return item;
    });

    const result = await axios
      .post(
        '/products/newProduct',
        {
          mainImage: mImg,
          productName,
          author,
          description,
          shortDesc,
          color,
          price,
          releaseDate: rd,
          styleCode,
          afterMarkets: finalAMS,
          whereToBuy: finalWTB,
          tags: tags,
          style,
          size,
          category,
          brand,
          otherImages: otherImgs,
          inStock,
          productFor,
          isPublished: true,
          topic
        },
        {
          headers: {
            Authorization: localStorage.getItem('accessToken')
          }
        }
      )
      .catch((error) => {
        if (error.response.data.msg == 'Invalid token') {
          axios
            .get('/refresh', {
              headers: {
                Refresh: localStorage.getItem('refreshToken')
              }
            })
            .then((res) => {
              localStorage.setItem('accessToken', res.data.accessToken);
              axios.post(
                '/products/newProduct',
                {
                  mainImage: mImg,
                  productName,
                  author,
                  description,
                  shortDesc,
                  color,
                  price,
                  releaseDate: rd,
                  styleCode,
                  afterMarkets: finalAMS,
                  whereToBuy: finalWTB,
                  tags: tags,
                  style,
                  size,
                  category,
                  brand,
                  otherImages: otherImgs,
                  inStock,
                  productFor,
                  isPublished: true,
                  topic
                },
                {
                  headers: {
                    Authorization: localStorage.getItem('accessToken')
                  }
                }
              );
            })
            .catch((err) => {
              console.log('err', err);
              window.location.href = '/';
            });
        }
      });

    console.log('result', result); //make notification
    window.location.href = '/admin/product/all';
  }

  return (
    <>
      <section id="add-product-form" class={toggler ? 'left-margin-container' : 'left-margin-container margin-remove'}>
        <div class="container add-product-form-container">
          <form onSubmit={addProduct} class="add-product-form">
            <div class="product-container">
              <div class="top-text-container add-product-header">
                <h1>
                  <span>Add product</span>
                  <i onClick={handleProductC} class="fa fa-dot-circle expand-circle"></i>
                </h1>
                <p>Add the product to list on the website!</p>
              </div>
              <div class="line-bar"></div>
              <div class={productContainer}>
                <div class="product-and-author">
                  <div class="form-group">
                    <label for="product_name">Product name</label>
                    <input
                      type="text"
                      name="product_name"
                      id="product_name"
                      placeholder="Product name"
                      value={productName}
                      onChange={handleProductName}
                    />
                  </div>
                  <div class="form-group">
                    <label for="author">Author</label>
                    <input
                      type="text"
                      name="author"
                      id="author"
                      placeholder="author"
                      value={author}
                      onChange={handleAuthor}
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="product_image">Product Image</label>
                  <input onChange={handleMainImage} type="file" name="product_image" id="product_image" />
                </div>
                <div class="category-and-brand">
                  <div class="form-group">
                    <label for="category">Category</label>
                    <select name="category" value={category} onChange={handleCategory} id="category">
                      {allCategory.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="brand">Brand</label>
                    <select value={brand} onChange={handleBrand} name="brand" id="brand">
                      {allBrand.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="size">Size</label>
                    <select value={size} onChange={handleSize} name="size" id="brand">
                      <option value="All">All</option>
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="XXL">XXL</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="style_code">Style code</label>
                    <input
                      value={styleCode}
                      onChange={handleStyleCode}
                      type="text"
                      name="style_code"
                      id="style_code"
                      placeholder="style code"
                    />
                  </div>
                </div>
                <div class="price-and-color">
                  <div class="form-group">
                    <label for="price">Price</label>
                    <input
                      value={price}
                      onChange={handlePrice}
                      type="number"
                      name="price"
                      id="price"
                      placeholder="price"
                    />
                  </div>
                  <div class="form-group">
                    <label for="color">Color</label>
                    <input
                      value={color}
                      onChange={handleColor}
                      type="text"
                      name="color"
                      id="color"
                      placeholder="color"
                    />
                  </div>
                  <div class="form-group">
                    <label for="shortDesc">Short Description</label>
                    <input
                      value={shortDesc}
                      onChange={handleShortDesc}
                      type="text"
                      name="shortDesc"
                      id="color"
                      placeholder="short description"
                    />
                  </div>
                  <div class="form-group">
                    <label for="topic">Topic</label>
                    <select value={topic} onChange={handleTopic} name="topic" id="topic">
                      <option value="NONE">None</option>;
                      {allTopics.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="release_date">Release date</label>
                    <input
                      value={releaseDate}
                      onChange={handleReleaseDate}
                      type="date"
                      name="release_date"
                      id="release_date"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="description">Description</label>
                  <div style={{ marginRight: '10em' }}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDesc(data);
                      }}
                    />
                  </div>
                </div>
                <div class="filter-and-stock">
                  <div class="form-group">
                    <label for="product_for">Product for</label>
                    <select value={productFor} onChange={handleProductFor} name="product_for" id="product_for">
                      {allProductFor.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="stock_status">In stock</label>
                    <select
                      value={inStock ? 'yes' : 'no'}
                      onChange={handleInStock}
                      name="stock_status"
                      id="stock_status"
                    >
                      <option value="yes">yes</option>
                      <option value="no">no</option>
                    </select>
                  </div>
                </div>
                <div class="tags-and-styles">
                  <div class="form-group">
                    <label for="tags">tags</label>
                    <input value={tags} onChange={handleTags} type="text" name="tags" id="tags" placeholder="tags" />
                    <span class="tag-sample">example: nike,branded shoes</span>
                  </div>
                  <div class="form-group">
                    <label for="styles">Style</label>
                    <select value={style} onChange={handleStyle} name="style">
                      {allStyle.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="after-market">
              <div class="inner-title flex-title">
                <span>
                  <span>After market</span>
                  <i onClick={handleAMContainer} class="fa fa-dot-circle expand-circle"></i>
                </span>
                <i onClick={addAMs} class="fa fa-plus add-btn"></i>
              </div>
              <div class={afterMarketContainer}>
                <div class="form-group">
                  <label for="marketplace">Marketplace</label>
                  <select
                    value={topAfterMarket.name}
                    onChange={(e) => {
                      let a = topAfterMarket;
                      a.name = e.target.value;
                      setTopAM({ ...a });
                    }}
                    name="marketplace"
                    id="marketplace"
                    class="pointer"
                  >
                    <option value="">None</option>
                    {allMarketplace.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </select>
                </div>
                <div class="form-group">
                  <label for="product_link">Product Link</label>
                  <input
                    value={topAfterMarket.affiliateLink}
                    onChange={(e) => {
                      let a = topAfterMarket;
                      a.affiliateLink = e.target.value;
                      setTopAM({ ...a });
                    }}
                    type="text"
                    name="product_link"
                    id="product_link"
                    placeholder="product link"
                  />
                </div>
              </div>
              {afterMarkets.map((item, index) => (
                <AfterMarketForm index={index} item={item} setAfterMs={setAfterMs} />
              ))}
            </div>
            <div class="where-to-buy">
              <div class="inner-title flex-title">
                <span>
                  <span>Where to buy </span>
                  <i onClick={handleWTBContainer} class="fa fa-dot-circle expand-circle"></i>
                </span>
                <i onClick={addWTB} class="fa fa-plus add-btn"></i>
              </div>
              <div class={whereToBuyContainer}>
                {whereToBuy.map((item, index) => {
                  return <WhereToBuyForm key={index} index={index} item={item} setWhereToBuy={setWhereToBuy} />;
                })}
              </div>
            </div>
            <div class="images-and-save">
              <div class="form-group">
                <label for="images">Images</label>
                <input onChange={handleOtherImages} type="file" name="images" id="images" multiple />
              </div>
              <div class="submit">
                <button type="submit" class="publish-button" name="publish_live">
                  <span class="lnr lnr-pencil"></span> Live
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default AddProduct;
