import React, { useState, useEffect } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import axios from 'axios';

const AllListingsBlog = ({ getMessage }) => {
  getMessage(`Blog section`);
  const { toggler } = useOutletContext();
  const [allProducts, setAllProducts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [filters, setFilters] = useState({});
  const [filter, setFilter] = useState('');
  const [values, setValues] = useState('');

  useEffect(() => {
    axios.get('/blog/getBlog').then((res) => {
      setAllProducts(res.data.blogs);
      setFiltered(res.data.blogs);
    });
    axios.get('/filter/all').then((res) => setFilters(res.data.filters));
  }, []);

  async function handleDelete(index) {
    const temp = allProducts;
    await axios
      .delete(`/blog/${allProducts[index]._id}`, {
        headers: {
          Authorization: localStorage.getItem('accessToken')
        }
      })
      .catch((error) => {
        if (error.response.data.msg == 'Invalid token') {
          axios
            .get('/refresh', {
              headers: {
                Refresh: localStorage.getItem('refreshToken')
              }
            })
            .then((res) => {
              localStorage.setItem('accessToken', res.data.accessToken);
              axios.delete(`/blog/${allProducts[index]._id}`, {
                headers: {
                  Authorization: localStorage.getItem('accessToken')
                }
              });
            })
            .catch((err) => (window.location.href = '/'));
        }
      });
    temp.splice(index, 1);
    setAllProducts([...temp]);
  }

  function handleFilter(e) {
    e.preventDefault();
    setFilter(e.target.value);
    setFiltered(allProducts);
  }

  function handleValues(e) {
    e.preventDefault();
    let f = filter;
    if (filter == 'blogTopics') {
      f = 'topic';
    }
    const temp = allProducts.filter((item) => {
      if (filter == 'none') {
        return true;
      }
      if (item[f] == e.target.value) {
        return true;
      } else {
        return false;
      }
    });
    setFiltered([...temp]);
    setValues(e.target.value);
  }

  return (
    <>
      <section id="brand-" class={toggler ? 'left-margin-container' : 'left-margin-container margin-remove'}>
        <div class="container add-brand-container">
          <h1>All Blogs</h1>
          <div class="listing-table-wrapper">
            <select value={filter} onChange={handleFilter} name="filter_by" id="filter_by" class="email-filter">
              <option value="none">None</option>
              <option value="brand">Brand</option>
              <option value="blogTopics">Blog Topics</option>
            </select>
            <select value={values} onChange={handleValues} name="filter_by" id="filter_by" class="email-filter">
              {filter !== '' &&
                filter !== 'none' &&
                filters[filter].map((item) => {
                  return <option value={item}>{item.toUpperCase()}</option>;
                })}
            </select>
            <table class="listings-table">
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Title</th>
                  <th>Product name</th>
                  <th>Short description</th>
                  <th>Tags</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((blog, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{blog.title}</td>
                      <td>{blog.productName}</td>
                      <td>{blog.shortDesc}</td>
                      <td>{blog.tags.map((item) => item + ', ')}</td>
                      <td class="options">
                        <Link
                          to={`/admin/blog/${blog._id}`}
                          // href="#"
                          // onClick={() => handleEdit(index)}
                          class="edit"
                        >
                          <div class="lnr lnr-pencil"></div>
                        </Link>
                        <a href="#" onClick={() => handleDelete(index)} class="delete">
                          <div class="lnr lnr-trash"></div>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllListingsBlog;
