import { useState, useEffect } from 'react';

import axios from 'axios';

import DropDown from '../UI/DropDown';

import useMobile from '../../hooks/useMobile';

import FilterIcon from '../../styles/icons/FilterIcon';

import './styles.css';

const Filters = ({ products, unionData, setUnionData, apparel, isTab }) => {
  const isMobile = useMobile();

  const colors = [
    'Black',
    'Blue',
    'Brown',
    'Green',
    'Grey',
    'Orange',
    'Pink',
    'Purple',
    'Red',
    'White',
    'Yellow',
    'Multi-Color'
  ];

  const [allFilters, setAllFilters] = useState({
    brand: [],
    productFor: [],
    topic: [],
    category: []
  });

  const [filterToggler, setFilterToggler] = useState({
    Brand: [],
    Gender: [],
    ProductTopic: [],
    Category: []
  });

  const [showFilters, setShowFilters] = useState(false);

  const [sizefilterationData, setSizeFiltrationData] = useState([]);
  const [colorfilterationData, setColorFiltrationData] = useState([]);
  const [finalFiltered, setFinalFiltered] = useState([]);
  const [brands, setBrands] = useState([]);
  const [genders, setGenders] = useState([]);
  const [productTopics, setProductTopics] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setSizeFiltrationData(products);
    setColorFiltrationData(products);
    setFinalFiltered(products);
  }, [products]);

  useEffect(() => {
    if (isTab) {
      setShowFilters(false);
    }
    axios.get('/filter/all').then((res) => {
      const filters = res.data.filters;

      setBrands(filters['brand']);
      setGenders(filters['productFor']);
      setProductTopics(
        filters['productTopics'].filter((item) => {
          if (item === 'Trending' || item === 'NEW RELEASES') {
            return true;
          }

          return false;
        })
      );

      setCategories(
        filters['categories'].filter((item) => {
          if (apparel && item === 'Sneaker') {
            return false;
          } else return true;
        })
      );

      let temp = { ...filterToggler };
      temp.Brand = filters['brand'].map((item) => false);
      temp.Gender = filters['productFor'].map((item) => false);
      temp.ProductTopic = filters['productTopics'].map((item) => false);
      temp.Category = filters['categories'].map((item) => false);

      setFilterToggler({ ...temp });
    });
  }, []);

  const handleSortBy = (e) => {
    const value = e.target.value;

    if (value === 'asc') {
      const asc = unionData.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
      setUnionData([...asc]);
    } else {
      const dsc = unionData.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
      setUnionData([...dsc]);
    }
  };

  const toggleCheck = (topic, filter, toggler) => {
    let topicFilter = filter;

    if (topic === 'topic') {
      switch (filter) {
        case 'NEW RELEASES':
          topicFilter = filter;
          break;
        case 'Trending':
          topicFilter = filter;
          break;
        default:
          topicFilter = '';
      }
    }

    let tem = { ...allFilters };

    if (toggler) {
      tem[topic].push(topicFilter);
    } else {
      tem[topic] = tem[topic].filter((filt) => filt !== topicFilter);
    }

    const filteredProducts = products.filter((product) => {
      for (const key in tem) {
        if (tem[key].length > 0) {
          if (key === 'marketplace') {
            if (!tem[key].includes(product.topAfterMarket.name)) {
              return false;
            }
          } else if (!tem[key].includes(product[key])) {
            return false;
          }
        }
      }

      return true;
    });

    setAllFilters({ ...tem });
    setFinalFiltered([...filteredProducts]);

    const union = sizefilterationData.filter(
      (item) => filteredProducts.includes(item) && colorfilterationData.includes(item)
    );

    setUnionData([...union]);
  };

  const checkBoxSection = (topic, items) => {
    let filterLabel;

    switch (topic) {
      case 'Brand':
        filterLabel = 'BRAND';
        break;
      case 'Gender':
        filterLabel = 'GENDER';
        break;
      case 'ProductTopic':
        filterLabel = 'IN-STYLE';
        break;
      case 'Category':
        filterLabel = 'APPAREL';
        break;
      default:
        filterLabel = topic;
    }

    const filterItems = items.map((item) => {
      if (item === 'NEW RELEASES') {
        return (item = 'New_Releases');
      } else return item;
    });

    return (
      <div className="gender-section">
        <div className="top-title">
          <span></span>
        </div>
        <div className="gender-type-listings">
          {filterItems.map((it, index) => (
            <label key={it} className="check-container">
              {it}
              <input
                checked={filterToggler[topic][index]}
                onChange={(e) => {
                  let tpc;
                  let flt;

                  if (topic === 'Brand') {
                    tpc = 'brand';
                    toggleCheck(tpc, it, e.target.checked);
                  } else if (topic === 'ProductTopic') {
                    tpc = 'topic';
                    if (it === 'New_Releases') {
                      flt = 'NEW RELEASES';
                    }
                    toggleCheck(tpc, flt, e.target.checked);
                  } else if (topic === 'Category') {
                    tpc = 'category';
                    toggleCheck(tpc, it, e.target.checked);
                  } else {
                    tpc = 'productFor';
                    toggleCheck(tpc, it, e.target.checked);
                  }

                  let t = { ...filterToggler };
                  t[topic][index] = !t[topic][index];

                  setFilterToggler({ ...t });

                  return;
                }}
                type="checkbox"
                id={it}
              />
              <span className="checkmark"></span>
            </label>
          ))}
        </div>
      </div>
    );
  };
  return (
    <section className="filters-sec">
      {isMobile ? (
        <>
          <div className="filter-menu">
            <div onClick={() => setShowFilters(true)}>
              <FilterIcon />
            </div>
            {!isTab ? (
              <div className="dropdown">
                <div className="results-count">Sort by:</div>
                <select name="sort" id="" onChange={handleSortBy}>
                  <option value="" selected disabled>
                    Price
                  </option>
                  <option value="asc">Price Low to High</option>
                  <option value="desc">Price High to Low</option>
                </select>
              </div>
            ) : null}
          </div>
          <div id="filter-modal" className={showFilters ? '' : 'hide-filter'}>
            <div className="container filter-modal-container">
              <div onClick={() => setShowFilters(false)} className="close-button">
                &times;
              </div>
              <div className="sticky">
                <div className="filter-title">
                  <p>search results for</p>
                  <h2>({products.length})</h2>
                </div>
                <div className="filter-line"></div>
                <div className="top-title filter-title">BRAND</div>
                {checkBoxSection('Brand', brands)}
                <div className="filter-line"></div>
                <div className="top-title filter-title">GENDER</div>
                {checkBoxSection('Gender', genders)}
                <div className="filter-line"></div>
                <div className="top-title filter-title">IN-STYLE</div>
                {checkBoxSection('ProductTopic', productTopics)}
                <div className="filter-line"></div>
                <div className="top-title filter-title">APPAREL</div>
                {checkBoxSection('Category', categories)}
                <div className="filter-line"></div>
                <div className="colors-section">
                  <div className="top-title filter-title">COLOR</div>
                  <div className="colors-list">
                    {colors.map((color, index) => {
                      const lowerCasedColor = color.toLowerCase();
                      return (
                        <div
                          key={index + 'color'}
                          onClick={() => {
                            let colorFilteredData;
                            if (color == 'Multi-Color') {
                              colorFilteredData = products;
                            } else {
                              colorFilteredData = products.filter(
                                (product) => product.color.toLowerCase() == lowerCasedColor
                              );
                            }
                            setColorFiltrationData([...colorFilteredData]);
                            const union = sizefilterationData.filter(
                              (item) => finalFiltered.includes(item) && colorFilteredData.includes(item)
                            );
                            setUnionData([...union]);
                          }}
                          className="color"
                        >
                          <div
                            className={
                              lowerCasedColor == 'multi-color'
                                ? 'multi-color-circle'
                                : lowerCasedColor + '-color-circle'
                            }
                          ></div>
                          <span>{color}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="filters-wrapper">
          <div className={isTab ? 'filters filters-reverse' : 'filters'}>
            <div className="filter-icon" onClick={() => setShowFilters(!showFilters)}>
              <FilterIcon />
            </div>
            <div className={showFilters ? 'dropdowns' : 'hide-dropdowns'}>
              <DropDown label="BRAND">{checkBoxSection('Brand', brands)}</DropDown>
              <DropDown label="GENDER">{checkBoxSection('Gender', genders)}</DropDown>
              <DropDown label="IN-STYLE">{checkBoxSection('ProductTopic', productTopics)}</DropDown>
              <DropDown label="APPAREL">{checkBoxSection('Category', categories)}</DropDown>
              <DropDown label="COLOR" lastChild>
                <div className="colors-section">
                  <div className="colors-list">
                    {colors.map((color, index) => {
                      const lowerCasedColor = color.toLowerCase();
                      return (
                        <div
                          key={index + 'color1'}
                          onClick={() => {
                            let colorFilteredData;

                            if (color === 'Multi-Color') {
                              colorFilteredData = products;
                            } else {
                              colorFilteredData = products.filter(
                                (product) => product.color.toLowerCase() === lowerCasedColor
                              );
                            }

                            setColorFiltrationData([...colorFilteredData]);

                            const union = sizefilterationData.filter(
                              (item) => finalFiltered.includes(item) && colorFilteredData.includes(item)
                            );

                            setUnionData([...union]);
                          }}
                          className="color"
                        >
                          <div
                            className={
                              lowerCasedColor === 'multi-color'
                                ? 'multi-color-circle'
                                : lowerCasedColor + '-color-circle'
                            }
                          ></div>
                          <div className="color-label">
                            <span>{color}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </DropDown>
            </div>
          </div>
          {!isTab ? (
            <div className="dropdown">
              <div className="results-count">Sort by:</div>
              <select name="sort" id="" onChange={(e) => handleSortBy(e)}>
                <option value="" selected disabled>
                  Price
                </option>
                <option value="asc">Price Low to High</option>
                <option value="desc">Price High to Low</option>
              </select>
            </div>
          ) : null}
        </div>
      )}
    </section>
  );
};

export default Filters;
