import { Link } from 'react-router-dom';

import Robot from '../images/robot_404.gif';

import '../css/404.css';

const ErrorPage = () => {
  return (
    <>
      <section id="page-404">
        <div class="container page-404-container">
          <div class="text-container">
            <h1>404</h1>
            <p class="oops">Ooops!</p>
            <p class="page-not-found">Page not found</p>
            <p class="description-text">
              This page doesn't exist or was removed! <br /> we suggest you back to home.
            </p>
            <Link to={'/'} class="back-to-home-btn">
              Back to Home
            </Link>
          </div>
          <div class="img-container">
            <img src={Robot} alt="page not found" />
          </div>
        </div>
      </section>
    </>
  );
};

export default ErrorPage;
