import React, { useEffect, useState } from 'react';

import Products from '../HomePage/HomeProducts/Products';
import Filters from '../../components/Filters';

import '../../css/style.css';
import './styles.css';

const Result = ({
  products,
  savedItems,
  setSavedItems,
  hasItem,
  refs,
  savedSlidesSetter
  //   allStyles,
  //   allMarketPlace
}) => {
  const [unionData, setUnionData] = useState([]);

  useEffect(() => {
    setUnionData(products);
  }, [products]);

  return (
    <>
      {hasItem ? (
        <div className="result-section">
          <Filters products={products} unionData={unionData} setUnionData={setUnionData} />
          <div className="products-section">
            <section id="new-releases">
              <div id="lit-kicks" className="container new-releases-container">
                <h1 className="uppercase">{unionData.length} results found</h1>
                {unionData.length > 0 ? (
                  <Products
                    products={unionData}
                    savedItems={savedItems}
                    setSavedItems={setSavedItems}
                    refs={refs}
                    savedSlidesSetter={savedSlidesSetter}
                  />
                ) : (
                  <div>No matching results found</div>
                )}
              </div>
            </section>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Result;
