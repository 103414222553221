import useMobile from '../../../hooks/useMobile';

import './styles.css';

const TipPopUp = ({ showTip, setShowTip }) => {
  const isMobile = useMobile();

  return showTip && !isMobile ? (
    <div className="tip-popup">
      <div className="close-popup" onClick={() => setShowTip(false)}>
        <span className="lnr lnr-cross" id="close-btn"></span>
      </div>
      <h3>"FREESTYLE YOUR KICKS"</h3>
      <span>
        Click here to explore
        <br />
        apparel options
        <br />
        that perfectly
        <br />
        compliment your
        <br />
        sneakers!
      </span>
      <div className="triangle" />
    </div>
  ) : null;
};

export default TipPopUp;
