import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import Slider from 'react-slick';

import EnlargedImage from '../../components/Modals/EnlargedImage';
import SearchModal from '../../components/Modals/SearchModal';
import TipPopUp from '../../components/Modals/TipPopUp';

import Pant from '../../styles/vectors/Pant.png';
import Tshirt from '../../styles/vectors/T-shirt.png';
import Jacket from '../../styles/vectors/Jacket.png';
import Cap from '../../styles/vectors/Cap.png';
import Accessories from '../../styles/vectors/Accessories.png';
import Sneaker from '../../styles/vectors/Sneaker.png';

import { categoryDict } from '../../constants/data';

import '../../css/style.css';

const categoryVectors = {
  Cap: <img src={Cap} className="icon" alt="pants" name="pant" />,
  Jacket: <img src={Jacket} className="icon" alt="pants" name="pant" />,
  'T-shirt': <img src={Tshirt} className="icon" alt="pants" name="pant" />,
  Pant: <img src={Pant} className="icon" alt="pants" name="pant" />,
  Sneaker: <img src={Sneaker} className="icon" alt="pants" name="pant" />,
  Accessories: <img src={Accessories} className="icon" alt="pants" name="pant" />
};

const Tabs = ({
  setSelectedTab,
  selectedTab,
  refs,
  savedSlides,
  savedSlidesSetter,
  savedItems,
  setSavedItems,
  products
}) => {
  const navigate = useNavigate();

  const [productCategories, setProductCategories] = useState([]);
  const [openedTabs, setOpenedTabs] = useState([]);

  const [enlargedImage, setEnlargeImage] = useState('');
  const [showEnlImage, setShowEnlImage] = useState(false);
  const [productId, setProductId] = useState('');

  const [showTip, setShowTip] = useState(false);

  const tip = localStorage.getItem('freestyleTip');

  const tabRef = useRef(null);
  const [tabPositionY, setTabPositionY] = useState(0);

  const addClassOnScroll = () => {
    const scrollY = window.scrollY;

    if (scrollY > tabPositionY - 300) {
      setShowTip(true);
      localStorage.setItem('freestyleTip', 'opened');
    } else {
      setShowTip(false);
    }
  };

  useEffect(() => {
    if (tabRef.current) {
      setTabPositionY(tabRef.current.offsetTop);
    }

    if (tip !== 'opened') {
      window.addEventListener('scroll', addClassOnScroll);
    }

    return () => window.removeEventListener('scroll', addClassOnScroll);
  });

  // const [savedSlides, setSavedSlides] = useState([[], [], [], [], []]);
  // const [selectSlide, setSelectSlide] = useState({
  //   Sneaker: [],
  //   Pant: [],
  //   "T-shirt": [],
  //   Cap:[],
  //   Accessories: []
  // })

  const sliderRef = useRef();

  // useEffect(()=>{
  //   let t = JSON.parse(localStorage.getItem("slider"));
  //   if(t){

  //     t = t.map((catItems) => {
  //       if(catItems){
  //         const parsed = catItems?.map((products) => JSON.parse(products));
  //         return parsed;
  //       }else{
  //         return []
  //       }
  //     });
  //     if(t !== savedSlides){
  //       console.log("Yes")
  //       savedSlides.setSavedSlides([...t]);
  //     }
  //   }
  // },[savedSlides])

  const getProductCategories = async () => {
    const res = await axios.get('/filter/categories').then((res) => res.data.result);

    const topic = res.map((item) => {
      return { category: item, show: false, productFor: 'All' };
    });

    setProductCategories(topic);
  };

  useEffect(() => {
    getProductCategories();
  }, []);

  const showCategory = (item, index) => {
    const newCategories = productCategories;
    newCategories[index].show = !newCategories[index].show;

    setProductCategories([...newCategories]);
  };

  useEffect(() => {
    const openedCategories = productCategories.filter((category) => category.show === true);

    setOpenedTabs(openedCategories);
  }, [productCategories]);

  const settings = {
    className: 'center',
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1125,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const showOutputCatagory = (productWithCategories, indexD) => {
    let filteredProductFor = [];

    if (productCategories[indexD].productFor === 'All') {
      filteredProductFor = productWithCategories;
    } else {
      filteredProductFor = productWithCategories.filter(
        (product) => product.productFor === productCategories[indexD].productFor
      );
    }

    return filteredProductFor.length > 0 ? (
      filteredProductFor.map((product, index) => (
        <div
          style={{ minWidth: 300, height: 150 }}
          className="card shoe relative custom"
          id="PROD_102"
          key={index + 'prod'}
        >
          <div
            className="img-container"
            style={{ maxHeight: 100 }}
            onClick={() => {
              setEnlargeImage(product.mainImage.url);
              setShowEnlImage(true);
              setProductId(product._id);
            }}
          >
            <img className="tab-card-image" src={product.mainImage.url} alt="" />
          </div>
          <div
            // onClick={()=>{
            //   const temp = selectSlide
            //   if(temp[product.category].includes(index)){
            //     temp[product.category] = temp[product.category].filter(item => item !== index)
            //   }else{
            //     temp[product.category].push(index)
            //   }
            //   setSelectSlide({...temp})
            // }}
            // onClick={() => (window.location.href = `/product/${product._id}`)}
            className="card-text"
            onClick={() => navigate(`/product/${product._id}`)}
            style={{ cursor: 'pointer' }}
          >
            <span>
              {product.productName.slice(0, 20)}
              {product.productName.length > 22 ? '...' : ''}
            </span>
            <b className="uppercase">
              {product.shortDesc.slice(0, 20)}
              {product.shortDesc.length > 22 ? '...' : ''}
            </b>
            {/* <div
              className="relative-custom slider-facts"
              onClick={() => {
                navigate(`/product/${product._id}`);
              }}
            >
              Facts
            </div> */}
          </div>
        </div>
      ))
    ) : (
      <p>"Nothing to show"</p>
    );
  };

  const hash = window.location.hash.slice(1);

  if (hash === 'tabs') {
    window.scrollTo({
      top: refs[0].current?.offsetParent?.offsetTop - 77,
      behavior: 'smooth'
    });
  }

  return (
    <>
      <div ref={tabRef} id="tabs" className="stick-tab">
        <div className="container tabs-container">
          <TipPopUp showTip={showTip} setShowTip={setShowTip} />
          {productCategories.length > 0
            ? productCategories.map((item, index) => {
                if (selectedTab === index && !productCategories[index].show) {
                  showCategory(item, index);
                }

                return (
                  <div
                    key={index + 'prod2'}
                    id="tab-2"
                    ref={refs[index]}
                    data-slider-id="pants"
                    name="pant"
                    data-show="no"
                    className={item.show ? 'tab active-tab' : 'tab'}
                    onClick={() => {
                      showCategory(item, index);
                      sliderRef.current.slickGoTo(0);
                      setSelectedTab(-1);
                    }}
                  >
                    {/* <img src={categoryVectors[item.category]} className="icon" alt="pants" name="pant"/> */}
                    {categoryVectors[item.category]}
                  </div>
                );
              })
            : null}
          {openedTabs.length ? (
            <SearchModal products={products} freestyle={true} savedSlidesSetter={savedSlidesSetter} refs={refs} />
          ) : null}
        </div>
      </div>
      {/* <!-- section: tab-contents --> */}
      <section id="tab-contents">
        <div className="container tab-contents-container">
          {productCategories.length > 0
            ? productCategories
                .map((item, index) => {
                  const productWithCategories = savedSlides[categoryDict[item.category]]
                    // .map((product) => JSON.parse(product))
                    .map((product) => product)
                    .reverse();
                  // productWithCategories = productWithCategories.map((product) =>
                  //   JSON.parse(product)
                  // );
                  if (productWithCategories.length < 12) {
                    let tempP = products.filter(
                      (product) => product.category === item.category && !productWithCategories.includes(product)
                    );

                    productWithCategories.forEach((ite) => {
                      tempP = tempP.filter((item) => item._id !== ite._id);
                    });
                    tempP.map(
                      (product, index) =>
                        productWithCategories.length + index < 13 && productWithCategories.push(product)
                    );
                  } else if (productWithCategories.length > 12) {
                    productWithCategories.splice(12, productWithCategories.length - 1);
                  }

                  return productWithCategories.length > 0 ? (
                    <div
                      id="tab-1-content"
                      key={index + 'tab-1-content'}
                      className={item.show ? 'tab-content-item show-tab' : 'tab-content-item'}
                    >
                      <div className="shoes accessories-slider">
                        <Slider {...settings} ref={sliderRef}>
                          {showOutputCatagory(productWithCategories, index)}
                        </Slider>
                      </div>
                    </div>
                  ) : (
                    ''
                  );
                })
                .reverse()
            : 'Nothing to show '}
        </div>
        <EnlargedImage
          showEnlImage={showEnlImage}
          setShowEnlImage={setShowEnlImage}
          enlargedImage={enlargedImage}
          savedItems={savedItems}
          setSavedItems={setSavedItems}
          productId={productId}
        />
      </section>
    </>
  );
};

export default Tabs;
