import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link, useOutletContext } from 'react-router-dom';

const Overview = ({ getMessage }) => {
  getMessage("Here's what's happening in your Cake account today.");
  const { toggler } = useOutletContext();

  const [allProducts, setAllProducts] = useState(0);
  const [allBlogs, setAllBlogs] = useState(0);
  const [allMarketplaces, setAllMarketplaces] = useState(0);
  const [members, setAllMembers] = useState(0);

  useEffect(() => {
    axios
      .get('/count', {
        headers: {
          Authorization: localStorage.getItem('accessToken')
        }
      })
      .then((res) => {
        setAllProducts(res.data.products);
        setAllBlogs(res.data.blogs);
        setAllMarketplaces(res.data.marketplaces);
        setAllMembers(res.data.users);
      });
  }, []);

  return (
    <>
      {/* <!-- section: overview --> */}
      <section id="overview" class={toggler ? 'left-margin-container' : 'left-margin-container margin-remove'}>
        <div class="container overview-container">
          <div class="items">
            <div class="item">
              <div class="left-icon">
                <span class="lnr lnr-layers bluish-color"></span>
                <span class="bold-text">{allProducts}</span>
              </div>
              <div class="right-text">
                <p>Products</p>
                <Link to={'/admin/product/all'}>
                  Visit &nbsp;<i class="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
            <div class="item">
              <div class="left-icon">
                <span class="lnr lnr-users redish-color"></span>
                <span class="bold-text">{members}</span>
              </div>
              <div class="right-text">
                <p>Members</p>
                <Link to={'/admin/email'}>
                  Visit &nbsp;<i class="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
            <div class="item">
              <div class="left-icon">
                <span class="lnr lnr-book greenish-color"></span>
                <span class="bold-text">{allBlogs}</span>
              </div>
              <div class="right-text">
                <p>Blogs</p>
                <Link to={'/admin/blog/all'}>
                  Visit &nbsp;<i class="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
            <div class="item">
              <div class="left-icon">
                <span class="lnr lnr-apartment orange-color"></span>
                <span class="bold-text">{allMarketplaces}</span>
              </div>
              <div class="right-text">
                <p>Marketplaces</p>
                <Link to={'/admin/marketplaces'}>
                  Visit &nbsp;<i class="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- section: email-reminder --> */}
      <section id="email-reminder" class={toggler ? 'left-margin-container' : 'left-margin-container margin-remove'}>
        <div class="container email-reminder-container">
          <div class="top-div">
            <p>Reminder emails ?</p>
            <p>It's simpler than ever!</p>
          </div>
          <div class="bottom-div">
            <div class="left-div">
              <div class="icon-div">
                <span class="lnr lnr-envelope"></span>
              </div>
              <div class="text-div">
                <strong>Remind the customers to explore the newly added products ?</strong>
                <p>Email the customer for better engagement.</p>
              </div>
            </div>
            <div class="right-div">
              <Link to={'/admin/email'} class="email-reminder-link">
                Open<span class="lnr lnr-chevron-right-circle"></span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Overview;
