import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import '../css/profile.css';

let config = {
  headers: {
    Authorization: localStorage.getItem('accessToken')
  }
};

const Profile = () => {
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    tiktokLink: '',
    instagramLink: '',
    facebookLink: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [errMsg, setErrMsg] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    axios
      .get('/user/getUser', config)
      .then((res) => {
        const temp = formValues;
        temp.firstName = res.data.firstName;
        temp.lastName = res.data.lastName;
        temp.email = res.data.email;
        temp.phone = res.data.phone;
        temp.tiktokLink = res.data.tiktokLink;
        temp.instagramLink = res.data.instagramLink;
        temp.facebookLink = res.data.facebookLink;
        setFormValues({ ...temp });
      })
      .catch((error) => {
        if (error.response.data.msg == 'Invalid Authentication') {
          const refreshToken = localStorage.getItem('refreshToken');
          let c = {
            headers: {
              Refresh: refreshToken
            }
          };
          axios
            .get('/user/refresh', c)
            .then((res) => {
              localStorage.setItem('accessToken', res.data.accessToken);
              let cnf = {
                headers: {
                  Authorization: res.data.accessToken
                }
              };
              axios.get('/user/getUser', cnf).then((res) => {
                const temp = formValues;
                temp.firstName = res.data.firstName;
                temp.lastName = res.data.lastName;
                temp.email = res.data.email;
                temp.phone = res.data.phone;
                temp.tiktokLink = res.data.tiktokLink;
                temp.instagramLink = res.data.instagramLink;
                temp.facebookLink = res.data.facebookLink;
                setFormValues(temp);
              });
            })
            .catch((error) => {
              window.location.href = '/login';
            });
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg('');
    setFormErrors(validate(formValues));
    setIsSubmit(true);

    try {
      const response = await axios.put('/user/updateUser', formValues, {
        headers: { Authorization: localStorage.getItem('accessToken') }
      });
      setFormValues(initialValues);
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Incorrect old password');
      } else {
        setErrMsg('Update Failed');
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const passwordRegex = /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    const phoneRegex = /^(\+(([0-9]){1,2})[-.])?((((([0-9]){2,3})[-.]){1,2}([0-9]{4,10}))|([0-9]{10}))$/;
    // This Regex Expression works for India, Canada, Europe, New Zealand, Australia,
    // United States phone numbers, along with their country codes:
    if (!values.firstName) {
      errors.firstName = 'Firstname is required!';
    }
    if (!values.phone) {
      errors.phone = 'Phone number is required';
    } else if (!phoneRegex.test(values.phone)) {
      errors.phone = 'This is not a valid phone format!';
    }

    if (!values.email) {
      errors.email = 'Email is required!';
    } else if (!emailRegex.test(values.email)) {
      errors.email = 'This is not a valid email format!';
    }

    if (values.newPassword) {
      if (!values.oldPassword) {
        errors.password = 'Old Password is required';
      } else if (values.newPassword.length < 8) {
        errors.password = 'password length must be greater than or equal to 8';
      } else if (!passwordRegex.test(values.newPassword)) {
        errors.password = 'Password must be consists of at least one uppercase, lowercase, numeric and symbol';
      } else if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = "Password doesn't match";
      }
    }

    return errors;
  };

  return (
    formValues.firstName != '' && (
      <>
        <section id="sidebar-div">
          <div class="container sidebar-div-container">
            <div class="sidebar-links">
              <Link to="/profile">
                <i class="fa fa-user"></i> <span>&nbsp;&nbsp;Edit profile</span>
              </Link>
              <Link to="/saved_item">
                <i class="fa fa-database"></i>
                <span> Saved items</span>
              </Link>
            </div>
            <div class="sidebar-line"></div>
          </div>
        </section>

        {/* <!-- section: user-profile --> */}
        <section id="user-profile">
          <div class="container user-profile-container">
            <h1>Edit Profile</h1>
            <a href="/saved_item" class="hidden-span py-2">
              My Saved items <span class="lnr lnr-arrow-right"></span>
            </a>
            <form action="" class="edit-profile-form" onSubmit={handleSubmit}>
              <p style={{ color: 'red' }}>{errMsg}</p>
              <div class="name-inputs">
                <div class="form-group">
                  <label for="first_name">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    id="first_name"
                    value={formValues.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div class="form-group">
                  <label for="last_name">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    id="last_name"
                    value={formValues.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <input type="email" name="email" id="email" value={formValues.email} onChange={handleChange} />
              </div>
              <div class="form-group">
                <label for="contact_number">Contact Number</label>
                <input type="text" name="phone" id="phone" value={formValues.phone} onChange={handleChange} />
              </div>
              <div class="city-and-state">
                <div class="form-group">
                  <label for="city">City</label>
                  <input type="text" name="city" id="city" value={formValues.city} onChange={handleChange} />
                </div>
                <div class="form-group">
                  <label for="state">State</label>
                  <input type="text" name="state" id="state" value={formValues.state} onChange={handleChange} />
                </div>
              </div>
              <div class="zip-country">
                <div class="form-group">
                  <label for="zip_code">Zip Code</label>
                  <input type="text" name="zipcode" id="zip_code" value={formValues.zipcode} onChange={handleChange} />
                </div>
                <div class="form-group">
                  <label for="country">Country</label>
                  <input type="text" name="country" id="country" value={formValues.country} onChange={handleChange} />
                </div>
              </div>
              <div class="form-group">
                <label for="tiktok_account">Tiktok link</label>
                <input
                  type="url"
                  name="tiktok_account"
                  id="tiktok_account"
                  value={formValues.tiktokLink}
                  onChange={handleChange}
                />
              </div>
              <div class="form-group">
                <label for="instagram_link">Instagram link</label>
                <input
                  type="url"
                  name="instagram_link"
                  id="instagram_link"
                  value={formValues.instagramLink}
                  onChange={handleChange}
                />
              </div>
              <div class="form-group">
                <label for="facebook_Account">Facebook link</label>
                <input
                  type="url"
                  name="facebook_Account"
                  id="facebook_Account"
                  value={formValues.facebookLink}
                  onChange={handleChange}
                />
              </div>
              <div class="form-group">
                <label for="old_password">Old Password</label>
                <input onChange={handleChange} type="password" name="oldPassword" id="old_password" />
                <span class="text-sm">* Old password is required to make changes.</span>
              </div>
              <div class="change-password-group">
                <div class="form-group">
                  <label for="newPassword">Password</label>
                  <input onChange={handleChange} type="password" name="newPassword" id="password" />
                </div>
                <div class="form-group">
                  <label for="confirmPassword">Confirm password</label>
                  <input onChange={handleChange} type="password" name="confirmPassword" id="confirm_password" />
                </div>
              </div>
              <button type="submit" class="profile-save-btn">
                Save
              </button>
            </form>
          </div>
        </section>
      </>
    )
  );
};

export default Profile;
